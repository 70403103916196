import React, { useState, useEffect } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { GET } from "../../tools/fetch";

const ActiveUser = () => {
  const [users, setUsers] = useState();
  const [loading, setLoading] = useState();

  const loadUsers = async () => {
    try {
      setLoading(true);
      const request = await GET("/auction/askedusers");
      setUsers(request.users);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadUsers();
  }, []);
  return (
    <Col>
      <Card style={{ opacity: "0.7" }}>
        <Card.Body>
          <Card.Title>Power Seller</Card.Title>
          {loading === false &&
            users.map((user) => {
              return (
                <Row key={user.auctionid}>
                  <Col className="pr-0">{user.username}</Col>
                  <Col className="pl-0" xs="auto">
                    {user.user_count} x
                  </Col>
                </Row>
              );
            })}
        </Card.Body>
      </Card>
    </Col>
  );
};

export default ActiveUser;
