import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { POST, GET } from "../tools/fetch";
import { characterState__setOverviewData } from "../redux/actions/characterActions";
import Force from "./force";

import Row from "react-bootstrap/Row";
import CheckQuest from "./quest/checkQuest";

const Forces = () => {
  const data = useSelector((state) => state.skills);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState();
  const [loadTrainForce, setLoadTrainForce] = useState(false);
  const [skillable, setSkillable] = useState(false);
  const [points, setPoints] = useState(0);
  const [forces, setForces] = useState();
  let range;

  const quest = useSelector((state) => state.skills.skills.quest);

  const loadForce = async () => {
    setLoading(true);
    try {
      const response = await GET("/character/forces");
      if (response) {
        dispatch(characterState__setOverviewData(response));
        if (response.rfp > 0) {
          setSkillable(true);
          setPoints(+response.rfp);
        }
        setForces(response);
      }
    } finally {
      setLoading(false);
    }
  };

  const skillForce = async (force) => {
    setLoadTrainForce(force);
    try {
      const response = await POST("/character/forces", { train: force });
      if (response) {
        setForces(response);
        if (response.rfp <= 0) {
          setSkillable(false);
        }
        setPoints(+response.rfp);
      }
    } finally {
      setLoadTrainForce(false);
    }
  };

  useEffect(() => {
    loadForce();
  }, []);

  if (loading === false) {
    if (forces.skills.fabso > 0 || forces.skills.fprot > 0) {
      range = 4;
    } else if (forces.skills.fheal > 0 || forces.skills.fteam > 0) {
      range = 3;
    } else if (forces.skills.fconf > 0 || forces.skills.fblin > 0) {
      range = 2;
    } else if (forces.skills.fproj > 0 || forces.skills.fpers > 0) {
      range = 1;
    }

    if (forces.skills.fdead > 0 || forces.skills.fdest > 0) {
      range = -4;
    } else if (forces.skills.fthun > 0 || forces.skills.fchai > 0) {
      range = -3;
    } else if (forces.skills.fdrai > 0 || forces.skills.fgrip > 0) {
      range = -2;
    } else if (forces.skills.frage > 0 || forces.skills.fthro > 0) {
      range = -1;
    }
  }

  return (
    <>
      {loading === false && quest[0] === 1 && (
        <CheckQuest details={quest[1]} refresh={loadForce} />
      )}
      {loading === false &&
        (quest === 0 ||
          (quest[1].quest_id === "1" &&
            (quest[1].step_id === "8" || quest[1].step_id === "9"))) &&
        points > 0 && (
          <div className="container text-center">
            <h3>
              Du hast noch {points} {points > 1 ? "Punkte" : "Punkt"}
            </h3>
          </div>
        )}
      {loading === false &&
      (quest === 0 ||
        (quest[1].quest_id === "1" &&
          (quest[1].step_id === "8" || quest[1].step_id === "9"))) ? (
        <Row>
          <Force
            f="fspee"
            basePoints={forces.skills.fspee}
            tempBonus={forces.tempBonusForces["tmpfspee"]}
            onClick={skillForce}
            skillable={skillable}
            loadTrainForce={loadTrainForce}
            available={true}
          />
          <Force
            f="fjump"
            basePoints={forces.skills.fjump}
            tempBonus={forces.tempBonusForces["tmpfjump"]}
            onClick={skillForce}
            skillable={skillable}
            loadTrainForce={loadTrainForce}
            available={true}
          />
          <Force
            f="fpush"
            basePoints={forces.skills.fpush}
            tempBonus={forces.tempBonusForces["tmpfpush"]}
            onClick={skillForce}
            skillable={skillable}
            loadTrainForce={loadTrainForce}
            available={true}
          />
          <Force
            f="fpull"
            basePoints={forces.skills.fpull}
            tempBonus={forces.tempBonusForces["tmpfpull"]}
            onClick={skillForce}
            skillable={skillable}
            loadTrainForce={loadTrainForce}
            available={true}
          />
          <Force
            f="fseei"
            basePoints={forces.skills.fseei}
            tempBonus={forces.tempBonusForces["tmpfseei"]}
            onClick={skillForce}
            skillable={skillable}
            loadTrainForce={loadTrainForce}
            available={true}
          />
          <Force
            f="fsabe"
            basePoints={forces.skills.fsabe}
            tempBonus={forces.tempBonusForces["tmpfsabe"]}
            onClick={skillForce}
            skillable={skillable}
            loadTrainForce={loadTrainForce}
            available={forces.skills.level > 75 ? true : false}
          />

          <Force
            f="fpers"
            basePoints={forces.skills.fpers}
            tempBonus={forces.tempBonusForces["tmpfpers"]}
            onClick={skillForce}
            skillable={skillable}
            loadTrainForce={loadTrainForce}
            available={range <= -4 ? false : true}
          />
          <Force
            f="fproj"
            basePoints={forces.skills.fproj}
            tempBonus={forces.tempBonusForces["tmpfproj"]}
            onClick={skillForce}
            skillable={skillable}
            loadTrainForce={loadTrainForce}
            available={range <= -4 ? false : true}
          />
          <Force
            f="fblin"
            basePoints={forces.skills.fblin}
            tempBonus={forces.tempBonusForces["tmpfblin"]}
            onClick={skillForce}
            skillable={skillable}
            loadTrainForce={loadTrainForce}
            available={range <= -3 ? false : true}
          />
          <Force
            f="fconf"
            basePoints={forces.skills.fconf}
            tempBonus={forces.tempBonusForces["tmpfconf"]}
            onClick={skillForce}
            skillable={skillable}
            loadTrainForce={loadTrainForce}
            available={range <= -3 ? false : true}
          />
          <Force
            f="fheal"
            basePoints={forces.skills.fheal}
            tempBonus={forces.tempBonusForces["tmpfheal"]}
            onClick={skillForce}
            skillable={skillable}
            loadTrainForce={loadTrainForce}
            available={range <= -2 ? false : true}
          />
          <Force
            f="fteam"
            basePoints={forces.skills.fteam}
            tempBonus={forces.tempBonusForces["tmpfteam"]}
            onClick={skillForce}
            skillable={skillable}
            loadTrainForce={loadTrainForce}
            available={range <= -2 && forces.skills.fheal <= 0 ? false : true}
          />
          <Force
            f="fprot"
            basePoints={forces.skills.fprot}
            tempBonus={forces.tempBonusForces["tmpfprot"]}
            onClick={skillForce}
            skillable={skillable}
            loadTrainForce={loadTrainForce}
            available={range <= -1 ? false : true}
          />
          <Force
            f="fabso"
            basePoints={forces.skills.fabso}
            tempBonus={forces.tempBonusForces["tmpfabso"]}
            onClick={skillForce}
            skillable={skillable}
            loadTrainForce={loadTrainForce}
            available={range <= -1 ? false : true}
          />

          <Force
            f="fthro"
            basePoints={forces.skills.fthro}
            tempBonus={forces.tempBonusForces["tmpfthro"]}
            onClick={skillForce}
            skillable={skillable}
            loadTrainForce={loadTrainForce}
            available={range >= 4 ? false : true}
          />
          <Force
            f="frage"
            basePoints={forces.skills.frage}
            tempBonus={forces.tempBonusForces["tmpfrage"]}
            onClick={skillForce}
            skillable={skillable}
            loadTrainForce={loadTrainForce}
            available={range >= 4 ? false : true}
          />
          <Force
            f="fgrip"
            basePoints={forces.skills.fgrip}
            tempBonus={forces.tempBonusForces["tmpfgrip"]}
            onClick={skillForce}
            skillable={skillable}
            loadTrainForce={loadTrainForce}
            available={range >= 3 ? false : true}
          />
          <Force
            f="fdrai"
            basePoints={forces.skills.fdrai}
            tempBonus={forces.tempBonusForces["tmpfdrai"]}
            onClick={skillForce}
            skillable={skillable}
            loadTrainForce={loadTrainForce}
            available={range >= 3 ? false : true}
          />
          <Force
            f="fthun"
            basePoints={forces.skills.fthun}
            tempBonus={forces.tempBonusForces["tmpfthun"]}
            onClick={skillForce}
            skillable={skillable}
            loadTrainForce={loadTrainForce}
            available={range >= 2 ? false : true}
          />
          <Force
            f="fchai"
            basePoints={forces.skills.fchai}
            tempBonus={forces.tempBonusForces["tmpfchai"]}
            onClick={skillForce}
            skillable={skillable}
            loadTrainForce={loadTrainForce}
            available={range >= 2 && forces.skills.fthun <= 0 ? false : true}
          />
          <Force
            f="fdest"
            basePoints={forces.skills.fdest}
            tempBonus={forces.tempBonusForces["tmpfdest"]}
            onClick={skillForce}
            skillable={skillable}
            loadTrainForce={loadTrainForce}
            available={range >= 1 ? false : true}
          />
          <Force
            f="fdead"
            basePoints={forces.skills.fdead}
            tempBonus={forces.tempBonusForces["tmpfdead"]}
            onClick={skillForce}
            skillable={skillable}
            loadTrainForce={loadTrainForce}
            available={range >= 1 ? false : true}
          />

          <Force
            f="frvtl"
            basePoints={forces.skills.frvtl}
            tempBonus={forces.tempBonusForces["tmpfrvtl"]}
            onClick={skillForce}
            skillable={skillable}
            loadTrainForce={loadTrainForce}
            available={forces.skills.level >= 75 ? true : false}
          />
          <Force
            f="ftnrg"
            basePoints={forces.skills.ftnrg}
            tempBonus={forces.tempBonusForces["tmpftnrg"]}
            onClick={skillForce}
            skillable={skillable}
            loadTrainForce={loadTrainForce}
            available={forces.skills.level >= 75 ? true : false}
          />
        </Row>
      ) : null}
    </>
  );
};

export default Forces;
