import React, { useState, useEffect } from "react";
import { Button, ButtonGroup, Card, Col, Form, Row } from "react-bootstrap";
import { GET, POST } from "../../tools/fetch";
import Search from "../search";
import Pagination from "react-js-pagination";
import { Slider } from "@mui/material";

const NewAuction = (props) => {
  const [loadingItems, setLoadingItems] = useState();
  const [activePage, setActivePage] = useState(1);
  const [itemType, setItemType] = useState("weapons");
  const [sortDir, setSortDir] = useState("desc");
  const [sortType, setSortType] = useState("itemid");
  const [searchVal, setSearchVal] = useState("");
  const [items, setItems] = useState();
  const [activeItem, setActiveItem] = useState();
  const [duration, setDuration] = useState(1);
  const [start, setStart] = useState(0);
  const [steps, setSteps] = useState(0);
  const [direct, setDirect] = useState(0);
  const [sofort, setSofort] = useState(0);
  const [validated, setValidated] = useState(false);

  const loadItems = async (
    activePage,
    itemType,
    sortDir,
    sortType,
    searchVal
  ) => {
    try {
      setLoadingItems(true);
      const response = await GET(
        `/character/inventory?id=${itemType}&page=${activePage}&sort=${sortType}&direction=${sortDir}&search=${searchVal}`
      );
      if (response) {
        setItems(response);
      }
    } catch (e) {
      console.error(e);
    } finally {
      // finally wird immer ausgefuehrt.
      // dadurch wird der state auch immer danach false gesetzt.
      setLoadingItems(false);
    }
  };

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const handleOnSortDir = (SortType) => {
    if (sortDir === "desc") {
      setSortDir("asc");
    } else {
      setSortDir("desc");
    }
    setSortType(SortType);
  };

  const onSearch = (event) => {
    setSearchVal(event.target.value);
  };

  const marks = [
    {
      value: 1,
      label: "1T",
    },
    {
      value: 2,
      label: "2T",
    },
    {
      value: 3,
      label: "3T",
    },
    {
      value: 4,
      label: "7T",
    },
  ];

  function valuetext(value) {
    return `${value}°C`;
  }

  function valueLabelFormat(value) {
    return marks.findIndex((mark) => mark.value === value) + 1;
  }

  const sendAuction = async (event) => {
    try {
      const form = event.currentTarget;
      if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
      }

      setValidated(true);
      const request = await POST("/auction/auction", {
        itemtype: itemType,
        itemid: activeItem.itemid,
        duration: duration === 4 ? 7 * 86400 : duration * 86400,
        start: start,
        step: +steps === 0 ? 100 : +steps === 1 ? 1000 : 10000,
        direct: direct,
        insta: sofort,
      });
    } catch (error) {
    } finally {
      props.single("my");
    }
  };

  const handleDurationChange = (e, newVal) => {
    setDuration(newVal);
  };

  useEffect(() => {
    loadItems(activePage, itemType, sortDir, sortType, searchVal);
  }, [activePage, sortDir, searchVal]);

  return (
    <div>
      <Search onSearch={onSearch} value={searchVal} />
      <div className="d-block ml-2 mb-1">
        <ButtonGroup>
          <Button
            size="sm"
            variant="outline-light"
            onClick={() => {
              loadItems(1, "weapons", "desc", "itemid", "");
              setItemType("weapons");
              setActivePage(1);
              setSortType("itemid");
              setSortDir("desc");
              setSearchVal("");
              setActiveItem();
            }}
          >
            Waffen
          </Button>
          <Button
            size="sm"
            variant="outline-light"
            onClick={() => {
              loadItems(1, "rings", "desc", "itemid", "");
              setItemType("rings");
              setActivePage(1);
              setSortType("itemid");
              setSortDir("desc");
              setSearchVal("");
              setActiveItem();
            }}
          >
            Ringe
          </Button>
          <Button
            size="sm"
            variant="outline-light"
            onClick={() => {
              loadItems(1, "misc", "desc", "itemid", "");
              setItemType("misc");
              setActivePage(1);
              setSortType("itemid");
              setSortDir("desc");
              setSearchVal("");
              setActiveItem();
            }}
          >
            Verschiedenes
          </Button>
        </ButtonGroup>
      </div>
      <Row>
        <Col md="8" className="ml-2 mr-2">
          <Card
            style={{
              minHeight: "30%",
              backgroundColor: "rgba(255,255,255,0.7)",
            }}
          >
            <Card.Body>
              <Row>
                {loadingItems === false &&
                  items.items.map((item) => {
                    if (item.nodrop != "1") {
                      return (
                        <Col
                          className="text-center"
                          style={{ cursor: "pointer" }}
                          key={item.itemid}
                          xs="6"
                          onClick={() => setActiveItem(item)}
                        >
                          <img
                            style={{
                              width: window.innerWidth < 768 ? "50%" : "35%",
                            }}
                            src={require(`../../images/items/${itemType}/${item.img}.jpg`)}
                          />
                        </Col>
                      );
                    }
                  })}
              </Row>
            </Card.Body>
          </Card>
          <Pagination
            hideDisabled
            activePage={activePage}
            itemsCountPerPage={10}
            totalItemsCount={loadingItems === false ? items.totalItems : 1}
            pageRangeDisplayed={window.innerWidth < 768 ? 3 : 5}
            onChange={handlePageChange}
            itemClassFirst="ml-0 mr-1"
            itemClassLast="ml-1 mr-0"
            itemClass="page-item mr-1 ml-1 text-opacity-75"
            linkClass="page-link"
          />
        </Col>
        <Col className="mr-md-2">
          <Card
            className="mr-2 ml-2"
            style={{ backgroundColor: "rgba(255,255,255,0.7)" }}
          >
            <Card.Body>
              {activeItem && (
                <>
                  <div>{activeItem.name}</div>
                  <div>
                    {activeItem.mindmg &&
                      activeItem.mindmg !== 0 &&
                      "Schaden: " +
                        activeItem.mindmg +
                        " - " +
                        activeItem.maxdmg}
                  </div>
                  {activeItem.reql > 0 && (
                    <div>req. Level: {activeItem.reql}</div>
                  )}
                  {activeItem.reqs > 0 && (
                    <div>req. Skill: {activeItem.reqs}</div>
                  )}
                  {activeItem.stat1_mod != "" && (
                    <div>
                      {activeItem.stat1_mod} {activeItem.stat1_stat}{" "}
                      {activeItem.stat1_value > 0 && activeItem.stat1_value}{" "}
                    </div>
                  )}
                  {activeItem.stat2_mod != "" && (
                    <div>
                      {activeItem.stat2_mod} {activeItem.stat2_stat}{" "}
                      {activeItem.stat2_value > 0 && activeItem.stat2_value}{" "}
                    </div>
                  )}
                  {activeItem.stat3_mod != "" && (
                    <div>
                      {activeItem.stat3_mod} {activeItem.stat3_stat}{" "}
                      {activeItem.stat3_value > 0 && activeItem.stat3_value}{" "}
                    </div>
                  )}
                  {activeItem.stat4_mod != "" && (
                    <div>
                      {activeItem.stat4_mod} {activeItem.stat4_stat}{" "}
                      {activeItem.stat4_value > 0 && activeItem.stat4_value}{" "}
                    </div>
                  )}
                  {activeItem.stat5_mod != "" && (
                    <div>
                      {activeItem.stat5_mod} {activeItem.stat5_stat}{" "}
                      {activeItem.stat5_value > 0 && activeItem.stat5_value}{" "}
                    </div>
                  )}
                  <Form noValidate validated={validated}>
                    <div className="w-100">
                      <Slider
                        size="small"
                        onChange={handleDurationChange}
                        value={duration}
                        max={4}
                        min={1}
                        valueLabelFormat={valueLabelFormat}
                        getAriaValueText={valuetext}
                        defaultValue={1}
                        step={null}
                        valueLabelDisplay="auto"
                        marks={marks}
                      />
                    </div>
                    <div>
                      Der geschätze Materialwert liegt bei {activeItem.price}{" "}
                      Cr.
                      <br />
                      Der Marktwert könnte ein höherer sein. Verkaufe nicht
                      unter Wert!
                    </div>
                    <Form.Label>Startpreis</Form.Label>
                    <Form.Control
                      onChange={(e) => setStart(e.target.value)}
                      min={0}
                      name="start"
                      type="number"
                      placeholder="0 Cr."
                    />
                    <Form.Label>Preisschritte</Form.Label>
                    <Form.Control
                      name="steps"
                      as="select"
                      custom
                      onChange={(e) => setSteps(e.target.value)}
                    >
                      <option value={0}>100 Cr.</option>
                      <option value={1}>1.000 Cr.</option>
                      <option value={2}>10.000 Cr.</option>
                    </Form.Control>
                    <Form.Label>Sofortkauf</Form.Label>
                    <Form.Control
                      onChange={(e) => setSofort(e.target.value)}
                      min={0}
                      name="sofort"
                      type="number"
                      placeholder="0 Cr."
                    />
                    <Form.Label>Direktkauf</Form.Label>
                    <Form.Control
                      onChange={(e) => setDirect(e.target.value)}
                      min={0}
                      name="direkt"
                      type="number"
                      placeholder="0 Cr."
                    />
                    <div className="pt-1 text-center">
                      <Button
                        className="mt-2"
                        variant="outline-success"
                        onClick={sendAuction}
                        disabled={start == 0}
                      >
                        anbieten
                      </Button>
                    </div>
                  </Form>
                </>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default NewAuction;
