import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  characterState__setMaster,
  characterState__setOverviewData,
} from "../../redux/actions/characterActions";
import { Button, Col, Form, Row } from "react-bootstrap";
import { GET, POST } from "../../tools/fetch";
import TempleSkill from "./templeSkill";
import "./temple.css";

const Temple = () => {
  const forces = [
    "fspee",
    "fjump",
    "fpull",
    "fpush",
    "fseei",
    "fsabe",
    "fpers",
    "fproj",
    "fblin",
    "fconf",
    "fheal",
    "fteam",
    "fprot",
    "fabso",
    "fthro",
    "frage",
    "fgrip",
    "fdrai",
    "fthun",
    "fchai",
    "fdest",
    "fdead",
    "frvtl",
    "ftnrg",
  ];
  const abis = ["cns", "agi", "spi", "itl", "tac", "dex", "lsa", "lsd"];
  const [loading, setLoading] = useState();
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const dispatch = useDispatch();
  const data = useSelector((state) => state.char.char);
  const [points, setPoints] = useState(1);
  const [disabled, setDisabled] = useState(false);
  const [skill, setSkill] = useState("force");
  const [skillShow, setSkillShow] = useState(forces);
  const [tempelData, setTempelData] = useState();
  const [switchLoadData, setSwitchLoadData] = useState(false);

  const onClick = (force) => {
    if (from === "" && to !== force && data.skills[`${force}`] > 0) {
      setFrom(force);
    } else if (from === force) {
      setFrom("");
    } else if (to === "") {
      if (checkForces(from, force, data)) {
        setTo(force);
      }
    } else if (to === force) {
      setPoints(1);
      setTo("");
    }
  };

  const checkForces = (from, to, forces) => {
    setDisabled(false);
    if (to === "fpers" || to === "fproj") {
      if (from === "fdead" && forces.skills.fdest === 0) {
        setPoints(forces.skills.fdead);
        setDisabled(true);
        return true;
      }
      if (from === "fdest" && forces.skills.fdead === 0) {
        setPoints(forces.skills.fdest);
        setDisabled(true);
        return true;
      }
      if (
        from !== "fdest" &&
        from !== "fdead" &&
        forces.skills.fdest === 0 &&
        forces.skills.fdead === 0
      ) {
        return true;
      }
    } else if (to === "fblin" || to === "fconf") {
      if (
        from === "fdead" &&
        forces.skills.fdest === 0 &&
        forces.skills.fthun === 0 &&
        forces.skills.fchai === 0
      ) {
        setPoints(forces.skills.fdead);
        setDisabled(true);
        return true;
      }
      if (
        from === "fdest" &&
        forces.skills.fdead === 0 &&
        forces.skills.fthun === 0 &&
        forces.skills.fchai === 0
      ) {
        setPoints(forces.skills.fdest);
        setDisabled(true);
        return true;
      }
      if (
        from === "fthun" &&
        forces.skills.fdead === 0 &&
        forces.skills.fdest === 0 &&
        forces.skills.fchai === 0
      ) {
        setPoints(forces.skills.fthun);
        setDisabled(true);
        return true;
      }
      if (
        from === "fchai" &&
        forces.skills.fdead === 0 &&
        forces.skills.fthun === 0 &&
        forces.skills.fdest === 0
      ) {
        setPoints(forces.skills.fchai);
        setDisabled(true);
        return true;
      }
      if (
        from !== "fdest" &&
        from !== "fdead" &&
        from !== "fthun" &&
        from !== "fchai" &&
        forces.skills.fdest === 0 &&
        forces.skills.fdead === 0 &&
        forces.skills.fthun === 0 &&
        forces.skills.fchai === 0
      ) {
        return true;
      }
    } else if (to === "fheal" || to === "fteam") {
      if (to === "fteam" && forces.skills.fheal === 0) {
        return false;
      }
      if (
        from === "fdead" &&
        forces.skills.fdest === 0 &&
        forces.skills.fthun === 0 &&
        forces.skills.fchai === 0 &&
        forces.skills.fdrai === 0 &&
        forces.skills.fgrip === 0
      ) {
        setPoints(forces.skills.fdead);
        setDisabled(true);
        return true;
      }
      if (
        from === "fdest" &&
        forces.skills.fdead === 0 &&
        forces.skills.fthun === 0 &&
        forces.skills.fchai === 0 &&
        forces.skills.fdrai === 0 &&
        forces.skills.fgrip === 0
      ) {
        setPoints(forces.skills.fdest);
        setDisabled(true);
        return true;
      }
      if (
        from === "fthun" &&
        forces.skills.fdead === 0 &&
        forces.skills.fdest === 0 &&
        forces.skills.fchai === 0 &&
        forces.skills.fdrai === 0 &&
        forces.skills.fgrip === 0
      ) {
        setPoints(forces.skills.fthun);
        setDisabled(true);
        return true;
      }
      if (
        from === "fchai" &&
        forces.skills.fdead === 0 &&
        forces.skills.fthun === 0 &&
        forces.skills.fdest === 0 &&
        forces.skills.fdrai === 0 &&
        forces.skills.fgrip === 0
      ) {
        setPoints(forces.skills.fchai);
        setDisabled(true);
        return true;
      }
      if (
        from === "fdrai" &&
        forces.skills.fdead === 0 &&
        forces.skills.fthun === 0 &&
        forces.skills.fdest === 0 &&
        forces.skills.fchai === 0 &&
        forces.skills.fgrip === 0
      ) {
        setPoints(forces.skills.fdrai);
        setDisabled(true);
        return true;
      }
      if (
        from === "fgrip" &&
        forces.skills.fdead === 0 &&
        forces.skills.fthun === 0 &&
        forces.skills.fdest === 0 &&
        forces.skills.fdrai === 0 &&
        forces.skills.fchai === 0
      ) {
        setPoints(forces.skills.fgrip);
        setDisabled(true);
        return true;
      }
      if (
        from !== "fdest" &&
        from !== "fdead" &&
        from !== "fthun" &&
        from !== "fchai" &&
        from !== "fdrai" &&
        from !== "fgrip" &&
        forces.skills.fdest === 0 &&
        forces.skills.fdead === 0 &&
        forces.skills.fthun === 0 &&
        forces.skills.fchai === 0 &&
        forces.skills.fdrai === 0 &&
        forces.skills.fgrip === 0
      ) {
        return true;
      }
    } else if (to === "fprot" || to === "fabso") {
      if (
        from === "fdead" &&
        forces.skills.fdest === 0 &&
        forces.skills.fthun === 0 &&
        forces.skills.fchai === 0 &&
        forces.skills.fdrai === 0 &&
        forces.skills.fgrip === 0 &&
        forces.skills.fthro === 0 &&
        forces.skills.frage === 0
      ) {
        setPoints(forces.skills.fdead);
        setDisabled(true);
        return true;
      }
      if (
        from === "fdest" &&
        forces.skills.fdead === 0 &&
        forces.skills.fthun === 0 &&
        forces.skills.fchai === 0 &&
        forces.skills.fdrai === 0 &&
        forces.skills.fgrip === 0 &&
        forces.skills.frage === 0 &&
        forces.skills.fthro === 0
      ) {
        setPoints(forces.skills.fdest);
        setDisabled(true);
        return true;
      }
      if (
        from === "fthun" &&
        forces.skills.fdead === 0 &&
        forces.skills.fdest === 0 &&
        forces.skills.fchai === 0 &&
        forces.skills.fdrai === 0 &&
        forces.skills.fgrip === 0 &&
        forces.skills.frage === 0 &&
        forces.skills.fthro === 0
      ) {
        setPoints(forces.skills.fthun);
        setDisabled(true);
        return true;
      }
      if (
        from === "fchai" &&
        forces.skills.fdead === 0 &&
        forces.skills.fthun === 0 &&
        forces.skills.fdest === 0 &&
        forces.skills.fdrai === 0 &&
        forces.skills.fgrip === 0 &&
        forces.skills.frage === 0 &&
        forces.skills.fthro === 0
      ) {
        setPoints(forces.skills.fchai);
        setDisabled(true);
        return true;
      }
      if (
        from === "fdrai" &&
        forces.skills.fdead === 0 &&
        forces.skills.fthun === 0 &&
        forces.skills.fdest === 0 &&
        forces.skills.fchai === 0 &&
        forces.skills.fgrip === 0 &&
        forces.skills.frage === 0 &&
        forces.skills.fthro === 0
      ) {
        setPoints(forces.skills.fdrai);
        setDisabled(true);
        return true;
      }
      if (
        from === "fgrip" &&
        forces.skills.fdead === 0 &&
        forces.skills.fthun === 0 &&
        forces.skills.fdest === 0 &&
        forces.skills.fdrai === 0 &&
        forces.skills.fchai === 0 &&
        forces.skills.frage === 0 &&
        forces.skills.fthro === 0
      ) {
        setPoints(forces.skills.fgrip);
        setDisabled(true);
        return true;
      }
      if (
        from === "frage" &&
        forces.skills.fdead === 0 &&
        forces.skills.fthun === 0 &&
        forces.skills.fdest === 0 &&
        forces.skills.fdrai === 0 &&
        forces.skills.fchai === 0 &&
        forces.skills.fgrip === 0 &&
        forces.skills.fthro === 0
      ) {
        setPoints(forces.skills.frage);
        setDisabled(true);
        return true;
      }
      if (
        from === "fthro" &&
        forces.skills.fdead === 0 &&
        forces.skills.fthun === 0 &&
        forces.skills.fdest === 0 &&
        forces.skills.fdrai === 0 &&
        forces.skills.fchai === 0 &&
        forces.skills.frage === 0 &&
        forces.skills.fgrip === 0
      ) {
        setPoints(forces.skills.fthro);
        setDisabled(true);
        return true;
      }
      if (
        from !== "fdest" &&
        from !== "fdead" &&
        from !== "fthun" &&
        from !== "fchai" &&
        from !== "fdrai" &&
        from !== "fgrip" &&
        from !== "fthro" &&
        from !== "frage" &&
        forces.skills.fdest === 0 &&
        forces.skills.fdead === 0 &&
        forces.skills.fthun === 0 &&
        forces.skills.fchai === 0 &&
        forces.skills.fdrai === 0 &&
        forces.skills.fgrip === 0 &&
        forces.skills.frage === 0 &&
        forces.skills.fthro === 0
      ) {
        return true;
      }
    } else if (to === "fthro" || to === "frage") {
      if (from === "fabso" && forces.skills.fprot === 0) {
        setPoints(forces.skills.fabso);
        setDisabled(true);
        return true;
      }
      if (from === "fprot" && forces.skills.fabso === 0) {
        setPoints(forces.skills.fprot);
        setDisabled(true);
        return true;
      }
      if (
        from !== "fabso" &&
        from !== "fprot" &&
        forces.skills.fprot === 0 &&
        forces.skills.fabso === 0
      ) {
        return true;
      }
    } else if (to === "fgrip" || to === "fdrai") {
      if (
        from === "fabso" &&
        forces.skills.fprot === 0 &&
        forces.skills.fheal === 0 &&
        forces.skills.fteam === 0
      ) {
        setPoints(forces.skills.fabso);
        setDisabled(true);
        return true;
      }
      if (
        from === "fprot" &&
        forces.skills.fabso === 0 &&
        forces.skills.fteam === 0 &&
        forces.skills.fheal === 0
      ) {
        setPoints(forces.skills.fprot);
        setDisabled(true);
        return true;
      }
      if (
        from === "fheal" &&
        forces.skills.fabso === 0 &&
        forces.skills.fprot === 0 &&
        forces.skills.fteam === 0
      ) {
        setPoints(forces.skills.fheal);
        setDisabled(true);
        return true;
      }
      if (
        from === "fteam" &&
        forces.skills.fabso === 0 &&
        forces.skills.fheal === 0 &&
        forces.skills.fprot === 0
      ) {
        setPoints(forces.skills.fteam);
        setDisabled(true);
        return true;
      }
      if (
        from !== "fabso" &&
        from !== "fprot" &&
        from !== "fteam" &&
        from !== "fheal" &&
        forces.skills.fabso === 0 &&
        forces.skills.fprot === 0 &&
        forces.skills.fteam === 0 &&
        forces.skills.fheal === 0
      ) {
        return true;
      }
    } else if (to === "fthun" || to === "fchai") {
      if (to === "fchai" && forces.skills.fthun === 0) {
        return false;
      }
      if (
        from === "fabso" &&
        forces.skills.fprot === 0 &&
        forces.skills.fteam === 0 &&
        forces.skills.fheal === 0 &&
        forces.skills.fblin === 0 &&
        forces.skills.fconf === 0
      ) {
        setPoints(forces.skills.fabso);
        setDisabled(true);
        return true;
      }
      if (
        from === "fprot" &&
        forces.skills.fabso === 0 &&
        forces.skills.fteam === 0 &&
        forces.skills.fheal === 0 &&
        forces.skills.fconf === 0 &&
        forces.skills.fblin === 0
      ) {
        setPoints(forces.skills.fprot);
        setDisabled(true);
        return true;
      }
      if (
        from === "fteam" &&
        forces.skills.fabso === 0 &&
        forces.skills.fprot === 0 &&
        forces.skills.fheal === 0 &&
        forces.skills.fconf === 0 &&
        forces.skills.fblin === 0
      ) {
        setPoints(forces.skills.fteam);
        setDisabled(true);
        return true;
      }
      if (
        from === "fheal" &&
        forces.skills.fabso === 0 &&
        forces.skills.fprot === 0 &&
        forces.skills.fteam === 0 &&
        forces.skills.fconf === 0 &&
        forces.skills.fblin === 0
      ) {
        setPoints(forces.skills.fheal);
        setDisabled(true);
        return true;
      }
      if (
        from === "fconf" &&
        forces.skills.fasbo === 0 &&
        forces.skills.fprot === 0 &&
        forces.skills.fheal === 0 &&
        forces.skills.fteam === 0 &&
        forces.skills.fblin === 0
      ) {
        setPoints(forces.skills.fconf);
        setDisabled(true);
        return true;
      }
      if (
        from === "fblin" &&
        forces.skills.fabso === 0 &&
        forces.skills.fprot === 0 &&
        forces.skills.fteam === 0 &&
        forces.skills.fheal === 0 &&
        forces.skills.fconf === 0
      ) {
        setPoints(forces.skills.fblin);
        setDisabled(true);
        return true;
      }
      if (
        from !== "fabso" &&
        from !== "fprot" &&
        from !== "fteam" &&
        from !== "fheal" &&
        from !== "fconf" &&
        from !== "fblin" &&
        forces.skills.fabso === 0 &&
        forces.skills.fprot === 0 &&
        forces.skills.fheal === 0 &&
        forces.skills.fteam === 0 &&
        forces.skills.fconf === 0 &&
        forces.skills.fblin === 0
      ) {
        return true;
      }
    } else if (to === "fdest" || to === "fdead") {
      if (
        from === "fabso" &&
        forces.skills.fprot === 0 &&
        forces.skills.fteam === 0 &&
        forces.skills.fheal === 0 &&
        forces.skills.fconf === 0 &&
        forces.skills.fblin === 0 &&
        forces.skills.fpers === 0 &&
        forces.skills.fproj === 0
      ) {
        setPoints(forces.skills.fabso);
        setDisabled(true);
        return true;
      }
      if (
        from === "fprot" &&
        forces.skills.fabso === 0 &&
        forces.skills.fheal === 0 &&
        forces.skills.fteam === 0 &&
        forces.skills.fconf === 0 &&
        forces.skills.fblin === 0 &&
        forces.skills.fproj === 0 &&
        forces.skills.fpers === 0
      ) {
        setPoints(forces.skills.fprot);
        setDisabled(true);
        return true;
      }
      if (
        from === "fteam" &&
        forces.skills.fabso === 0 &&
        forces.skills.fprot === 0 &&
        forces.skills.fheal === 0 &&
        forces.skills.fconf === 0 &&
        forces.skills.fblin === 0 &&
        forces.skills.fpers === 0 &&
        forces.skills.fproj === 0
      ) {
        setPoints(forces.skills.fteam);
        setDisabled(true);
        return true;
      }
      if (
        from === "fheal" &&
        forces.skills.fabso === 0 &&
        forces.skills.fprot === 0 &&
        forces.skills.fpers === 0 &&
        forces.skills.fteam === 0 &&
        forces.skills.fblin === 0 &&
        forces.skills.fconf === 0 &&
        forces.skills.fproj === 0
      ) {
        setPoints(forces.skills.fheal);
        setDisabled(true);
        return true;
      }
      if (
        from === "fblin" &&
        forces.skills.fabso === 0 &&
        forces.skills.fprot === 0 &&
        forces.skills.fheal === 0 &&
        forces.skills.fteam === 0 &&
        forces.skills.fconf === 0 &&
        forces.skills.fproj === 0 &&
        forces.skills.fpers === 0
      ) {
        setPoints(forces.skills.fblin);
        setDisabled(true);
        return true;
      }
      if (
        from === "fconf" &&
        forces.skills.fabso === 0 &&
        forces.skills.fprot === 0 &&
        forces.skills.fheal === 0 &&
        forces.skills.fteam === 0 &&
        forces.skills.fblin === 0 &&
        forces.skills.fpers === 0 &&
        forces.skills.fproj === 0
      ) {
        setPoints(forces.skills.fconf);
        setDisabled(true);
        return true;
      }
      if (
        from === "fpers" &&
        forces.skills.fabso === 0 &&
        forces.skills.fprot === 0 &&
        forces.skills.fteam === 0 &&
        forces.skills.fheal === 0 &&
        forces.skills.fconf === 0 &&
        forces.skills.fblin === 0 &&
        forces.skills.fproj === 0
      ) {
        setPoints(forces.skills.fpers);
        setDisabled(true);
        return true;
      }
      if (
        from === "fproj" &&
        forces.skills.fasbo === 0 &&
        forces.skills.fprot === 0 &&
        forces.skills.fteam === 0 &&
        forces.skills.fheal === 0 &&
        forces.skills.fconf === 0 &&
        forces.skills.fblin === 0 &&
        forces.skills.fpers === 0
      ) {
        setPoints(forces.skills.fproj);
        setDisabled(true);
        return true;
      }
      if (
        from !== "fabso" &&
        from !== "fprot" &&
        from !== "fteam" &&
        from !== "fheal" &&
        from !== "fconf" &&
        from !== "fblin" &&
        from !== "fproj" &&
        from !== "fpers" &&
        forces.skills.fabso === 0 &&
        forces.skills.fprot === 0 &&
        forces.skills.fteam === 0 &&
        forces.skills.fheal === 0 &&
        forces.skills.fconf === 0 &&
        forces.skills.fblin === 0 &&
        forces.skills.fpers === 0 &&
        forces.skills.fproj === 0
      ) {
        return true;
      }
    } else if (to === "fsabe") {
      if (data.skills.level >= 75) {
        return true;
      }
    } else if (to === "frvtl") {
      if (data.skills.level >= 75) {
        return true;
      }
    } else if (to === "ftnrg") {
      if (data.skills.level >= 75) {
        return true;
      }
    } else {
      return true;
    }
    return false;
  };

  const changePoints = (e) => {
    let points = +e.target.value;
    if (points > data.skills[`${from}`]) {
      points = data.skills[`${from}`];
    }
    if (points % 1 === 0) {
      setPoints(points);
    }
  };

  const loadForce = async () => {
    setLoading(true);
    try {
      const response = await GET("/character/forces");
      if (response) {
        dispatch(characterState__setOverviewData(response));
      }
    } finally {
    }
  };

  const handleMeditate = async () => {
    try {
      await POST("/city/tempel?action=meditate", {
        points: points,
        to: to,
        from: from,
        skill: skill,
      });
    } catch (e) {
      console.error(e);
    } finally {
      await loadForce();
      await loadTempel();
      await loadData();
    }
  };

  const loadData = async () => {
    try {
      setLoading(true);
      const response = await GET("/character/overview");
      if (response) {
        dispatch({ type: "FETCH_CHAR", payload: response });
        console.log(response);
        if (response.char.masterid !== 0) {
          const masterResponse = await GET(
            `/character/user?id=${response.char.masterid}`
          );
          if (masterResponse) {
            dispatch(characterState__setMaster(masterResponse));
          }
        }
      }
    } catch (e) {
      return;
    } finally {
      setLoading(false);
      setSwitchLoadData(false);
    }
  };

  const loadTempel = async () => {
    try {
      const request = await GET("/city/tempel?action=check");
      if (request) {
        setTempelData(request);
      }
    } catch (e) {
    } finally {
      setSwitchLoadData(true);
    }
  };

  const handleSkillChange = () => {
    if (skill === "force") {
      setSkill("abi");
    } else {
      setSkill("force");
    }
  };

  useEffect(() => {
    loadForce();
    loadTempel();
  }, []);

  useEffect(() => {
    if (switchLoadData === true) {
      loadData();
    }
  }, [switchLoadData]);

  useEffect(() => {
    if (skill === "force") {
      setSkillShow(forces);
    } else {
      setSkillShow(abis);
    }
  }, [skill]);

  return (
    <div>
      {loading === false && data.char.actionid === 0 && (
        <div>
          from: {from} - to: {to}
          <Button onClick={handleSkillChange}>{skill}</Button>
          <div>Resetpunkte: {data.rstp}</div>
        </div>
      )}
      {loading === false && (
        <div className="text-center position-relative">
          <picture>
            {data.side.side === "dark" && data.char.actionid === 80 ? (
              <source
                className="imgT"
                media="screen and (min-width:650px)"
                srcSet={require(`../../images/city/tempel-rot.jpg`)}
              />
            ) : data.side.side === "light" && data.char.actionid === 80 ? (
              <source
                className="imgT"
                media="screen and (min-width:650px)"
                srcSet={require(`../../images/city/tempel-weiss.jpg`)}
              />
            ) : (
              <source
                className="imgT"
                media="screen and (min-width:650px)"
                srcSet={require(`../../images/city/tempel_ohne.jpg`)}
              />
            )}
            {data.side.side === "dark" && data.char.actionid === 80 ? (
              <source
                className="imgT"
                media="screen and (max-width:649px)"
                srcSet={require(`../../images/city/tempel_rot_l.jpg`)}
              />
            ) : data.side.side === "light" && data.char.actionid === 80 ? (
              <source
                className="imgT"
                media="screen and (max-width:649px)"
                srcSet={require(`../../images/city/tempel_weiss_l.jpg`)}
              />
            ) : (
              <source
                className="imgT"
                media="screen and (max-width:649px)"
                srcSet={require(`../../images/city/tempel_l_ohne.jpg`)}
              />
            )}
            <img
              className="imgT"
              style={{ verticalAlign: "sub" }}
              src={require(`../../images/city/tempel_l_ohne.jpg`)}
              alt=""
            />
          </picture>
          {data.char.actionid === 0 && (
            <div className="position-absolute text-white text-left tempelBox">
              <Row>
                {skillShow.map((force, index) => (
                  <Col
                    key={index}
                    xs="6"
                    className={
                      (index === 4 || index === 12 || index === 20) &&
                      skill === "force"
                        ? "mb-3"
                        : index === 1 ||
                          index === 3 ||
                          index === 5 ||
                          index === 7 ||
                          index === 9 ||
                          index === 11 ||
                          index === 13 ||
                          index === 15 ||
                          index === 17 ||
                          index === 19 ||
                          index === 21 ||
                          index === 23
                        ? "text-right"
                        : ""
                    }
                  >
                    <TempleSkill
                      skill={force}
                      to={to}
                      from={from}
                      onClick={onClick}
                    />
                  </Col>
                ))}
              </Row>
            </div>
          )}
        </div>
      )}
      {loading === false &&
        from !== "" &&
        to !== "" &&
        data.char.actionid === 0 && (
          <Form>
            <Form.Group controlId="formBasicRangeCustom">
              <Row className="justify-content-center">
                <Col className="align-self-center" md="6">
                  <Form.Control
                    disabled={disabled}
                    value={points}
                    onChange={changePoints}
                    type="range"
                    custom
                    size="sm"
                    min={1}
                    max={
                      data.skills[`${from}`] > data.rstp
                        ? data.rstp
                        : data.skills[`${from}`]
                    }
                  />
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col className="align-self-center text-center" md="6">
                  <Form.Control
                    disabled={disabled}
                    className="text-center border rounded-pill"
                    custom
                    pattern="[0-9.]+"
                    value={points}
                    onChange={changePoints}
                    type="text"
                    size="sm"
                    min={1}
                    max={
                      data.skills[`${from}`] > data.rstp
                        ? data.rstp
                        : data.skills[`${from}`]
                    }
                    step={1}
                    style={{ width: "25%" }}
                  />
                </Col>
              </Row>
            </Form.Group>
            <div className="text-center">
              <Button
                disabled={points > data.rstp}
                onClick={handleMeditate}
                variant="outline-success"
              >
                meditieren
              </Button>
              {points > data.rstp && <div>du hast nicht genug Punkte</div>}
            </div>
          </Form>
        )}
      {loading === false && data.char.actionid === 80 && (
        <div className="text-center">
          you are currently meditating...
          <div>
            The result will be {tempelData.medi.fromskill} -
            {tempelData.medi.rstp_used} and {tempelData.medi.toskill} +
            {tempelData.medi.rstp_used}
          </div>
        </div>
      )}
    </div>
  );
};

export default Temple;
