import React, { useState, useEffect, useRef } from "react";
import "./styles.css";

const SLOT_SIZE = 100; // Größe jedes Symbols in Pixeln
const NUM_SLOTS = 3; // Anzahl der Walzen
const NUM_SYMBOLS = 5; // Anzahl der Symbole pro Walze

const symbols = ["💰", "🍒", "🔔", "🎰", "🤑"]; // Symbole

export default function App() {
  const [spinning, setSpinning] = useState(false);
  const [results, setResults] = useState([0, 0, 0]);
  const slotsRef = useRef([]);

  useEffect(() => {
    if (spinning) {
      const timerIds = [];
      for (let i = 0; i < NUM_SLOTS; i++) {
        const ref = slotsRef.current[i];
        let j = 0;
        const speed = 10 * (i + 1); // Geschwindigkeit pro Sekunde
        const timerId = setInterval(() => {
          ref.current.style.transform = `translateY(${-j * SLOT_SIZE}px)`;
          j++;
          if (j >= NUM_SYMBOLS + 1) {
            j = 0;
          }
        }, 1000 / speed);
        timerIds.push(timerId);
      }

      // Anhalten der Walzen nach zufälliger Zeit zwischen 1 und 3 Sekunden
      const stopTimerId = setTimeout(() => {
        clearInterval(timerIds[0]);
        clearInterval(timerIds[1]);
        clearInterval(timerIds[2]);

        // Ermittlung der Ergebnisse
        const newResults = slotsRef.current.map(
          (ref) =>
            Math.floor(-ref.current.style.transform.slice(11, -3) / SLOT_SIZE) %
            NUM_SYMBOLS
        );
        setResults(newResults);
        setSpinning(false);
      }, Math.random() * 2000 + 1000); // Zufällige Zeit zwischen 1 und 3 Sekunden

      return () => {
        timerIds.forEach((timerId) => clearInterval(timerId));
        clearTimeout(stopTimerId);
      };
    }
  }, [spinning]);

  const handleSpinClick = () => {
    setSpinning(true);
  };

  // Initialisierung des Ref-Arrays
  useEffect(() => {
    slotsRef.current = Array(NUM_SLOTS)
      .fill()
      .map(() => React.createRef());
  }, []);

  return (
    <div className="App">
      <h1>Slot-Maschine</h1>
      <div className="slots-container">
        {Array(NUM_SLOTS)
          .fill()
          .map((_, index) => {
            const slotSymbols = symbols.concat(symbols).concat(symbols); // Symbole der aktuellen Walze
            const slotRef = slotsRef.current[index]; // Verwendung des Refs aus dem Array

            return (
              <div key={index} className="slot">
                <div className="symbols" ref={slotRef}>
                  {slotSymbols.map((symbol, i) => (
                    <div key={i} className="symbol">
                      {symbol}
                    </div>
                  ))}
                </div>
              </div>
            );
          })}
      </div>
      <button disabled={spinning} onClick={handleSpinClick}>
        Spin
      </button>
      {results.join(" ")}
    </div>
  );
}
