import React, { useState, useEffect } from "react";
import "./App.css";
import { useSelector } from "react-redux";
import Wheel from "./wheel";
import Bet from "./bet";

function Roulette() {
  const userid = useSelector((state) => state.user.userId);
  const [spinResult, setSpinResult] = useState(null);
  const [currentBet, setCurrentBet] = useState(null);

  const handleBet = (bet) => {
    setCurrentBet(bet);
  };

  const handleSpin = () => {
    if (currentBet) {
      const randomSegment = Math.floor(Math.random() * 8);
      setSpinResult(randomSegment);
    }
  };

  return (
    <div>
      {userid === 20 ? (
        <div>
          <Wheel spinResult={spinResult} />
        </div>
      ) : (
        <div>under construction</div>
      )}
    </div>
  );
}

export default Roulette;
