import React from "react";
import { useSelector } from "react-redux";
import { Button, Card } from "react-bootstrap";
import { GET } from "../../tools/fetch";

const TradeView = (props) => {
  const user = useSelector((state) => state.user.userId);

  const handleDeleteTrade = async (id) => {
    try {
      await GET(`/trade/delete/${id}`);
    } catch (e) {
      console.error(e);
      props.errorFunc(e.code);
    } finally {
      await props.loadFunc();
    }
  };
  return (
    <Card className="shadow">
      <Card.Header className="pb-2">
        <div className="d-flex justify-content-between">
          {props.tradeDirection === user
            ? "Dein Angebot"
            : "Angebot von " +
              (props.tradeInfo.bidder === user
                ? props.tradeInfo.offererUser
                : props.tradeInfo.bidderUser)}
        </div>
        <hr />
      </Card.Header>
      <Card.Body className="pt-2">
        {props.tradeItems.map((item) => {
          return (
            <div key={item.itemid}>
              {item.ownerid === props.tradeDirection && item.type !== "cash" ? (
                <div>
                  {item.name}
                  <div className="ml-2">
                    Quality: {item.qlvl}
                    {item.type === "weapons" && (
                      <>
                        {" / "}
                        Damage: {item.mindmg} - {item.maxdmg}
                      </>
                    )}
                    {item.stat1 !== "" && (
                      <>
                        {" / "} {item.stat1}
                      </>
                    )}
                    {item.stat2 !== "" && (
                      <>
                        {" / "}
                        {item.stat2}
                      </>
                    )}
                    {item.stat3 !== "" && (
                      <>
                        {" / "}
                        {item.stat3}
                      </>
                    )}
                    {item.stat4 !== "" && (
                      <>
                        {" / "}
                        {item.stat4}
                      </>
                    )}
                    {item.stat5 !== "" && (
                      <>
                        {" / "}
                        {item.stat5}
                      </>
                    )}
                  </div>
                </div>
              ) : (
                item.ownerid === props.tradeDirection &&
                item.type === "cash" && <div>Cash: {item.itemid}</div>
              )}
            </div>
          );
        })}
        {props.tradeDirection === props.tradeInfo.offerer &&
          props.tradeInfo.text !== null && (
            <div className="pt-3 text-center">
              <i>"{props.tradeInfo.text.text}"</i>
            </div>
          )}
      </Card.Body>
      <Card.Footer>
        {props.tradeDirection === user && props.tradeInfo.status !== 3 ? (
          <div className="d-flex justify-content-between">
            <div>
              <Button
                disabled={
                  (props.tradeInfo.status === 1 &&
                    props.tradeDirection === user &&
                    props.tradeInfo.offerer === user) ||
                  (props.tradeInfo.status === 2 &&
                    props.tradeDirection === user)
                }
                onClick={() => props.idFunc(props.tradeInfo.id)}
                variant="outline-secondary"
                size="sm"
              >
                ohne Angebot
              </Button>
            </div>
            <div>
              <Button
                onClick={() => handleDeleteTrade(props.tradeInfo.id)}
                size="sm"
                variant="danger"
              >
                löschen
              </Button>{" "}
              <Button
                disabled={
                  (props.tradeInfo.status === 1 &&
                    props.tradeDirection === user &&
                    props.tradeInfo.offerer === user) ||
                  (props.tradeInfo.status === 2 &&
                    props.tradeDirection === user)
                }
                onClick={() => (
                  props.offerFunc(), props.idFunc(props.tradeInfo.id)
                )}
                size="sm"
                variant="success"
              >
                anbieten
              </Button>
            </div>
          </div>
        ) : (
          props.tradeInfo.status !== 3 && (
            <Button
              disabled={
                (props.tradeInfo.status === 1 &&
                  props.tradeDirection !== user) ||
                (props.tradeInfo.status === 2 &&
                  props.tradeInfo.bidder === user &&
                  props.tradeDirection !== user)
              }
              onClick={() => props.tradeFunc(props.tradeInfo.id)}
              size="sm"
              variant="success"
            >
              annehmen
            </Button>
          )
        )}
      </Card.Footer>
    </Card>
  );
};

export default TradeView;
