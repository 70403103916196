import React, { useState } from "react";
import {useHistory} from "react-router-dom";
import { Button } from "react-bootstrap";
import { POST } from "../../tools/fetch";

const Register = () => {
  const history = useHistory();

  const [accountname, setAccountname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [response, setResponse] = useState();

  const handleRegister = async () => {
    try {
      const request = await POST("/accounts/add", {
        accountname: accountname,
        email: email,
        password: password,
      });
      setResponse(request);
    } catch (e) {
      console.error(e);
    } finally {
      setAccountname("")
      setEmail("")
      setPassword("")
    }
  };

  const handleBack = () => {
    history.push("/login")
  };

  return (
    <div className="register">
      <div
        style={{
          top: "50%",
          left: "50%",
          height: "50%",
          width: "75%",
          position: "absolute",
          transform: "translate(-50%, -50%)",
          display: "grid",
          justifyItems: "center",
        }}
      >
        <h2 className="text-center">Getting started</h2>
        <div
          className="login-box text-center"
          style={{ height: "auto", width: "auto" }}
        >
          <div className="mb-4">
            <label htmlFor="Acc">
              Choose your Accountname (not your Charactername)
            </label>
            <br />
            <input
              value={accountname}
              onChange={(e) => setAccountname(e.target.value)}
              id="Acc"
              placeholder="Accountname"
              required
              type="text"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="email">Enter your E-Mail Adress</label>
            <br />
            <input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              autoComplete="off"
              id="email"
              placeholder="E-Mail"
              required
              type="email"
            />
            {response && response.error.email["_isUnique"] && (
              <div className="text-danger">
                Diese E-Mail wird bereits verwendet
              </div>
            )}
            {response && response.error.email["email"] && (
              <div className="text-danger">
                Bitte eine valide E-Mail angeben
              </div>
            )}
          </div>
          <div>
            <label htmlFor="Pass">Enter your Password</label>
            <br />
            <input
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              id="Pass"
              placeholder="Passwort"
              required
              autoComplete="off"
              type="password"
            />
          </div>
          {response && !response.error && (
              <div className="text-success">
                Account erfolgreich angelegt
              </div>
            )}
        </div>
        <div className="pt-4 text-center">
          <Button onClick={handleRegister} variant="outline-light">
            registrieren
          </Button>{" "}
          <Button onClick={handleBack} variant="outline-light">
            zurück
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Register;
