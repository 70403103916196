import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { inventoryState__setItems } from "../../redux/actions/inventoryActions";
import Pagination from "react-js-pagination";
import { GET, POST } from "../../tools/fetch";
import Checkbox from "../../tools/checkbox";
import Search from "../search";
import { Button, ButtonGroup, Row, Table } from "react-bootstrap";
import Essence from "../../tools/essence";
import Craft from "./craft";

const Workshop = () => {
  const [section, setSection] = useState("craft");

  const [loadingItems, setLoadingItems] = useState();
  const [loadingAllItems, setLoadingAllItems] = useState();

  const [activePage, setActivePage] = useState(1);
  const [itemType, setItemType] = useState("rings");
  const [sortDir, setSortDir] = useState("desc");
  const [sortType, setSortType] = useState("itemid");
  const [searchVal, setSearchVal] = useState("");

  const [essences, setEssences] = useState({
    platinEssence: 0,
    goldEssence: 0,
    silverEssence: 0,
    rustyEssence: 0,
    onionEssence: 0,
  });

  const [melting, setMelting] = useState();

  const inv = useSelector((state) => state.skills.inv);
  const quest = useSelector((state) => state.skills.inv.quest);
  const data = useSelector((state) => state.skills.skills);

  const dispatch = useDispatch();

  const [checkedItems, setCheckedItems] = useState([]);

  const loadItems = async (
    activePage,
    itemType,
    sortDir,
    sortType,
    searchVal
  ) => {
    try {
      setLoadingItems(true);
      const response = await GET(
        `/character/inventory?id=${itemType}&page=${activePage}&sort=${sortType}&direction=${sortDir}&search=${searchVal}`
      );
      if (response) {
        dispatch(inventoryState__setItems(response));
      }
    } catch (e) {
      console.error(e);
    } finally {
      // finally wird immer ausgefuehrt.
      // dadurch wird der state auch immer danach false gesetzt.
      setLoadingItems(false);
    }
  };

  const [allItems, setAllItems] = useState();

  const loadAllItems = async (itemType, searchVal) => {
    try {
      setLoadingAllItems(true);
      const response = await GET(
        `/character/melt?id=${itemType}&search=${searchVal}`
      );
      if (response) {
        setAllItems(response);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoadingAllItems(false);
    }
  };

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const handleOnSortDir = (SortType) => {
    if (sortDir === "desc") {
      setSortDir("asc");
    } else {
      setSortDir("desc");
    }
    setSortType(SortType);
  };

  const onSearch = (event) => {
    setSearchVal(event.target.value);
  };

  const handleMelt = async () => {
    try {
      setMelting(true);
      const request = await POST("/character/melt", { items: checkedItems });
      if (request) {
        await loadItems(activePage, itemType, sortDir, sortType, searchVal);
        await loadAllItems(itemType, searchVal);
        setCheckedItems([]);
        setEssences({
          platinEssence: 0,
          goldEssence: 0,
          silverEssence: 0,
          rustyEssence: 0,
          onionEssence: 0,
        });
      }
    } catch (e) {
      console.error(e);
    } finally {
      setMelting(false);
    }
  };

  const handleDeSelect = () => {
    setEssences({
      platinEssence: 0,
      goldEssence: 0,
      silverEssence: 0,
      rustyEssence: 0,
      onionEssence: 0,
    });
    setCheckedItems([]);
  };

  const handleSelectAll = (type) => {
    const ItemArray = Object.values(allItems);
    let newCheckedItems = checkedItems;
    let platinEssences = essences.platinEssence;
    let goldEssences = essences.goldEssence;
    let silverEssences = essences.silverEssence;
    let rustyEssences = essences.rustyEssence;
    let onionEssences = essences.onionEssence;
    let changes = false;

    ItemArray.forEach((item) => {
      item.forEach((itemNested) => {
        if (
          type === itemNested.name &&
          checkedItems.some((item) => item.id === itemNested.itemid) === false
        ) {
          changes = true;
          newCheckedItems.push({ id: itemNested.itemid, checked: true });

          if (itemNested.name === "Platin Ring") {
            platinEssences += itemNested.qlvl;
          } else if (itemNested.name === "Gold Ring") {
            goldEssences += itemNested.qlvl;
          } else if (itemNested.name === "Silver Ring") {
            silverEssences += itemNested.qlvl;
          } else if (itemNested.name === "Rusty Ring") {
            rustyEssences += itemNested.qlvl;
          } else if (itemNested.name === "Onion Ring") {
            onionEssences += itemNested.qlvl;
          }
        }
      });
    });
    if (changes === true) {
      setCheckedItems(newCheckedItems);
      setEssences({
        platinEssence: platinEssences,
        goldEssence: goldEssences,
        silverEssence: silverEssences,
        rustyEssence: rustyEssences,
        onionEssence: onionEssences,
      });
    }
  };

  useEffect(() => {
    loadItems(activePage, itemType, sortDir, sortType, searchVal);
    loadAllItems(itemType, searchVal);
  }, [activePage, sortType, itemType, sortDir, searchVal, section]);

  const handleChange = (item) => {
    const itemID = item.itemid;
    //item entfernen
    if (checkedItems.some((checkedItem) => checkedItem.id === +itemID)) {
      if (item.name === "Platin Ring") {
        setEssences({
          ...essences,
          platinEssence: essences.platinEssence - item.qlvl,
        });
      } else if (item.name === "Gold Ring") {
        setEssences({
          ...essences,
          goldEssence: essences.goldEssence - item.qlvl,
        });
      } else if (item.name === "Silver Ring") {
        setEssences({
          ...essences,
          silverEssence: essences.silverEssence - item.qlvl,
        });
      } else if (item.name === "Rusty Ring") {
        setEssences({
          ...essences,
          rustyEssence: essences.rustyEssence - item.qlvl,
        });
      } else if (item.name === "Onion Ring") {
        setEssences({
          ...essences,
          onionEssence: essences.onionEssence - item.qlvl,
        });
      }
      const newItems = checkedItems.filter((newItem) => newItem.id !== +itemID);
      setCheckedItems(newItems);
    }
    //item hinzufügen
    else {
      if (item.name === "Platin Ring") {
        setEssences({
          ...essences,
          platinEssence: essences.platinEssence + item.qlvl,
        });
      } else if (item.name === "Gold Ring") {
        setEssences({
          ...essences,
          goldEssence: essences.goldEssence + item.qlvl,
        });
      } else if (item.name === "Silver Ring") {
        setEssences({
          ...essences,
          silverEssence: essences.silverEssence + item.qlvl,
        });
      } else if (item.name === "Rusty Ring") {
        setEssences({
          ...essences,
          rustyEssence: essences.rustyEssence + item.qlvl,
        });
      } else if (item.name === "Onion Ring") {
        setEssences({
          ...essences,
          onionEssence: essences.onionEssence + item.qlvl,
        });
      }
      setCheckedItems([...checkedItems, { id: +itemID, checked: true }]);
    }
  };

  return section === "melt" &&
    data.char.actionid === 0 &&
    data.char.targetid === 0 &&
    data.char.targettime === 0 ? (
    <div>
      <div className="text-right">
        <Button
          size="sm"
          variant="outline-primary"
          onClick={() => (setSection("craft"), setLoadingItems())}
        >
          Item erstellen
        </Button>
      </div>
      {(quest === 0 ||
        (quest[1].quest_id === "1" && quest[1].step_id === "2")) && (
        <div>
          <Row className="p-1 justify-content-center">
            <ButtonGroup>
              <Button
                disabled={loadingItems}
                variant="outline-dark"
                onClick={() => {
                  loadItems(1, "weapons", "desc", "itemid", "");
                  setItemType("weapons");
                  setActivePage(1);
                  setSortType("itemid");
                  setSortDir("desc");
                  setSearchVal("");
                  setCheckedItems([]);
                  setEssences({
                    platinEssence: 0,
                    goldEssence: 0,
                    silverEssence: 0,
                    rustyEssence: 0,
                    onionEssence: 0,
                  });
                }}
              >
                Waffen
              </Button>
              <Button
                disabled={loadingItems}
                variant="outline-dark"
                onClick={() => {
                  loadItems(1, "rings", "desc", "itemid", "");
                  setItemType("rings");
                  setActivePage(1);
                  setSortType("itemid");
                  setSortDir("desc");
                  setSearchVal("");
                  setCheckedItems([]);
                  setEssences({
                    platinEssence: 0,
                    goldEssence: 0,
                    silverEssence: 0,
                    rustyEssence: 0,
                    onionEssence: 0,
                  });
                }}
              >
                Ringe
              </Button>
              {/*}
                <Button
                  disabled={loadingItems}
                  variant="outline-dark"
                  onClick={() => {
                    loadItems(1, "misc", "desc", "itemid", "");
                    setItemType("misc");
                    setActivePage(1);
                    setSortType("itemid");
                    setSortDir("desc");
                    setSearchVal("");
                  }}
                >
                  Verschiedenes
                </Button>
                {*/}
            </ButtonGroup>
          </Row>

          <Search
            disabled={loadingItems}
            onSearch={onSearch}
            value={searchVal}
          />
        </div>
      )}
      {(quest === 0 ||
        (quest[1].quest_id === "1" && quest[1].step_id === "2")) && (
        <Table responsive="md" size="sm">
          <thead>
            <tr>
              <th>
                <Button
                  disabled={loadingItems}
                  onClick={() => handleOnSortDir("name")}
                  className="pl-2 pb-0 pt-0 pr-0 font-weight-bold text-secondary"
                  variant="link"
                >
                  name
                </Button>
              </th>

              {itemType === "weapons" ? (
                <>
                  <th>
                    <Button
                      disabled={loadingItems}
                      onClick={() => handleOnSortDir("mindmg")}
                      className="p-0 font-weight-bold text-secondary"
                      variant="link"
                    >
                      mindmg
                    </Button>
                  </th>
                  <th>
                    <Button
                      disabled={loadingItems}
                      onClick={() => handleOnSortDir("maxdmg")}
                      className="p-0 font-weight-bold text-secondary"
                      variant="link"
                    >
                      maxdmg
                    </Button>
                  </th>
                </>
              ) : null}

              <th>
                <Button
                  disabled={loadingItems}
                  onClick={() => handleOnSortDir("price")}
                  className="p-0 font-weight-bold text-secondary"
                  variant="link"
                >
                  price
                </Button>
              </th>
              <th>
                <Button
                  disabled={loadingItems}
                  onClick={() => handleOnSortDir("qlvl")}
                  className="p-0 font-weight-bold text-secondary"
                  variant="link"
                >
                  qlvl
                </Button>
              </th>
              {inv.img !== "misc" && (
                <>
                  <th>
                    <Button
                      disabled={loadingItems}
                      onClick={() => handleOnSortDir("reql")}
                      className="p-0 font-weight-bold text-secondary"
                      variant="link"
                    >
                      reql
                    </Button>
                  </th>
                  <th>
                    <Button
                      disabled={loadingItems}
                      onClick={() => handleOnSortDir("reqs")}
                      className="p-0 font-weight-bold text-secondary"
                      variant="link"
                    >
                      reqs
                    </Button>
                  </th>
                </>
              )}
              <th>
                <Button
                  disabled={loadingItems}
                  onClick={() => handleOnSortDir("stat1_value")}
                  className="p-0 font-weight-bold text-secondary"
                  variant="link"
                >
                  stat1
                </Button>
              </th>
              {inv.img !== "misc" && (
                <>
                  <th>
                    <Button
                      disabled={loadingItems}
                      onClick={() => handleOnSortDir("stat2_value")}
                      className="p-0 font-weight-bold text-secondary"
                      variant="link"
                    >
                      stat2
                    </Button>
                  </th>
                  <th>
                    <Button
                      disabled={loadingItems}
                      onClick={() => handleOnSortDir("stat3_value")}
                      className="p-0 font-weight-bold text-secondary"
                      variant="link"
                    >
                      stat3
                    </Button>
                  </th>
                  <th>
                    <Button
                      disabled={loadingItems}
                      onClick={() => handleOnSortDir("stat4_value")}
                      className="p-0 font-weight-bold text-secondary"
                      variant="link"
                    >
                      stat4
                    </Button>
                  </th>
                  <th>
                    <Button
                      disabled={loadingItems}
                      onClick={() => handleOnSortDir("stat5_value")}
                      className="p-0 font-weight-bold text-secondary"
                      variant="link"
                    >
                      stat5
                    </Button>
                  </th>
                </>
              )}
            </tr>
          </thead>
          <tbody>
            {loadingItems === false &&
              inv.items.map((item) => {
                //definieren ob Ring oder Waffe
                item.type = inv.img;

                return (
                  <tr className="small" key={item.itemid}>
                    <td>
                      <Checkbox
                        checked={checkedItems.some((e) => e.id === item.itemid)}
                        name={item.itemid}
                        onChange={() => handleChange(item)}
                        itemName={item.name}
                      />
                    </td>
                    {itemType === "weapons" ? (
                      <>
                        <td>{item.mindmg}</td>
                        <td>{item.maxdmg}</td>
                      </>
                    ) : null}
                    <td>{item.price}</td>
                    <td>{item.qlvl}</td>
                    {inv.img !== "misc" && (
                      <>
                        <td>
                          {inv.char.skills.level < item.reql ? (
                            <span className="text-danger">{item.reql}</span>
                          ) : (
                            item.reql
                          )}
                        </td>

                        <td>
                          {inv.char.skills.dex < item.reqs ? (
                            <span className="text-danger">{item.reqs}</span>
                          ) : (
                            item.reqs
                          )}
                        </td>
                      </>
                    )}
                    <td>
                      {item.stat1_mod} {item.stat1_stat}{" "}
                      {item.stat1_value !== "0" ? item.stat1_value : null}
                    </td>
                    {inv.img !== "misc" && (
                      <>
                        <td>
                          {item.stat2_mod} {item.stat2_stat}{" "}
                          {item.stat2_value !== "0" ? item.stat2_value : null}
                        </td>
                        <td>
                          {item.stat3_mod} {item.stat3_stat}{" "}
                          {item.stat3_value !== "0" ? item.stat3_value : null}
                        </td>
                        <td>
                          {item.stat4_mod} {item.stat4_stat}{" "}
                          {item.stat4_value !== "0" ? item.stat4_value : null}
                        </td>
                        <td>
                          {item.stat5_mod} {item.stat5_stat}{" "}
                          {item.stat5_value !== "0" ? item.stat5_value : null}
                        </td>
                      </>
                    )}
                  </tr>
                );
              })}
          </tbody>
        </Table>
      )}
      {
        //Essenzen anzeige
        <div>
          {!melting &&
          (essences.platinEssence > 0 ||
            essences.goldEssence > 0 ||
            essences.silverEssence > 0 ||
            essences.rustyEssence > 0 ||
            essences.onionEssence > 0) ? (
            <div>you will get</div>
          ) : null}
          <div>
            {essences.platinEssence > 0 && (
              <Essence img={"essenceGold"} count={essences.platinEssence} />
            )}
            {essences.goldEssence > 0 && (
              <Essence img={"essenceGold"} count={essences.goldEssence} />
            )}
            {essences.silverEssence > 0 && (
              <Essence img={"essenceSilver"} count={essences.silverEssence} />
            )}
            {essences.rustyEssence > 0 && (
              <Essence img={"essenceRusty"} count={essences.rustyEssence} />
            )}
            {essences.onionEssence > 0 && (
              <Essence img={"essenceOnion"} count={essences.onionEssence} />
            )}
          </div>
          {!melting &&
          (essences.platinEssence > 0 ||
            essences.goldEssence > 0 ||
            essences.silverEssence > 0 ||
            essences.rustyEssence > 0 ||
            essences.onionEssence > 0) ? (
            <div className="mb-2">
              <Button onClick={handleMelt} variant="outline-success" size="sm">
                schmelzen
              </Button>
              <Button
                onClick={handleDeSelect}
                variant="outline-danger"
                size="sm"
              >
                alle abwählen
              </Button>
            </div>
          ) : null}
        </div>
      }
      {loadingItems === false && itemType === "rings" && (
        <div className="d-flex mb-3 justify-content-center">
          <ButtonGroup size="sm">
            <Button
              disabled={loadingAllItems}
              onClick={() => handleSelectAll("Platin Ring")}
              style={{ backgroundColor: "#e5e4e2", color: "black" }}
            >
              alle Platinringe
            </Button>
            <Button
              onClick={() => handleSelectAll("Gold Ring")}
              disabled={loadingAllItems}
              style={{ backgroundColor: "#ffd700", color: "black" }}
            >
              alle Goldringe
            </Button>
            <Button
              onClick={() => handleSelectAll("Silver Ring")}
              disabled={loadingAllItems}
              style={{ backgroundColor: "#c0c0c0", color: "black" }}
            >
              alle Silberringe
            </Button>
            <Button
              onClick={() => handleSelectAll("Rusty Ring")}
              disabled={loadingAllItems}
              style={{ backgroundColor: "#b7410e", color: "black" }}
            >
              alle Rustyringe
            </Button>
            <Button
              onClick={() => handleSelectAll("Onion Ring")}
              disabled={loadingAllItems}
              style={{ backgroundColor: "#ccac00", color: "black" }}
            >
              alle Onionringe
            </Button>
          </ButtonGroup>
        </div>
      )}
      {loadingItems === false &&
      (quest === 0 ||
        (quest[1].quest_id === "1" && quest[1].step_id === "2")) ? (
        <Pagination
          hideDisabled
          activePage={activePage}
          itemsCountPerPage={10}
          totalItemsCount={inv.totalItems ? inv.totalItems : 0}
          pageRangeDisplayed={5}
          onChange={handlePageChange}
          itemClass="page-item"
          linkClass="page-link"
        />
      ) : null}
    </div>
  ) : section === "craft" &&
    data.char.actionid === 0 &&
    data.char.targetid === 0 &&
    data.char.targettime === 0 &&
    loadingItems === false ? ( //section craft
    <div>
      <div className="text-right">
        <Button
          size="sm"
          variant="outline-primary"
          onClick={() => (setSection("melt"), setLoadingItems())}
        >
          items schmelzen
        </Button>
      </div>
      <Craft />
    </div>
  ) : (
    <div>Du bist beschäftigt</div>
  );
};

export default Workshop;
