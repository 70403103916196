import moment from "moment";
import React, { useEffect, useState } from "react";
import { Accordion, Alert, Card, Col, Row } from "react-bootstrap";
import Pagination from "react-js-pagination";
import { GET } from "../../tools/fetch";

const Bugs = () => {
  const [loading, setLoading] = useState();
  const [bugs, setBugs] = useState();

  const loadBugs = async () => {
    setLoading(true);
    try {
      const request = await GET(`/bugs/list?page=${activePage}`);
      if (request) {
        setBugs(request);
      }
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  const bugStatus = (status) => {
    switch (status) {
      case 0:
        return "unassigned";
      case 1:
        return "queued";
      case 2:
        return "assigned";
      case 3:
        return "fixed / watching";
      case 4:
        return "temp fixed";
      case 5:
        return "fixed";
      case 6:
        return "no bug";

      default:
        break;
    }
  };

  const getColor = (level) => {
    switch (level) {
      case "minor":
        return "text-dark";
      case "major":
        return "text-warning";
      case "critical":
        return "text-danger";

      default:
        break;
    }
  };

  const [activePage, setActivePage] = useState(1);

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  useEffect(() => {
    loadBugs();
  }, [activePage]);

  return (
    <div>
      {loading === false && (
        <Accordion className="mt-2">
          {Object.values(bugs)[2].map((bug) => {
            return (
              <Card key={bug.id} className="shadow">
                <Accordion.Toggle as={Card.Header} eventKey={bug.id}>
                  <div className="d-flex justify-content-between">
                    <div>{bug.id}</div> - <div>{bug.text}</div> -{" "}
                    <div>{bugStatus(bug.status)}</div> -{" "}
                    <div className={getColor(bug.level)}>{bug.level}</div>
                  </div>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey={bug.id}>
                  <Card.Body>
                    <div>
                      <Row>
                        <Col>ID: {bug.id}</Col>
                        <Col>Titel: {bug.text}</Col>
                        <Col>von: {bug.JediUserChars.username}</Col>
                        <Col>
                          @{" "}
                          {moment(bug.submitdate)
                            .local()
                            .format("DD.MM.yyyy H:mm:ss")}
                        </Col>
                      </Row>
                      <Alert
                        style={{ whiteSpace: "pre-line" }}
                        variant="secondary"
                      >
                        {bug.description}
                      </Alert>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            );
          })}
        </Accordion>
      )}
      {loading === false && bugs.number >= 1 && (
        <Pagination
          totalItemsCount={Object.values(bugs)[1]}
          activePage={activePage}
          hideDisabled
          itemsCountPerPage={5}
          onChange={handlePageChange}
          itemClass="page-item"
          linkClass="page-link"
        />
      )}
      {loading === false && bugs.number === 0 && (
        <div>
          <div>Aktuell liegen keine bekannten Bugs vor.</div>
          {bugs.numberUnchecked >= 0 && (
            <div>
              Derzeit {bugs.numberUnchecked > 1 ? "sind" : "ist"} noch{" "}
              {bugs.numberUnchecked} {bugs.numberUnchecked > 1 ? "Bugs" : "Bug"}{" "}
              ungeprüft.
            </div>
          )}
        </div>
      )}
      {loading === true && "loading..."}
    </div>
  );
};

export default Bugs;
