import React, { useEffect, useState } from "react";
import { Accordion, Alert, Card, Col, Row } from "react-bootstrap";
import { GET } from "../../tools/fetch";
import Pagination from "react-js-pagination";
import moment from "moment";

const NewsArchiv = () => {
  const [activePage, setActivePage] = useState(1);
  const [loading, setLoading] = useState();
  const [news, setNews] = useState();

  const loadNews = async () => {
    setLoading(true);
    try {
      const request = await GET(`/admin/ingamenews?page=${activePage}`);
      if (request) {
        setNews(request);
      }
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };
  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };
  useEffect(() => {
    loadNews();
  }, [activePage]);

  return (
    <div>
      {loading === false && (
        <Accordion className="mt-2">
          {Object.values(news.news).map((news, id) => {
            return (
              <Card key={news.id} className="shadow">
                <Accordion.Toggle
                  style={{ cursor: "pointer" }}
                  as={Card.Header}
                  eventKey={news.id}
                >
                  <Row>
                    <Col>News Nr. {news.id}</Col>
                    <Col>von: {news.author}</Col>
                    <Col>
                      @ {moment(news.date).local().format("DD.MM.yyyy H:mm:ss")}
                    </Col>
                  </Row>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey={news.id}>
                  <Card.Body>
                    <div>
                      <Alert
                        style={{ whiteSpace: "pre-line" }}
                        variant="secondary"
                      >
                        <span
                          dangerouslySetInnerHTML={{ __html: news.text }}
                        ></span>
                      </Alert>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            );
          })}
        </Accordion>
      )}
      {loading === false && news.number >= 1 && (
        <Pagination
          totalItemsCount={news.number}
          activePage={activePage}
          hideDisabled
          itemsCountPerPage={5}
          onChange={handlePageChange}
          itemClass="page-item"
          linkClass="page-link"
        />
      )}
    </div>
  );
};

export default NewsArchiv;
