import moment from "moment";
import React, { useState, useEffect } from "react";
import { Badge, Button, Modal, Tab, Tabs } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { GET } from "../../tools/fetch";
import Pagination from "react-js-pagination";
import { characterState__setOverviewData } from "../../redux/actions/characterActions";
import Countdown from "../../tools/countdown";
import { Backdrop } from "@mui/material";

const Multifight = () => {
  const data = useSelector((state) => state.skills.skills);
  const [response, setResponse] = useState();
  const [loading, setLoading] = useState();
  const [fighting, setFighting] = useState();
  const [activePage, setActivePage] = useState(1);
  const [activeUserPage, setActiveUserPage] = useState(1);
  const userid = useSelector((state) => state.user.userId);
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const [stage, setStage] = useState(0);
  const [firstLoad, setFirstLoad] = useState(1);

  const loadData = async () => {
    setLoading(true);
    if (firstLoad === 1) {
      const userStage = await GET("/events/killrace");
      if (userStage) {
        const stage = Math.floor(userStage.user.points / 10 + 1);
        setActivePage(+stage);
      }
    }
    const response = await GET(
      `/events/killrace?stages[page]=${activePage}&players[page]=${activeUserPage}`
    );
    if (response) {
      setResponse(response);
    }
    const response2 = await GET("/character/overview");
    if (response2) {
      dispatch(characterState__setOverviewData(response2));
    }
    setFirstLoad(0);
    setLoading(false);
  };

  const handleJoin = async () => {
    const request = await GET(`/events/killrace/join`);
    if (request) {
      setResponse(request);
    }
  };

  const handleAttack = async () => {
    setFighting(true);
    try {
      const request = await GET(
        `/events/killrace/attack?stages[page]=${activePage}&players[page]=${activeUserPage}`
      );
      if (request) {
        setResponse(request);
      }
      const response2 = await GET("/character/overview");
      if (response2) {
        dispatch(characterState__setOverviewData(response2));
      }
    } catch (error) {
    } finally {
      setFighting(false);
    }
  };

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const handlePageUserChange = (pageUserNumber) => {
    setActiveUserPage(pageUserNumber);
  };

  const enemyImage = (enemy) => {
    switch (enemy) {
      case 1:
        return (
          <img
            style={{ width: "40px" }}
            src={require(`../../images/layer2_bigrat_test.png`)}
            alt=""
          />
        );
      case 2:
        return (
          <img
            style={{ width: "40px" }}
            src={require(`../../images/layer2_rat_test.png`)}
            alt=""
          />
        );
      case 8:
        return (
          <img
            style={{ width: "40px" }}
            src={require(`../../images/layer2_hk.png`)}
            alt=""
          />
        );
      case 9:
        return (
          <img
            style={{ width: "40px" }}
            src={require(`../../images/layer2_reek_test.png`)}
            alt=""
          />
        );
      case 11:
        return (
          <img
            style={{ width: "40px" }}
            src={require(`../../images/deena_test.png`)}
            alt=""
          />
        );
      case 12:
        return (
          <img
            style={{ width: "40px" }}
            src={require(`../../images/mariell_test.png`)}
            alt=""
          />
        );
      case 13:
        return (
          <img
            style={{ width: "40px" }}
            src={require(`../../images/layer2_jumper_test.png`)}
            alt=""
          />
        );
      default:
        return (
          <img
            style={{ width: "40px" }}
            src={require(`../../images/monster/rancor.png`)}
            alt=""
          />
        );
        break;
    }
  };

  const handleMonsterModal = () => {
    setModal(true);
  };

  const translate_reward = (reward) => {
    switch (reward) {
      case "inccash":
        return "Credits";
        break;
      case "incxp":
        return "XP";
      case "incSp":
        return "Skillpunkte";
      case "incFp":
        return "Machtpunkte";
      case "incRp":
        return "Resetpunkte";
      default:
        break;
    }
  };

  useEffect(() => {
    loadData();
  }, [activePage, activeUserPage]);

  return (
    <div>
      {firstLoad === 0 && (
        <div>
          <div className="text-center">Killrace-Event</div>
          <div className="d-flex justify-content-between">
            {response.event !== null && (
              <div>restliche Versuche: {response.event.attemps}</div>
            )}
            <div>
              Läuft bis:{" "}
              {moment(response.cur_event.expire).format("DD.MM.YYYY HH:mm")}
            </div>
          </div>
          <div className="d-flex justify-content-between">
            <div>Reset der Versuche: täglich</div>
            <div>
              <Button
                variant="success"
                disabled={response.event !== null}
                onClick={handleJoin}
              >
                join
              </Button>
            </div>
          </div>
          {fighting ? (
            <div className="text-center">fighting...</div>
          ) : (
            data.char.actionid === 17 && (
              <div className="text-center">kurze Erholung</div>
            )
          )}
          {(data.char.actionid === 16 || data.char.actionid === 17) && (
            <Countdown
              onFinish={() => {
                loadData();
                setFighting();
              }}
              timeTillDate={data.char.targettime}
              timeFormat="X"
            />
          )}
          <div style={{ position: "relative" }} className="table-responsive-md">
            {
              <Backdrop
                style={{
                  position: "absolute",
                  zIndex: "1",
                  backgroundColor: "rgba(0,0,0,0.3)",
                }}
                open={data.char.actionid === 16 || data.char.actionid === 17}
              />
            }
            <table className="table table-sm table-striped">
              <thead>
                <tr>
                  <th scope="col" style={{ width: "auto" }}>
                    #
                  </th>
                  <th scope="col" className="text-center">
                    Gegner
                  </th>
                </tr>
              </thead>
              <tbody>
                {response.stages.map((stage, index) => {
                  const tableDanger =
                    stage.userid === userid ? "table-danger" : null;
                  return (
                    <tr
                      style={
                        stage.id === response.user.points + 1
                          ? { cursor: "pointer", backgroundColor: "burlywood" }
                          : { cursor: "pointer" }
                      }
                      key={stage.id}
                      className={tableDanger}
                      onClick={() => {
                        handleMonsterModal();
                        setStage(stage.id);
                      }}
                    >
                      <td style={{ width: "1px", whiteSpace: "nowrap" }}>
                        <Badge
                          style={{ verticalAlign: "center" }}
                          pill
                          variant="warning"
                        >
                          {stage.id}
                        </Badge>
                      </td>
                      <td className="text-center">
                        {enemyImage(+stage.enemy.split(",")[0])}
                        {stage.enemy.split(",")[1] && (
                          <div style={{ verticalAlign: "bottom" }}> .....</div>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          {
            <Pagination
              hideDisabled
              activePage={activePage}
              itemsCountPerPage={10}
              totalItemsCount={response.stages_count}
              pageRangeDisplayed={3}
              onChange={handlePageChange}
              itemClass="page-item"
              linkClass="page-link"
            />
          }
          <div className="row">
            <div className="col-md">
              <Tabs defaultActiveKey={"r"}>
                <Tab eventKey="r" title="Rangliste">
                  <div className="table-responsive-md">
                    <table className="table table-sm table-striped">
                      <thead>
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">User</th>
                          <th scope="col">Stufe</th>
                          <th scope="col">Fights</th>
                        </tr>
                      </thead>
                      <tbody>
                        {response.players.map((player, index) => {
                          const tableDanger =
                            player.userid === userid ? "table-danger" : null;
                          return (
                            <tr key={player.userid} className={tableDanger}>
                              <td>{+index + 1 + ". "}</td>
                              <td>{player.JediUserChars.username}</td>
                              <td>{player.points}</td>
                              <td>{player.fights}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                  {
                    <Pagination
                      hideDisabled
                      activePage={activeUserPage}
                      itemsCountPerPage={10}
                      totalItemsCount={response.count}
                      pageRangeDisplayed={3}
                      onChange={handlePageUserChange}
                      itemClass="page-item"
                      linkClass="page-link"
                    />
                  }
                </Tab>
                <Tab eventKey="b" title="Berichte">
                  <div className="small">
                    {response.fight_reps_a.map((rep) => {
                      return (
                        <div key={rep.md5}>
                          <a
                            target="_blank"
                            href={`https://starwarslegends.de/rest-api/fight/readr?id=${rep.md5}`}
                            key={rep.md5}
                          >
                            {"Stufe " +
                              rep.stage +
                              ": " +
                              moment(rep.zeit, "X").format("DD.MM. HH:mm") +
                              " Uhr "}
                            <br />
                            {rep.headline}
                          </a>
                          <hr className="mt-1 mb-1" />
                        </div>
                      );
                    })}
                  </div>
                </Tab>
              </Tabs>
            </div>
          </div>
          <Modal
            backdrop={"static"}
            centered
            show={modal}
            onHide={() => {
              setModal(false);
              setStage(0);
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>Stufe {stage}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center">
              {stage > 0 && (
                <div>
                  <div>
                    {response.stages.map((stage_temp) => {
                      return (
                        stage_temp.id === stage &&
                        stage_temp.enemy
                          .split(",")
                          .map((enemy) => enemyImage(+enemy))
                      );
                    })}
                  </div>
                  <div>
                    Belohnung:{" "}
                    {response.stages.map((stage_temp) => {
                      return (
                        stage_temp.id === stage &&
                        stage_temp.amount +
                          " " +
                          translate_reward(stage_temp.reward)
                      );
                    })}
                  </div>
                  {response.fight &&
                    response.fight[3] === userid &&
                    !fighting && (
                      <div className="text-center">
                        <b>Gewonnen!</b>
                      </div>
                    )}
                  {response.fight &&
                    response.fight[3] !== userid &&
                    !fighting && (
                      <div className="text-center">
                        <b>Verloren!</b>
                      </div>
                    )}
                </div>
              )}
              <Button
                size="sm"
                disabled={
                  fighting === false ||
                  fighting === true ||
                  data.char.actionid != 0 ||
                  response.event === null ||
                  response.event.attemps <= 0 ||
                  response.user.points >= +stage ||
                  +stage - 1 > response.user.points
                }
                onClick={() => {
                  handleAttack();
                }}
              >
                {response.user.points >= +stage
                  ? "cleared"
                  : +stage - 1 > response.user.points
                  ? "not ready"
                  : data.char.actionid == 17
                  ? "recovering..."
                  : response.event !== null && response.event.attemps <= 0
                  ? "done"
                  : fighting === false ||
                    fighting === true ||
                    data.char.actionid != 0 ||
                    response.event === null
                  ? "fighting..."
                  : "attack"}
              </Button>
            </Modal.Body>
          </Modal>
        </div>
      )}
    </div>
  );
};

export default Multifight;
