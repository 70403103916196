import React, { useEffect, useState } from "react";
import { firestore } from "../../tools/firebase";
import moment from "moment";
import "./sidebarchat.css";
import { Badge } from "react-bootstrap";
import { useSelector } from "react-redux";

const SidebarChat = ({ setChat, id, name }) => {
  const [message, setMessage] = useState([]);
  const [unread, setUnread] = useState(0);
  const username = useSelector((state) => state.user.username);

  const createChat = () => {
    setChat(id);
  };

  useEffect(() => {
    if (id) {
      firestore
        .collection("chats")
        .doc(id)
        .collection("messages")
        .limit(1)
        .orderBy("dt", "desc")
        .onSnapshot((snapshot) =>
          setMessage(snapshot.docs.map((doc) => doc.data()))
        );
      firestore
        .collection("chats")
        .doc(id)
        .collection("messages")
        .where("seen", "==", 0)
        .where("receiver", "==", username)
        .onSnapshot((snapshot2) => setUnread(snapshot2.size));
    }
  }, [id]);

  return (
    <div onClick={createChat} className="sidebarchat">
      <div className="w-100 sidebarChatInfo">
        <h2 className="d-flex justify-content-between">
          <span>{name}</span>
          {unread > 0 && (
            <Badge pill variant="danger">
              {unread}
            </Badge>
          )}
        </h2>
        <em>{message[0]?.message}</em>{" "}
        <small className="text-gray">
          {message[0]?.dt &&
            message[0].dt.seconds !== null &&
            moment.unix(message[0]?.dt.seconds).format("DD.MM.YYYY HH:mm:ss")}
        </small>
      </div>
    </div>
  );
};

export default SidebarChat;
