import React, { useEffect, useState } from "react";
import { GET, POST } from "../../tools/fetch";
import Pagination from "react-js-pagination";
import { Accordion, Alert, Card, Col, Form, Row } from "react-bootstrap";
import moment from "moment";

const AdminBugs = () => {
  const [loading, setLoading] = useState();
  const [bugs, setBugs] = useState();

  const loadBugs = async () => {
    setLoading(true);
    try {
      const request = await GET(`/admin/bugs?page=${activePage}`);
      if (request) {
        setBugs(request);
      }
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  const bugStatus = (status) => {
    switch (status) {
      case 0:
        return "unassigned";
      case 1:
        return "queued";
      case 2:
        return "assigned";
      case 3:
        return "fixed / watching";
      case 4:
        return "temp fixed";
      case 5:
        return "fixed";
      case 6:
        return "no bug";

      default:
        break;
    }
  };

  const getColor = (level) => {
    switch (level) {
      case "minor":
        return "text-dark";
      case "major":
        return "text-warning";
      case "critical":
        return "text-danger";

      default:
        break;
    }
  };

  const [activePage, setActivePage] = useState(1);

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const handleBugChange = (e, id, state) => {
    updateBug(id, e.target.value, state);
  };

  const updateBug = async (id, value, state) => {
    setLoading(true);
    try {
      const request = await POST("/admin/bugs", {
        update: id,
        value: value,
        state: state,
      });
      if (request) {
        setBugs(request);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadBugs();
  }, [activePage]);

  return (
    <div>
      {loading === false && (
        <Accordion className="mt-2">
          {Object.values(bugs)[2].map((bug, id) => {
            return (
              <Card key={bug.id} className="shadow">
                <Accordion.Toggle as={Card.Header} eventKey={bug.id}>
                  <div className="d-flex justify-content-between">
                    <div>{bug.id}</div> - <div>{bug.text}</div> -{" "}
                    <div>{bugStatus(bug.status)}</div> -{" "}
                    <div className={getColor(bug.level)}>{bug.level}</div>
                  </div>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey={bug.id}>
                  <Card.Body>
                    <div>
                      <Row>
                        <Col>ID: {bug.id}</Col>
                        <Col>Titel: {bug.text}</Col>
                        <Col>von: {bug.JediUserChars.username}</Col>
                        <Col>
                          @{" "}
                          {moment(bug.submitdate)
                            .local()
                            .format("DD.MM.yyyy H:mm:ss")}
                        </Col>
                      </Row>
                      <Alert
                        style={{ whiteSpace: "pre-line" }}
                        variant="secondary"
                      >
                        {bug.description}
                      </Alert>
                    </div>
                    <Form>
                      <Form.Group controlId="status">
                        <Form.Label>Status ändern</Form.Label>
                        <Form.Control
                          onChange={(e) => handleBugChange(e, bug.id, "status")}
                          value={bug.status}
                          as="select"
                          custom
                        >
                          <option>0</option>
                          <option>1</option>
                          <option>2</option>
                          <option>3</option>
                          <option>4</option>
                          <option>5</option>
                        </Form.Control>
                      </Form.Group>
                      <Form.Group controlId="level">
                        <Form.Label>Level ändern</Form.Label>
                        <Form.Control
                          as="select"
                          custom
                          value={bug.level}
                          onChange={(e) => handleBugChange(e, bug.id, "level")}
                        >
                          <option></option>
                          <option>minor</option>
                          <option>major</option>
                          <option>critical</option>
                        </Form.Control>
                      </Form.Group>
                    </Form>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            );
          })}
        </Accordion>
      )}
      {loading === false && bugs.number >= 1 && (
        <Pagination
          totalItemsCount={Object.values(bugs)[1]}
          activePage={activePage}
          hideDisabled
          itemsCountPerPage={5}
          onChange={handlePageChange}
          itemClass="page-item"
          linkClass="page-link"
        />
      )}
      {loading === true && "loading..."}
    </div>
  );
};

export default AdminBugs;
