import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import UserCard from "./userCard";
import { GET } from "../../tools/fetch";
import Countdown from "../../tools/countdown";

const Explore = () => {
  const [loading, setLoading] = useState();
  const [exploreData, setExploreData] = useState(null);

  const getExplore = async () => {
    try {
      setLoading(true);
      const response = await GET("/character/getexplore");
      setExploreData(response);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const formatTime = (timestamp) => {
    const hours = Math.floor(timestamp / 3600);
    const minutes = Math.floor((timestamp % 3600) / 60);
    return `${hours < 10 ? "0" : ""}${hours}:${
      minutes < 10 ? "0" : ""
    }${minutes}`;
  };

  useEffect(() => {
    getExplore();
  }, []);

  return (
    <div
      className="p-2 container"
      style={{
        backgroundImage:
          "url(" + require("../../images/city/explore.png") + ")",
        marginLeft: "-15px",
        marginRight: "-15px",
        maxWidth: "1024px",
        margin: "0 auto",
        maxHeight: "1024px",
      }}
    >
      {loading === false && exploreData.length !== 0 && (
        <Card className="shadow-lg" style={{ backgroundColor: "#ffffffc2" }}>
          <Card.Body>
            <Card.Title>
              <div className="d-flex justify-content-between">
                <div>Überblick</div>
                <div>
                  <small>{loading === false && exploreData.explore.typ}</small>
                </div>
              </div>
              <hr />
            </Card.Title>
            <Row>
              <Col>aktueller Auftrag</Col>
              <Col className="text-right">
                {loading === false && exploreData.explore.status}
              </Col>
            </Row>
            <Row>
              <Col>restliche Zeit</Col>
              <Col className="text-right">
                {loading === false && (
                  <Countdown
                    format="explore"
                    timeTillDate={exploreData.explore.finish}
                    timeFormat="X"
                    onFinish="fertig..."
                  />
                )}
              </Col>
            </Row>
            <br />
            <Row>
              <Col>
                <Card.Title>Ereignisse</Card.Title>
              </Col>
              {loading === false && exploreData.encounterRunning === true && (
                <Col className="text-right">
                  Du befindest dich aktuell in einer Interaktion.
                </Col>
              )}
            </Row>
            <hr />
            <div
              style={{
                maxHeight: "770px",
                overflowY: "scroll",
                overflowX: "clip",
              }}
            >
              {loading === false &&
                exploreData.log.map((log, id) => {
                  if (log.end < Date.now() / 1000) {
                    return (
                      <Row key={id}>
                        <Col md="auto">
                          {formatTime(log.time - exploreData.explore.start)}
                        </Col>
                        <Col>{log.text}</Col>
                      </Row>
                    );
                  }
                })}
            </div>
          </Card.Body>
        </Card>
      )}
      {/* für später wenn mal mehr charaktere dazu kommen}
      <Card className="shadow-lg" style={{ backgroundColor: "#ffffffc2" }}>
        <Card.Body>
          <Card.Title>
            Charaktere
            <hr />
          </Card.Title>
          <div className="d-flex">
            {loading === false &&
              exploreData.explore.members.map((member, id) => {
                return (
                  <UserCard
                    HealthPro={member.HealthPro}
                    ManaPro={member.ManaPro}
                    name={member.username}
                    level={member.skills.level}
                  />
                );
              })}
          </div>
        </Card.Body>
            </Card>{*/}
    </div>
  );
};

export default Explore;
