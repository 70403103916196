import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Modal, Row } from "react-bootstrap";
import { POST } from "../../tools/fetch";

const SingleAuc = (props) => {
  const [auction, setAuction] = useState();
  const [loading, setLoading] = useState();
  const [bet, setBet] = useState(0);
  const [confirm, setConfirm] = useState(false);
  const [cancel, setCancel] = useState(false);
  const [insta, setInsta] = useState(false);
  const [direct, setDirect] = useState(false);

  const loadAuction = async () => {
    try {
      const response = await POST("/auction/singleauction", {
        id: props.auction,
      });
      setAuction(response);
      setBet(response.new_price);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const confirmCancle = async () => {
    try {
      await POST("/auction/cancel", { id: auction.auction.auctionid });
    } catch (error) {
    } finally {
      props.single("my");
    }
  };

  const confirmInsta = () => {
    confirmBuy("insta");
  };

  const confirmDirect = () => {
    confirmBuy("direct");
  };

  const confirmBuy = async (type) => {
    try {
      const request = await POST(`/auction/buy/${type}`, {
        id: auction.auction.auctionid,
      });
    } catch (error) {
    } finally {
      props.single("");
    }
  };

  const confirmBet = async () => {
    try {
      const request = await POST("/auction/bet", {
        id: auction.auction.auctionid,
        gebot: bet,
      });
    } catch (error) {
    } finally {
      props.single("bids");
    }
  };

  useEffect(() => {
    loadAuction();
  }, [props.auction]);

  return (
    <div>
      <Row>
        <Col md="3">
          {loading === false && (
            <Card
              className="ml-2 mr-2"
              style={{ backgroundColor: "rgba(245, 245, 245, 0.4)" }}
            >
              <Card.Img
                className="card-img-top mx-auto d-block mt-2"
                style={{ width: "100px" }}
                src={`../../images/items/${auction.auction.itemtype}/${auction.item.img}.jpg`}
              />
              <Card.Body className="text-center">
                <Card.Title className="h5">{auction.item.name}</Card.Title>
                <Card.Text>
                  <p>
                    Quality: {auction.item.qlvl}
                    <br />
                    {auction.item.reql > 0 && (
                      <>
                        required level: {auction.item.reql} <br />
                      </>
                    )}
                    {auction.item.reqs > 0 && (
                      <>
                        required skill: {auction.item.reqs} <br />
                      </>
                    )}
                    {auction.item.mindmg > 0 && (
                      <>
                        Damage: {auction.item.mindmg} - {auction.item.maxdmg}
                        <br />
                      </>
                    )}
                    {auction.item.stat1 && (
                      <>
                        {auction.item.stat1.split(",").join(" ")}
                        <br />
                      </>
                    )}
                    {auction.item.stat2 && (
                      <>
                        {auction.item.stat2.split(",").join(" ")}
                        <br />
                      </>
                    )}
                    {auction.item.stat3 && (
                      <>
                        {auction.item.stat3.split(",").join(" ")}
                        <br />
                      </>
                    )}
                    {auction.item.stat4 && (
                      <>
                        {auction.item.stat4.split(",").join(" ")}
                        <br />
                      </>
                    )}
                    {auction.item.stat5 && (
                      <>
                        {auction.item.stat5.split(",").join(" ")}
                        <br />
                      </>
                    )}
                  </p>
                </Card.Text>
              </Card.Body>
            </Card>
          )}
        </Col>
        <Col className="mr-2 ml-2 text-white">
          {loading === false && (
            <>
              <Row>
                <Col>Verkäufer: {auction.auction.seller.username}</Col>
                <Col>
                  Ablauf:{" "}
                  {moment(auction.auction.endtime, "X").format("DD.MM. HH:mm")}{" "}
                  Uhr
                </Col>
              </Row>
              <div>Startpreis: {auction.auction.startprice} Cr.</div>
              {auction.auction.act_price != 0 && (
                <div>
                  Aktueller Preis: {auction.auction.act_price} Cr. von{" "}
                  {auction.auction.act_price_user.username}
                </div>
              )}
              {auction.auction.direct_price != 0 && (
                <div>
                  Direktkauf: {auction.auction.direct_price} Cr.{" "}
                  {auction.direct === true && auction.my_act === false && (
                    <>
                      <br />
                      <Button
                        disabled={
                          Math.floor(Date.now() / 1000) >
                          auction.auction.endtime
                        }
                        onClick={() => setDirect(true)}
                        size="sm"
                        variant="success"
                      >
                        Direktkauf
                      </Button>
                    </>
                  )}
                </div>
              )}
              {auction.auction.instant_price != 0 && (
                <div>
                  Sofortkauf: {auction.auction.instant_price} Cr.
                  {auction.instant === true && auction.my_act === false && (
                    <>
                      <br />
                      <Button
                        disabled={
                          Math.floor(Date.now() / 1000) >
                          auction.auction.endtime
                        }
                        onClick={() => setInsta(true)}
                        size="sm"
                        variant="success"
                      >
                        Sofortkauf
                      </Button>
                    </>
                  )}
                </div>
              )}
              <div>Preisschritte: {auction.auction.inc_price} Cr.</div>
              {auction.my_act === true && auction.cancel === true && (
                <div>
                  keine Gebote bisher{" "}
                  <Button
                    disabled={
                      Math.floor(Date.now() / 1000) > auction.auction.endtime
                    }
                    size="sm"
                    variant="secondary"
                    onClick={() => setCancel(true)}
                  >
                    abbrechen
                  </Button>
                </div>
              )}
              {auction.my_act === false && auction.cancel === false && (
                <div>
                  <Form>
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label>
                        Gebot (mind. {auction.new_price} Cr.)
                      </Form.Label>
                      <Col className="p-0" xs="10" md="6">
                        <Form.Control
                          style={{ backgroundColor: "rgba(255,255,255,0.4)" }}
                          value={bet}
                          min={bet}
                          required
                          type="number"
                          onChange={(e) => setBet(e.target.value)}
                        />
                      </Col>
                    </Form.Group>
                  </Form>
                  <Button
                    disabled={
                      bet < auction.new_price ||
                      Math.floor(Date.now() / 1000) > auction.auction.endtime
                    }
                    onClick={() => setConfirm(true)}
                    variant="success"
                    size="sm"
                  >
                    bieten
                  </Button>
                </div>
              )}
              {auction.max_bet === true && (
                <div>
                  Dein Maximalgebot liegt bei: {auction.max_bet_credits.max_bet}{" "}
                  Cr.
                </div>
              )}
            </>
          )}
        </Col>
      </Row>
      <Modal show={confirm} onHide={() => setConfirm(false)}>
        <Modal.Header>
          <Modal.Title>Gebot abgeben</Modal.Title>
        </Modal.Header>
        <Modal.Body>Möchtest du wirklich {bet} Cr. bieten?</Modal.Body>
        <Modal.Footer>
          <Button onClick={confirmBet} size="sm" variant="outline-success">
            ja
          </Button>{" "}
          <Button
            onClick={() => setConfirm(false)}
            size="sm"
            variant="outline-danger"
          >
            nein
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={cancel} onHide={() => setCancel(false)}>
        <Modal.Header>
          <Modal.Title>Auktion abbrechen</Modal.Title>
        </Modal.Header>
        <Modal.Body>Möchtest du die Auktion wirklich abbrechen?</Modal.Body>
        <Modal.Footer>
          <Button onClick={confirmCancle} size="sm" variant="outline-success">
            ja
          </Button>{" "}
          <Button
            onClick={() => setCancel(false)}
            size="sm"
            variant="outline-danger"
          >
            nein
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={direct} onHide={() => setDirect(false)}>
        <Modal.Header>
          <Modal.Title>Direktkauf</Modal.Title>
        </Modal.Header>
        <Modal.Body>Möchtest du das Item wirklich direkt kaufen?</Modal.Body>
        <Modal.Footer>
          <Button onClick={confirmDirect} size="sm" variant="outline-success">
            ja
          </Button>{" "}
          <Button
            onClick={() => setDirect(false)}
            size="sm"
            variant="outline-danger"
          >
            nein
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={insta} onHide={() => setInsta(false)}>
        <Modal.Header>
          <Modal.Title>Sofortkauf</Modal.Title>
        </Modal.Header>
        <Modal.Body>Möchtest du das Item wirklich sofort kaufen?</Modal.Body>
        <Modal.Footer>
          <Button onClick={confirmInsta} size="sm" variant="outline-success">
            ja
          </Button>{" "}
          <Button
            onClick={() => setInsta(false)}
            size="sm"
            variant="outline-danger"
          >
            nein
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default SingleAuc;
