import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import Countdown from "../../tools/countdown";
import { GET } from "../../tools/fetch";

const MyBids = (props) => {
  const [loading, setLoading] = useState();
  const [auctions, setAuctions] = useState();

  const loadAuctions = async () => {
    try {
      setLoading(true);
      const request = await GET("/auction/myBids");
      setAuctions(request.bidding);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadAuctions();
  }, []);

  return (
    <Table striped size="sm" borderless className="text-white">
      <thead>
        <tr>
          <th>Item</th>
          <th>Stats</th>
          <th>Verkäufer</th>
          <th>Gebot</th>
          <th>SK</th>
          <th>DK</th>
          <th>Höchstgebot</th>
          <th>Ablauf</th>
        </tr>
      </thead>
      <tbody>
        {loading === false &&
          Object.values(auctions).map((element) => {
            let dmg = "";
            let stat1 = "";
            let stat2 = "";
            let stat3 = "";
            let stat4 = "";
            let stat5 = "";

            if (element.item.mindmg !== 0) {
              dmg = "Dmg: " + element.item.mindmg + " - " + element.item.maxdmg;
            }
            if (element.item.stat1 !== "") {
              stat1 = element.item.stat1;
            }
            if (element.item.stat2 !== "") {
              stat2 = element.item.stat2;
            }
            if (element.item.stat3 !== "") {
              stat3 = element.item.stat3;
            }
            if (element.item.stat4 !== "") {
              stat4 = element.item.stat4;
            }
            if (element.item.stat5 !== "") {
              stat5 = element.item.stat5;
            }
            return (
              <tr
                onClick={() => props.single(element.auctionid)}
                key={element.auctionid}
              >
                <td>{element.item.name}</td>
                <td>{dmg + stat1 + stat2 + stat3 + stat4 + stat5}</td>
                <td>{element.seller.username}</td>
                <td>{element.act_price + " Cr. (" + element.bids + ")"}</td>
                <td>{element.instant_price}</td>
                <td>{element.direct_price}</td>
                <td>{element.max_bet_user.username}</td>
                <td>
                  <Countdown
                    onFinish="finish"
                    timeTillDate={element.endtime}
                    timeFormat="X"
                  />
                </td>
              </tr>
            );
          })}
      </tbody>
    </Table>
  );
};

export default MyBids;
