import React, { useState, useEffect } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { GET } from "../../tools/fetch";

const HighestItems = (props) => {
  const [items, setItems] = useState();
  const [loading, setLoading] = useState();

  const loadItems = async () => {
    try {
      setLoading(true);
      const request = await GET("/auction/highestitems");
      setItems(request.items);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadItems();
  }, []);
  return (
    <Col>
      <Card style={{ opacity: "0.7" }}>
        <Card.Body>
          <Card.Title>Höchste Erlöse</Card.Title>
          {loading === false &&
            items.map((item) => {
              return (
                <Row
                  onClick={() => {
                    props.single("historyAuc");
                    props.setHistoryAuc(item.auctionid);
                  }}
                  style={{ cursor: "pointer" }}
                  key={item.itemid}
                >
                  <Col className="pr-0">
                    <u>{item.item.name}</u>
                  </Col>
                  <Col className="pl-0" xs="auto">
                    <u>{item.price} Cr.</u>
                  </Col>
                </Row>
              );
            })}
        </Card.Body>
      </Card>
    </Col>
  );
};

export default HighestItems;
