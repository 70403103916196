import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row, Spinner } from "react-bootstrap";
import { GET } from "../../tools/fetch";
import ActiveUser from "./activeUser";
import AskedItems from "./askedItems";
import Auctions from "./auctions";
import HighestItems from "./highestItems";
import History from "./history";
import MyAuctions from "./myAuctions";
import MyBids from "./myBids";
import NewAuction from "./newAuction";
import SingleAuc from "./singleAuc";
import SingleAucHis from "./singleAucHis";
import { useSelector } from "react-redux";

const Auction = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const [view, setView] = useState("");
  const [loading, setLoading] = useState();
  const [auctions, setAuctions] = useState();
  const [myAuctions, setMyAuctions] = useState();
  const [myBids, setMyBids] = useState();
  const [historyAuc, setHistoryAuc] = useState(0);
  const [refresh, setRefresh] = useState();
  const data = useSelector((state) => state.skills.skills);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  const loadAuctions = async () => {
    try {
      setLoading(true);
      const request = await GET("/auction/start");
      setAuctions(request.all);
      const myRequest = await GET("/auction/myAuctions");
      setMyAuctions(myRequest.selling);
      const bids = await GET("/auction/myBids");
      setMyBids(Object.keys(bids.bidding));
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadAuctions();
  }, [view]);

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    /*
    let jwt = sessionStorage.getItem("jwt") || null;
    const sse = new EventSourcePolyfill(
      "http://localhost/qyr/rest-api/auction/sse",
      {
        headers: {
          Authorization: "Bearer " + jwt,
        },
      }
    );
    */

    //const sse = new EventSource("http://localhost/qyr/rest-api/auction/sse");
    const sse = new EventSource(
      "https://starwarslegends.de/rest-api/auction/sse"
    );

    function getRealtimeData(data) {
      // process the data here,
      // then pass it to state to be rendered
      setAuctions(data.auctions);
    }
    sse.onmessage = (e) => {
      getRealtimeData(JSON.parse(e.data));
    };
    sse.onerror = () => {
      // error log here
      sse.close();
    };

    return () => {
      sse.close();
    };
  }, []);

  const isMobile = width <= 768;

  return (
    <div>
      {data.char.actionid === 0 &&
      data.char.targetid === 0 &&
      data.char.targettime === 0 ? (
        <div
          className="w-100 pt-2 pb-2 d-flex"
          style={{
            backgroundImage:
              "url(" + require("../../images/monster/neu/ah.jpg") + ")",
            backgroundSize: "cover",
            backgroundPosition: "center",
            minHeight: "100%",
          }}
        >
          <div
            className="align-items-center justify-content-center"
            style={{
              margin: "0 auto",
              width: "90%",
              height: "90%",
              WebkitBackdropFilter: " blur( 5px )",
              background: "rgba(255, 255, 255, 0.19)",
              boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
              backdropFilter: "blur( 5px )",
              borderRadius: "10px",
              border: "1px solid rgba(255, 255, 255, 0.3)",
            }}
          >
            <div className="pl-3 pr-3 pt-3 h4 d-flex justify-content-between">
              <div className="text-white">
                {view === "bids"
                  ? "Meine Gebote"
                  : view === "my"
                  ? "Meine Auktionen"
                  : view === "all"
                  ? "Alle Auktionen"
                  : view === "new"
                  ? "Item anbieten"
                  : "Auktionshaus"}
              </div>
              {refresh === true && `${refresh}`}
              <Button
                style={{ backgroundColor: "rgba(255,255,255,0.7)" }}
                size="sm"
                variant="light"
                onClick={() => setView(view === "" ? "history" : "")}
              >
                {view === "" ? "Historie" : "Home"}
              </Button>
            </div>
            {view === "" && (
              <>
                <Row className="pl-3 pr-3 pt-2">
                  <Col>
                    <Card
                      style={{ cursor: "pointer", opacity: "0.7" }}
                      onClick={() => setView("all")}
                    >
                      <Card.Body>
                        <Card.Title className="d-flex justify-content-between">
                          <div>Alle Auktionen</div>
                          <div>
                            {loading === false ? (
                              auctions.length
                            ) : (
                              <Spinner animation="border" size="sm" />
                            )}
                          </div>
                        </Card.Title>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card
                      style={{ cursor: "pointer", opacity: "0.7" }}
                      onClick={() => setView("bids")}
                    >
                      <Card.Body>
                        <Card.Title className="d-flex justify-content-between">
                          <div>Meine Gebote</div>
                          <div>
                            {loading === false ? (
                              myBids.length
                            ) : (
                              <Spinner animation="border" size="sm" />
                            )}
                          </div>
                        </Card.Title>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card
                      style={{ cursor: "pointer", opacity: "0.7" }}
                      onClick={() => setView("my")}
                    >
                      <Card.Body>
                        <Card.Title className="d-flex justify-content-between">
                          <div>Meine Auktionen</div>
                          <div>
                            {loading === false ? (
                              myAuctions.length
                            ) : (
                              <Spinner animation="border" size="sm" />
                            )}
                          </div>
                        </Card.Title>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col>
                    <Card
                      style={{ cursor: "pointer", opacity: "0.7" }}
                      onClick={() => setView("new")}
                    >
                      <Card.Body>
                        <Card.Title className="d-flex justify-content-between">
                          <div>Item anbieten</div>
                          <div>+</div>
                        </Card.Title>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
                {!isMobile && (
                  <Row className="pl-3 pr-3 pt-2">
                    <HighestItems
                      single={setView}
                      setHistoryAuc={setHistoryAuc}
                    />
                    <AskedItems />
                    <ActiveUser />
                  </Row>
                )}
              </>
            )}
            {view === "all" && <Auctions single={setView} />}
            {view === "history" && <History />}
            {view === "my" && <MyAuctions single={setView} />}
            {view === "bids" && <MyBids single={setView} />}
            {view === "new" && <NewAuction single={setView} />}
            {view > 0 && <SingleAuc auction={view} single={setView} />}
            {view === "historyAuc" && (
              <SingleAucHis auction={historyAuc} single={setView} />
            )}
          </div>
        </div>
      ) : (
        <div>Du bist beschäftigt</div>
      )}
    </div>
  );
};

export default Auction;
