import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { GET, POST } from "../../tools/fetch";

const AdminOffline = () => {
  const [status, setStatus] = useState();

  const getStatus = async () => {
    try {
      const response = await GET("/admin/offline");
      if (response) {
        setStatus(response.status2.status);
      }
    } catch (error) {}
  };

  const handleChangeStatus = async (wert) => {
    const request = await POST("/admin/offline", { wert: wert });
    if (request) {
      setStatus(wert);
    }
  };

  useEffect(() => {
    getStatus();
  }, []);

  return (
    <div>
      <div>
        aktueller Status:{" "}
        {status === undefined
          ? "loading..."
          : status === 2
          ? "online"
          : "offline"}
      </div>
      <Button onClick={() => handleChangeStatus(1)}>offline nehmen</Button>{" "}
      <Button onClick={() => handleChangeStatus(2)}>online stellen</Button>
    </div>
  );
};

export default AdminOffline;
