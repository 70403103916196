import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchAllianceData } from "../../redux/actions/allianceActions";
import { Col, Row } from "react-bootstrap";
import Xarrow from "react-xarrows";
import { GET } from "../../tools/fetch";
import Menu from "./alliance_menu";

const AlliResearch = () => {
  const [researchData, setData] = useState();
  const [loading, setLoading] = useState();
  const dispatch = useDispatch();

  const AlliData = useSelector((state) => state.alliance);

  const getData = async () => {
    const data = await GET("/alliances/research");
    if (data) {
      setData(data);
      setLoading(false);
    }
  };

  const loadingAllianceData = async () => {
    try {
      setLoading(true);
      const response = await GET("/alliances");
      if (response) {
        dispatch(fetchAllianceData(response));
      }
    } catch (e) {
      console.error(e);
    } finally {
      // finally wird immer ausgefuehrt.
      // dadurch wird der state auch immer danach false gesetzt.
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
    loadingAllianceData();
  }, []);

  return (
    <div>
      <Row className="mt-2 justify-content-center">
        <Col md={6} xl={4} xs={10} id="0" className="text-center shadow">
          {loading === false && researchData && (
            <>
              <Row>
                <img
                  className="pl-2"
                  style={{ width: "25%" }}
                  src={
                    researchData.sumBook < 10
                      ? require(`../../images/items/alli/book/book.png`)
                      : require(`../../images/items/books/faithfulness1.jpg`)
                  }
                />
                <Col className="small">
                  {researchData.sumBook < 10
                    ? "Diese Kapitel scheinen eine Menge nützliche Dinge preiszugeben. Wir müssen alle finden um das Buch zu vervollständigen. Eine große Womp-Ratte soll diese bewachen."
                    : "Wir haben alle Kapitel gesammelt und können die einzelnen Geheimnisse erforschen."}
                </Col>
              </Row>
              <div>
                <small>{researchData.sumBook}/10</small>
              </div>
            </>
          )}
        </Col>
      </Row>
      <Xarrow
        color="black"
        path="grid"
        showHead={false}
        strokeWidth={1}
        startAnchor="bottom"
        endAnchor="top"
        start="0" //can be react ref
        end="1" //or an id
      />
      <Xarrow
        color="black"
        path="grid"
        showHead={false}
        strokeWidth={1}
        startAnchor="bottom"
        endAnchor="top"
        start="0" //can be react ref
        end="2" //or an id
      />
      <Row className="justify-content-between mx-auto mb-3">
        <Col xs={5} id="1" className="pt-2 mt-4 text-center shadow">
          {loading === false && researchData && (
            <>
              <div>
                <img
                  style={{ width: "25%" }}
                  src={
                    researchData.sumBook < 10
                      ? require(`../../images/questionmark.png`)
                      : researchData.sumBook === 10
                      ? require(`../../images/ffars_g.png`)
                      : require(`../../images/ffars.gif`)
                  }
                />
                <div className="small">
                  {researchData.sumBook < 10
                    ? ""
                    : researchData.sumBook === 10
                    ? "Die Augen der Reeks scheinen besonders gut beim finden von Beute zu sein."
                    : "Wir haben alle Kapitel gesammelt und können die einzelnen Geheimnisse erforschen."}
                </div>
              </div>
              {researchData.sumBook === 10 && (
                <div>
                  <small>{researchData.sumBook}/10</small>
                </div>
              )}
            </>
          )}
        </Col>
        <Col xs={5} id="2" className="pt-2 mt-4 text-center shadow">
          {loading === false && researchData && (
            <>
              <div>
                <img
                  style={{ width: "25%" }}
                  src={
                    researchData.sumBook < 10
                      ? require(`../../images/questionmark.png`)
                      : researchData.sumBook === 10
                      ? require(`../../images/items/misc/pt-monster-heart3_g.png`)
                      : require(`../../images/items/misc/pt-monster-heart3.jpg`)
                  }
                />
                <div className="small">
                  {researchData.sumBook < 10
                    ? ""
                    : researchData.sumBook === 10
                    ? "Die Herzen der Reeks fördern eure Tapferkeit."
                    : "Wir haben alle Kapitel gesammelt und können die einzelnen Geheimnisse erforschen."}
                </div>
              </div>
              {researchData.sumBook === 10 && (
                <div>
                  <small>{researchData.sumBook}/10</small>
                </div>
              )}
            </>
          )}
        </Col>
      </Row>
      {AlliData && loading === false && researchData && (
        <Menu alliId={AlliData.AlliData.alliance.id} />
      )}
    </div>
  );
};

export default AlliResearch;
