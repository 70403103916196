import React from 'react';
import Map2 from '../quest/map2';

const City = () => {
  return ( 
    <div>
      <Map2 />
    </div>
   );
}
 
export default City;