import React from "react";
import { Button } from "react-bootstrap";
import { GET } from "../../tools/fetch";

const AdminShop = () => {
  const empty = async () => {
    await GET(
      "/cron/deleteitems/cde389ed5f7c850f3c8efbf2759c65e15b92eee5738858f6de061ef1b0026130"
    );
  };
  const fill = async () => {
    await GET(
      "/cron/createitems/cde389ed5f7c850f3c8efbf2759c65e15b92eee5738858f6de061ef1b0026131"
    );
  };
  return (
    <div>
      <br />
      <Button onClick={empty}>Shop leeren</Button>
      <br />
      <br />
      <Button onClick={fill}>Shop füllen</Button>
    </div>
  );
};

export default AdminShop;
