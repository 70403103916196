import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import { GET, POST } from "../../tools/fetch";
import Countdown from "../../tools/countdown";
import Item from "../item";
import MightBar from "./mightBar";

const Map2 = () => {
  const [tiles, setTiles] = useState([]);
  const [mapSize, setMapSize] = useState({
    width: 768,
    height: 288,
  });

  const [surroundings, setSurroundings] = useState([]);
  const [discovered, setDiscovered] = useState([]);
  const [discoverRange, setDiscoverRange] = useState(1);

  const [playerP, setPlayerP] = useState({
    x: 22,
    y: 3,
    tile: {},
  });

  const [action, setAction] = useState("");
  const [loot, setLoot] = useState();

  const [loading, setLoading] = useState();
  const [response, setResponse] = useState();
  const [report, setReport] = useState();
  const [fighting, setFighting] = useState(false);

  const [win, setWin] = useState(null);
  const userid = useSelector((state) => state.user.userId);

  const checkClickable = () => {
    const _surroundings = [
      { x: playerP.x - 1, y: playerP.y - 1 },
      { x: playerP.x, y: playerP.y - 1 },
      { x: playerP.x + 1, y: playerP.y - 1 },
      { x: playerP.x - 1, y: playerP.y },
      { x: playerP.x + 1, y: playerP.y },
      { x: playerP.x + 1, y: playerP.y + 1 },
      { x: playerP.x, y: playerP.y + 1 },
      { x: playerP.x - 1, y: playerP.y + 1 },
    ];
    const tempS = [];
    //Nicht auf WÄnde laufen
    for (let index = 0; index < tiles.length; index++) {
      for (let index2 = 0; index2 < _surroundings.length; index2++) {
        if (
          tiles[index].some(
            (tile) =>
              tile.x / 32 === _surroundings[index2].x &&
              tile.y / 32 === _surroundings[index2].y &&
              tile.type !== "gray"
          )
        ) {
          const x2 = _surroundings[index2].x;
          _surroundings[index2].type = tiles[_surroundings[index2].y][x2].type;
          _surroundings[index2].img = tiles[_surroundings[index2].y][x2].img;
          tempS.push(_surroundings[index2]);
        }
      }
    }
    setSurroundings(tempS);
  };

  const discover = () => {
    const _discovered = [];
    for (let index = 1; index < discoverRange + 1; index++) {
      _discovered.push(
        { x: playerP.x, y: playerP.y },
        { x: playerP.x - index, y: playerP.y - index },
        { x: playerP.x, y: playerP.y - index },
        { x: playerP.x + index, y: playerP.y - index },
        { x: playerP.x - index, y: playerP.y },
        { x: playerP.x + index, y: playerP.y },
        { x: playerP.x + index, y: playerP.y + index },
        { x: playerP.x, y: playerP.y + index },
        { x: playerP.x - index, y: playerP.y + index },
        { x: playerP.x + (index - 1), y: playerP.y - index },
        { x: playerP.x + index, y: playerP.y + (index - 1) },
        { x: playerP.x + index, y: playerP.y - (index - 1) },
        { x: playerP.x - index, y: playerP.y + (index - 1) },
        { x: playerP.x - index, y: playerP.y - (index - 1) },
        { x: playerP.x + index, y: playerP.y + (index - (index - 1)) },
        { x: playerP.x + index, y: playerP.y - (index - (index - 1)) },
        { x: playerP.x - index, y: playerP.y - (index - (index - 1)) },
        { x: playerP.x - index, y: playerP.y + (index - (index - 1)) },
        { x: playerP.x + (index - (index - 1)), y: playerP.y - index },
        { x: playerP.x + (index - (index - 1)), y: playerP.y + index },
        { x: playerP.x - (index - (index - 1)), y: playerP.y - index },
        { x: playerP.x - (index - (index - 1)), y: playerP.y + index },
        { x: playerP.x + (index - 1), y: playerP.y + index },
        { x: playerP.x - (index - 1), y: playerP.y + index },
        { x: playerP.x - (index - 1), y: playerP.y - index }
      );
    }
    const tempNewBlocks = [];
    _discovered.map((block) => {
      if (
        discovered.some(
          (Dblock) => Dblock.x === block.x && Dblock.y === block.y
        )
      ) {
        //wenn tile schon dabei, dann gar nichts
      } else {
        if (block.x <= 24 && block.x >= 0 && block.y <= 8 && block.y >= 0) {
          tempNewBlocks.push(block);
        }
      }
    });
    const together = discovered.concat(tempNewBlocks);
    setDiscovered(together);
  };

  const handleCast = (force) => {
    if (response.doing === "no") {
      switch (force) {
        case "fseei":
          //hier noch Prüfung - für 25 Pkt fseei 1 Range dazu bis 3 - also 75 pkt fseei
          if (discoverRange <= 2) {
            setDiscoverRange(discoverRange + 3);
          }
          break;

        default:
          break;
      }
    }
  };

  const consumeItem = async () => {
    //Leeren falls es vorher noch einen loot gab
    setLoot();
    //Box-Item
    await GET(
      "/cron/mapLoot/cde389ed5f7c850f3c8efbf2759c65e15b92eee5738858f6de061ef1b0026129"
    );

    setAction("");
  };

  const handleSkipLoot = () => {
    setLoot();
  };

  const handleAttack = async () => {
    await POST("/city/initfight", { enemy: 13, fight: "y" });
    await loadData();
  };

  const handleReport = async () => {
    setFighting(true);
    const response = await GET("/city/initfight/fight");
    if (response.fight_report) {
      setReport(response.fight_report);
      setWin(response.fight_report.winner);
    } else if (response.doing === "yes") {
      const report = await GET("/city/initfight/view");
      if (report.fight_report) {
        setReport(report.fight_report);
        setWin(report.fight_report.winner);
      }
    }
    setFighting(false);
  };

  const loadData = async () => {
    try {
      setLoading(true);
      const response = await GET("/city/initfight");
      if (response) {
        setResponse(response);
      }
    } catch {
    } finally {
      setLoading(false);
    }
  };

  //intial load
  useEffect(() => {
    loadData();
  }, []);

  //Map erstellung
  useEffect(() => {
    const _tiles = [];
    let id = 0;
    for (let y = 0; y < mapSize.height; y = y + 32) {
      const row = [];
      for (let x = 0; x < mapSize.width; x = x + 32) {
        let type;
        let img = "";
        //Gegner
        if (x / 32 === 19 && y / 32 === 2) {
          img = "ranc";
        }
        //Türen
        if ((x / 32 === 0 && y / 32 === 4) || (x / 32 === 19 && y / 32 === 2)) {
          type = "peru";
        }
        //Kisten
        else if (x / 32 === 12 && y / 32 === 4) {
          type = "wheat";
          img = "loot";
        }
        //Mauern           //raum
        //((x / 32 === 22 || x / 32 === 21 || x / 32 === 20 || x / 32 === 19 || x / 32 === 18) && (y / 32 === 4 || y / 32 === 3))
        else if (
          (x / 32 === 22 && y / 32 === 4) ||
          (x / 32 === 21 && y / 32 === 4) ||
          (x / 32 === 20 && y / 32 === 4) ||
          (x / 32 === 19 && y / 32 === 4) ||
          (x / 32 === 19 && y / 32 === 3) ||
          (x / 32 === 19 && y / 32 === 2) ||
          (x / 32 === 19 && y / 32 === 1)
        ) {
          type = "gray";
        }
        //rand oben               //rand unten              //rand links    //rand rechts
        else if (id >= 0 && id <= 22) {
          type = "randO";
        } else if (id <= 214 && id >= 192) {
          type = "randU";
        } else if (id === 23) {
          type = "eckeRO";
        } else if (id === 215) {
          type = "eckeRU";
        } else if (x / 32 === 0) {
          type = "randL";
        } else if (x / 32 === 23) {
          type = "randR";
        } else {
          type = "wheat";
        }
        row.push({
          x,
          y,
          type,
          img,
          id: id++,
          v: { x: -32, y: -32 },
        });
      }
      _tiles.push(row);
    }
    setTiles(_tiles);
  }, []);

  useEffect(() => {
    checkClickable();
    discover();
  }, [tiles, playerP, discoverRange]);

  //checking standing tile
  useEffect(() => {
    switch (playerP.tile.img) {
      case "loot":
        setAction("öffne Box...");
        consumeItem();
        const newTiles = [...tiles];
        newTiles[4][12] = { ...tiles[4][12], img: "" };
        setTiles(newTiles);
        break;

      default:
        break;
    }
  }, [playerP]);

  //check winning
  useEffect(() => {
    if (win === userid) {
      const newTiles = [...tiles];
      newTiles[2][19] = { ...tiles[2][19], img: "" };
      setTiles(newTiles);
    }
  }, [win]);

  //check surrounding tiles
  useEffect(() => {
    if (surroundings.some((block) => block.img === "ranc")) {
      setAction("attack");
    } else {
      setAction("");
    }
  }, [surroundings, loot]);

  return (
    <div>
      <div style={{ width: mapSize.width }}>
        {tiles.map((row, y) => (
          <div key={y} className="d-flex">
            {row.map((tile, x) => {
              //Background
              let bg;
              if (discovered.some((block) => block.x === x && block.y === y)) {
                bg = tile.type;
              } else {
                bg = "black";
              }
              //Player Image
              let img;
              if (playerP.x === x && playerP.y === y) {
                img = (
                  <img
                    style={{ height: "89%", width: "89%" }}
                    src={require(`../../images/player.png`)}
                    alt=""
                  />
                );
              } else if (tile.img === "loot" && bg !== "black") {
                img = (
                  <img
                    style={{ height: "89%", width: "89%" }}
                    src={require(`../../images/items/misc/executivecase1.gif`)}
                    alt=""
                  />
                );
              } else if (tile.img === "ranc" && bg !== "black") {
                img = (
                  <img
                    style={{ height: "89%", width: "89%" }}
                    src={require(`../../images/monster/rancor.png`)}
                    alt=""
                  />
                );
              }

              return (
                <div
                  key={x}
                  onClick={
                    surroundings.some(
                      (block) =>
                        block.x === x && block.y === y && block.img !== "ranc"
                    ) &&
                    response &&
                    response.doing === "no"
                      ? () => setPlayerP({ x: x, y: y, tile: tile })
                      : null
                  }
                  //className="border small"
                  style={{
                    backgroundColor: bg === "black" && bg,
                    backgroundImage:
                      bg === "wheat"
                        ? "url(" + require("../../images/map/5.png") + ")"
                        : bg === "randL" || bg === "randR"
                        ? "url(" + require("../../images/map/6.png") + ")"
                        : bg === "randO"
                        ? "url(" + require("../../images/map/9.png") + ")"
                        : bg === "randU"
                        ? "url(" + require("../../images/map/9.png") + ")"
                        : bg === "eckeRO"
                        ? "url(" + require("../../images/map/12.png") + ")"
                        : bg === "eckeRU" &&
                          "url(" + require("../../images/map/13.png") + ")",
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    rotate:
                      (bg === "randO" || bg === "eckeRO" || bg === "randL") &&
                      "180deg",
                    cursor:
                      surroundings.some(
                        (block) =>
                          block.x === x && block.y === y && block.img !== "ranc"
                      ) &&
                      response &&
                      response.doing === "no"
                        ? "pointer"
                        : "auto",
                    width: 32,
                    height: 32,
                  }}
                >
                  {img}
                  {/*tile.img*/}
                </div>
              );
            })}
          </div>
        ))}
      </div>

      <div className="text-center">
        {action === "attack" && response && response.doing === "no" ? (
          <Button onClick={handleAttack}>attack</Button>
        ) : (
          action
        )}
      </div>
      {loading === false && !report && response.doing === "yes" ? (
        <Countdown
          onFinish={
            <div className="text-center">
              <Button
                disabled={fighting}
                className="text-dark"
                variant="link"
                onClick={handleReport}
              >
                {fighting ? "lade..." : "Bericht"}
              </Button>
            </div>
          }
          timeTillDate={response.char.targettime}
          timeFormat="X"
        />
      ) : null}
      {loot && (
        <div className="text-center">
          <div className="d-flex justify-content-center">
            <Item type="box" item={loot} imgFolder={loot.type} />
          </div>
          <Button size="sm" onClick={handleSkipLoot}>
            ok
          </Button>
        </div>
      )}
      {loading === false && report ? (
        <div>
          <div dangerouslySetInnerHTML={{ __html: report.report }}></div>
          <Button
            className="text-dark"
            variant="link"
            onClick={() => {
              setReport("");
              loadData();
            }}
          >
            verwerfen
          </Button>
        </div>
      ) : null}
      <MightBar doing={response && response.doing} handleCast={handleCast} />
    </div>
  );
};

export default Map2;
