import React from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import "./itemCardInventory.module.css";
import { useSelector } from "react-redux";

const ItemCardInventory = (props) => {
  const inv = useSelector((state) => state.skills.inv);

  const postDataHandler = () => {
    props.dropItem({ itemid: props.item.itemid, type: props.type });
  };

  let dmg = "";
  if (props.item.mindmg !== 0 && props.item.mindmg !== undefined) {
    dmg = "Damage: " + props.item.mindmg + " - " + props.item.maxdmg;
  }

  if (props.type === "cash") {
    dmg = props.item.cash + " Credits";
    props.item.img = "cash";
    props.item.stat1 = "";
    props.item.stat2 = "";
    props.item.stat3 = "";
    props.item.stat4 = "";
    props.item.stat5 = "";
    props.item.reql = 0;
    props.item.reqs = 0;
  }

  let ImgEnd = "";
  if (
    (props.imgFolder === "misc" &&
      props.item.img !== "executivecase1" &&
      props.item.img !== "cell") ||
    props.imgFolder === "bots"
  ) {
    ImgEnd = "png";
  } else {
    ImgEnd = "jpg";
  }

  const styleCard = {
    border: "1px solid rgba(0,0,0,0.125)",
    cursor:
      (props.item.consumable === true &&
        props.imgFolder === "misc" &&
        "pointer") ||
      (props.item.reql <= inv.char.skills.level &&
        props.item.reqs <= inv.char.skills.dex &&
        props.imgFolder !== "misc" &&
        props.imgFolder !== "bots" &&
        "pointer"),
  };
  return (
    <div key={props.item.itemid}>
      <Card
        style={styleCard}
        className="shadow mr-1"
        onClick={() => {
          if (props.item.consumable === true && props.imgFolder === "misc") {
            props.itemModal(true);
            props.consumItem(props.item);
          } else if (
            props.item.reql <= inv.char.skills.level &&
            props.item.reqs <= inv.char.skills.dex &&
            props.imgFolder !== "misc" &&
            props.imgFolder !== "bots"
          ) {
            props.equipItem(props.item);
          }
        }}
      >
        <Card.Img
          variant="top"
          style={{ width: "100px" }}
          className="mx-auto d-block mt-2"
          src={require(`../../images/items/${props.imgFolder}/${props.item.img}.${ImgEnd}`)}
        />
        <Card.Body className="text-center">
          <Card.Title>
            {props.item.name === "Mana-Potion" && <>{props.cMana}x </>}
            {props.item.name === "Mega-Potion" && <>{props.cMega}x </>}
            {props.item.name === "Health-Potion" && <>{props.cHealth}x </>}
            {props.item.name === "Energie-Potion" && <>{props.cEnergie}x </>}
            {props.item.name === "Rancor-Lootbox (S)" && <>{props.cRancS}x </>}
            {props.item.name}
          </Card.Title>
          <Card.Text>
            {props.item.uni === "y" ? (
              <span className="text-warning">unique item</span>
            ) : null}
            {props.item.uni === "y" ? <br /> : null}
            {props.item.nodrop === "y" ? "no drop" : null}
            {props.item.nodrop === "y" ? <br /> : null}
            {props.item.crafted === "y" ? "crafted" : null}
            {props.item.crafted === "y" ? <br /> : null}
            {dmg}
            {dmg ? <br /> : null}
            {props.item.stat1_mod !== "inc" && props.item.stat1_mod !== "dec"
              ? props.item.stat1_mod
              : null}
            {props.item.stat1_stat}
            {props.item.stat1_mod === "inc"
              ? " +"
              : props.item.stat1_mod === "dec"
              ? " -"
              : null}
            {props.item.stat1_value !== "0" && props.item.stat1_value}
            {props.item.stat1_value !== "0" && <br />}

            {(props.item.name === "Gold Essenz" ||
              props.item.name === "Silver Essenz" ||
              props.item.name === "Rusty Essenz" ||
              props.item.name === "Onion Essenz") &&
              props.item.weight}
            {props.imgFolder !== "misc" && (
              <>
                {props.item.stat2_stat}
                {props.item.stat2_mod === "inc"
                  ? " +"
                  : props.item_stat2_mod === "dec"
                  ? " -"
                  : null}
                {props.item.stat2_value !== "0" && props.item.stat2_value}
                {props.item.stat2_value !== "0" && <br />}

                {props.item.stat3_stat}
                {props.item.stat3_mod === "inc"
                  ? " +"
                  : props.item_stat3_mod === "dec"
                  ? " -"
                  : null}
                {props.item.stat3_value !== "0" && props.item.stat3_value}
                {props.item.stat3_value !== "0" && <br />}

                {props.item.stat4_stat}
                {props.item.stat4_mod === "inc"
                  ? " +"
                  : props.item_stat4_mod === "dec"
                  ? " -"
                  : null}
                {props.item.stat4_value !== "0" && props.item.stat4_value}
                {props.item.stat4_value !== "0" && <br />}

                {props.item.stat5_stat}
                {props.item.stat5_mod === "inc"
                  ? " +"
                  : props.item_stat5_mod === "dec"
                  ? " -"
                  : null}
                {props.item.stat5_value !== "0" && props.item.stat5_value}
                {props.item.stat5_value !== "0" && <br />}
              </>
            )}
            {props.item.reql !== 0 ? `mind. level: ${props.item.reql}` : null}
            {props.item.reql !== 0 ? <br /> : null}
            {props.item.reqs !== 0 ? `mind. skill: ${props.item.reqs}` : null}
          </Card.Text>
        </Card.Body>
      </Card>
    </div>
  );
};

export default ItemCardInventory;
