import React, { useEffect, useState } from "react";
import { GET, POST } from "../../tools/fetch";
import Pagination from "react-js-pagination";
import {
  Accordion,
  Alert,
  Button,
  ButtonGroup,
  Card,
  Col,
  Form,
  Row,
} from "react-bootstrap";
import moment from "moment";

const AdminUser = () => {
  const [activePage, setActivePage] = useState(1);
  const [sort, setSort] = useState("userid");
  const [direction, setDirection] = useState("asc");
  const [loading, setLoading] = useState();

  const [users, setUsers] = useState();

  const [activeKey, setActiveKey] = useState(0);

  const loadUsers = async () => {
    setLoading(true);
    try {
      const response = await GET(
        `/admin/users?page=${activePage}&sort=${sort}&direction=${direction}`
      );
      setUsers(response);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const handleKeyChange = (id) => {
    if (activeKey === id) {
      setActiveKey(0);
    } else {
      setActiveKey(id);
    }
  };

  const handleSortDirection = (sortAfter) => {
    setSort(sortAfter);
    if (sortAfter === sort) {
      if (direction === "asc") {
        setDirection("desc");
      } else {
        setDirection("asc");
      }
    }
  };

  const handleUserChange = (e, id, state) => {
    updateUser(id, e.target.value, state);
  };

  const updateUser = async (id, value, state) => {
    setLoading(true);
    try {
      const request = await POST(`/admin/users?page=${activePage}&sort=${sort}&direction=${direction}`, {
        update: id,
        value: value,
        state: state,
      });
      if (request) {
        setUsers(request);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadUsers();
  }, []);

  useEffect(() => {
    loadUsers();
  }, [activePage, sort, direction]);

  return (
    <div>
      {loading === false && (
        <div>
          <div>sortieren nach</div>
          <ButtonGroup size="sm">
            <Button
              variant="outline-primary"
              onClick={() => handleSortDirection("userid")}
            >
              id
            </Button>
            <Button
              variant="outline-primary"
              onClick={() => handleSortDirection("lastaccess")}
            >
              lastaccess
            </Button>
            <Button
              variant="outline-primary"
              onClick={() => handleSortDirection("status")}
            >
              status
            </Button>
          </ButtonGroup>
          <div>
            aktuell: {sort} - {direction}
          </div>
        </div>
      )}
      {loading === false && (
        <Accordion className="mt-2" activeKey={activeKey}>
          {Object.values(users)[1].map((user, id) => {
            return (
              <Card key={id}>
                <Card.Header className="d-flex justify-content-between">
                  <Accordion.Toggle
                    className="border-bottom"
                    as={Button}
                    eventKey={user.userid}
                    onClick={() => handleKeyChange(user.userid)}
                  >
                    {user.username}
                  </Accordion.Toggle>
                  <div>{user.userid}</div> - <div>{user.status}</div>
                </Card.Header>
                <Accordion.Collapse eventKey={user.userid}>
                  <Card.Body>
                    <Alert variant="secondary">
                      <Row>
                        <Col>User-ID:</Col>
                        <Col>{user.userid}</Col>
                        <Col>User-Name:</Col>
                        <Col>{user.username}</Col>
                      </Row>
                      <hr />
                      <Row>
                        <Col>Status:</Col>
                        <Col>{user.status}</Col>
                        <Col>lastaccess:</Col>
                        <Col>
                          {moment
                            .unix(user.lastaccess)
                            .format("DD.MM.YYYY - H:m:s")}
                        </Col>
                      </Row>
                      <hr />
                      <Row>
                        <Col>Action-ID:</Col>
                        <Col>{user.actionid}</Col>
                        <Col>Target-ID:</Col>
                        <Col>{user.targetid}</Col>
                      </Row>
                      <hr />
                      <Row>
                        <Col>Target-Time:</Col>
                        <Col>{user.targettime}</Col>
                        <Col>last-Fight:</Col>
                        <Col>{user.lastfightid}</Col>
                      </Row>
                    </Alert>
                    <Form>
                      <Form.Group controlId="status">
                        <Form.Label>Status ändern</Form.Label>
                        <Form.Control
                          onChange={(e) =>
                            handleUserChange(e, user.userid, "status")
                          }
                          value={user.status}
                          as="select"
                          custom
                        >
                          <option>newuser</option>
                          <option>unactivated</option>
                          <option>active</option>
                          <option>temp. baned</option>
                          <option>perm. baned</option>
                        </Form.Control>
                      </Form.Group>
                    </Form>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            );
          })}
        </Accordion>
      )}
      {loading === false && users.number >= 1 && (
        <Pagination
          totalItemsCount={users.number}
          activePage={activePage}
          hideDisabled
          itemsCountPerPage={5}
          onChange={handlePageChange}
          itemClass="page-item"
          linkClass="page-link"
        />
      )}
    </div>
  );
};

export default AdminUser;
