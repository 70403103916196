import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Card,
  Col,
  Container,
  Media,
  Row,
  Tab,
  Tabs,
} from "react-bootstrap";
import { GET, POST } from "../../tools/fetch";
import { useSelector } from "react-redux";

const Shop = () => {
  const [items, setItems] = useState();
  const [item, setItem] = useState();
  const [loading, setLoading] = useState();
  const [confirm, setConfirm] = useState();
  const [itemType, setItemType] = useState("rings");

  const data = useSelector((state) => state.skills.skills);

  const loadItems = async () => {
    const request = await GET("/shop/getitems");
    if (request) {
      setItems(request);
      setLoading(false);
    }
  };

  const buyItem = async (type) => {
    const request = await POST("/shop/buyitem", {
      itemtype: type,
      id: item.itemid,
    });
    if (request) {
      setItem();
      loadItems();
      setConfirm(request.error);
    }
  };

  useEffect(() => {
    loadItems();
  }, []);

  return (
    <div>
      {loading === false &&
      data.char.actionid === 0 &&
      data.char.targetid === 0 &&
      data.char.targettime === 0 ? (
        <Container className="border rounded mt-2">
          <div>
            <div className="text-center">Rylar's Angebote</div>
            <Tabs
              defaultActiveKey="rings"
              id="uncontrolled-tab-example"
              className="mb-3"
            >
              <Tab
                onEntering={() => setItemType("rings")}
                onExit={() => setItem()}
                eventKey="rings"
                title="Ringe"
              >
                <Row>
                  <Col md="4">
                    {loading === false &&
                      items.rings.map((ring, id) => {
                        let stats1 = ring.stat1.split(",");
                        let stats2 = ring.stat2.split(",");
                        let stats3 = ring.stat3.split(",");
                        let stats4 = ring.stat4.split(",");
                        let stats5 = ring.stat5.split(",");
                        return (
                          <ul
                            key={id}
                            className="list-unstyled mb-1"
                            style={{
                              borderBottom: "1px solid lightgray",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setItem(ring);
                              setConfirm();
                            }}
                          >
                            <li className="media">
                              <img
                                className="align-self-center mr-3"
                                src={require(`../../images/items/rings/${ring.img}.jpg`)}
                                alt=""
                                width={"50px"}
                              />
                              <Media.Body>
                                <div className="mt-0 mb-0 d-flex justify-content-between">
                                  <span>
                                    <span>{ring.name}</span>
                                  </span>
                                </div>
                                <div>
                                  {stats1[0] === "inc" && "+"}
                                  {stats1[2]} {stats1[1]}
                                  {stats2[0] &&
                                    stats2[0] === "inc" &&
                                    ", +" + stats2[2] + " " + stats2[1]}
                                  {stats3[0] &&
                                    stats3[0] === "inc" &&
                                    ", +" + stats3[2] + " " + stats3[1]}
                                  {stats4[0] &&
                                    stats4[0] === "inc" &&
                                    ", +" + stats4[2] + " " + stats4[1]}
                                  {stats5[0] &&
                                    stats5[0] === "inc" &&
                                    ", +" + stats5[2] + " " + stats5[1]}
                                </div>
                              </Media.Body>
                            </li>
                          </ul>
                        );
                      })}
                  </Col>
                  <Col style={{ borderLeft: "1px solid lightgray" }}>
                    {confirm !== undefined && (
                      <Alert variant={confirm === 1 ? "danger" : "success"}>
                        {confirm === 1
                          ? "Angebot ist nicht mehr gültig!"
                          : "Glückwunsch, Item gekauft!"}
                      </Alert>
                    )}
                    {item && itemType === "rings" ? (
                      <Card>
                        <Card.Img
                          variant="top"
                          style={{ width: "100px" }}
                          className="mx-auto d-block mt-2"
                          src={require(`../../images/items/rings/${item.img}.jpg`)}
                        />
                        <Card.Body className="text-center">
                          <Card.Title>{item.name}</Card.Title>

                          <Card.Text>
                            {item.mindmg !== 0 &&
                              "Damage: " + item.mindmg + " - " + item.maxdmg}

                            {item.stat1 !== "" ? (
                              <div>{item.stat1.split(",").join(" ")}</div>
                            ) : null}

                            {item.stat2 !== "" ? (
                              <div>{item.stat2.split(",").join(" ")}</div>
                            ) : null}

                            {item.stat3 !== "" ? (
                              <div>{item.stat3.split(",").join(" ")}</div>
                            ) : null}

                            {item.stat4 !== "" ? (
                              <div>{item.stat4.split(",").join(" ")}</div>
                            ) : null}

                            {item.stat5 !== "" ? (
                              <div>{item.stat5.split(",").join(" ")}</div>
                            ) : null}

                            {<div>Qualität: {item.qlvl}</div>}
                            {item.reql !== 0
                              ? `req. level: ${item.reql}`
                              : null}
                            {item.reql !== 0 ? <br /> : null}

                            {item.reqs !== 0
                              ? `req. skill: ${item.reqs}`
                              : null}

                            {
                              <div>
                                Preis: <u>{item.price} Cr.</u>
                              </div>
                            }
                          </Card.Text>
                        </Card.Body>
                        <Card.Footer className="text-right">
                          <small>
                            <Button
                              size="sm"
                              variant="link"
                              onClick={() =>
                                buyItem(item.mindmg !== 0 ? "weapons" : "rings")
                              }
                            >
                              kaufen
                            </Button>
                          </small>
                        </Card.Footer>
                      </Card>
                    ) : items && items.rings.length === 0 ? (
                      "kein Angebot vorhanden. Du solltest wann anders nochmal kommen."
                    ) : (
                      "Wähle einen Ring"
                    )}
                  </Col>
                </Row>
              </Tab>
              <Tab
                onEntering={() => setItemType("weapons")}
                onExit={() => setItem()}
                eventKey="weapons"
                title="Waffen"
              >
                <Row>
                  <Col md="4">
                    {loading === false &&
                      items.weapons.map((ring, id) => {
                        let stats1 = ring.stat1.split(",");
                        let stats2 = ring.stat2.split(",");
                        let stats3 = ring.stat3.split(",");
                        let stats4 = ring.stat4.split(",");
                        let stats5 = ring.stat5.split(",");
                        return (
                          <ul
                            key={id}
                            className="list-unstyled mb-1"
                            style={{
                              borderBottom: "1px solid lightgray",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setItem(ring);
                              setConfirm();
                            }}
                          >
                            <li className="media">
                              <img
                                className="align-self-center mr-3"
                                src={require(`../../images/items/weapons/${ring.img}.jpg`)}
                                alt=""
                                width={"50px"}
                              />
                              <Media.Body>
                                <div className="mt-0 mb-0 d-flex justify-content-between">
                                  <span>
                                    <span>{ring.name}</span>
                                  </span>
                                </div>
                                <div>
                                  Dmg: {ring.mindmg} - {ring.maxdmg}
                                  {stats1[0] === "inc" && ", +"}
                                  {stats1[2]} {stats1[1]}
                                  {stats2[0] &&
                                    stats2[0] === "inc" &&
                                    ", +" + stats2[2] + " " + stats2[1]}
                                  {stats3[0] &&
                                    stats3[0] === "inc" &&
                                    ", +" + stats3[2] + " " + stats3[1]}
                                  {stats4[0] &&
                                    stats4[0] === "inc" &&
                                    ", +" + stats4[2] + " " + stats4[1]}
                                  {stats5[0] &&
                                    stats5[0] === "inc" &&
                                    ", +" + stats5[2] + " " + stats5[1]}
                                </div>
                              </Media.Body>
                            </li>
                          </ul>
                        );
                      })}
                  </Col>
                  <Col style={{ borderLeft: "1px solid lightgray" }}>
                    {confirm !== undefined && (
                      <Alert variant={confirm === 1 ? "danger" : "success"}>
                        {confirm === 1
                          ? "Angebot ist nicht mehr gültig!"
                          : "Glückwunsch, Item gekauft!"}
                      </Alert>
                    )}
                    {item && itemType === "weapons" ? (
                      <Card>
                        <Card.Img
                          variant="top"
                          style={{ width: "100px" }}
                          className="mx-auto d-block mt-2"
                          src={require(`../../images/items/weapons/${item.img}.jpg`)}
                        />
                        <Card.Body className="text-center">
                          <Card.Title>{item.name}</Card.Title>

                          <Card.Text>
                            {item.mindmg !== 0 &&
                              "Damage: " + item.mindmg + " - " + item.maxdmg}

                            {item.stat1 !== "" ? (
                              <div>{item.stat1.split(",").join(" ")}</div>
                            ) : null}

                            {item.stat2 !== "" ? (
                              <div>{item.stat2.split(",").join(" ")}</div>
                            ) : null}

                            {item.stat3 !== "" ? (
                              <div>{item.stat3.split(",").join(" ")}</div>
                            ) : null}

                            {item.stat4 !== "" ? (
                              <div>{item.stat4.split(",").join(" ")}</div>
                            ) : null}

                            {item.stat5 !== "" ? (
                              <div>{item.stat5.split(",").join(" ")}</div>
                            ) : null}

                            {<div>Qualität: {item.qlvl}</div>}
                            {item.reql !== 0
                              ? `req. level: ${item.reql}`
                              : null}
                            {item.reql !== 0 ? <br /> : null}

                            {item.reqs !== 0
                              ? `req. skill: ${item.reqs}`
                              : null}

                            {
                              <div>
                                Preis: <u>{item.price} Cr.</u>
                              </div>
                            }
                          </Card.Text>
                        </Card.Body>
                        <Card.Footer className="text-right">
                          <small>
                            <Button
                              size="sm"
                              variant="link"
                              onClick={() =>
                                buyItem(item.mindmg !== 0 ? "weapons" : "rings")
                              }
                            >
                              kaufen
                            </Button>
                          </small>
                        </Card.Footer>
                      </Card>
                    ) : items && items.weapons.length === 0 ? (
                      "Kein Angebot vorhanden. Du solltest wann anders nochmal kommen."
                    ) : (
                      "Wähle eine Waffe"
                    )}
                  </Col>
                </Row>
              </Tab>
              <Tab
                eventKey="sonst"
                title="Verschiedenes"
                onEntering={() => setItemType("misc")}
                onExit={() => setItem()}
              >
                <Row>
                  <Col md="4">
                    {loading === false &&
                      items.misc.map((ring, id) => {
                        let stats1 = ring.stat1.split(",");
                        let stats2 = ring.stat2.split(",");
                        let stats3 = ring.stat3.split(",");
                        let stats4 = ring.stat4.split(",");
                        let stats5 = ring.stat5.split(",");
                        return (
                          <ul
                            key={id}
                            className="list-unstyled mb-1"
                            style={{
                              borderBottom: "1px solid lightgray",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setItem(ring);
                              setConfirm();
                            }}
                          >
                            <li className="media">
                              <img
                                className="align-self-center mr-3"
                                src={require(`../../images/items/misc/${ring.img}.png`)}
                                alt=""
                                width={"50px"}
                              />
                              <Media.Body>
                                <div className="mt-0 mb-0 d-flex justify-content-between">
                                  <span>
                                    <span>{ring.name}</span>
                                  </span>
                                </div>
                                <div>
                                  {stats1[0] === "inc" && ", +"}
                                  {stats1[2]} {stats1[1]}
                                  {stats2[0] &&
                                    stats2[0] === "inc" &&
                                    ", +" + stats2[2] + " " + stats2[1]}
                                  {stats3[0] &&
                                    stats3[0] === "inc" &&
                                    ", +" + stats3[2] + " " + stats3[1]}
                                  {stats4[0] &&
                                    stats4[0] === "inc" &&
                                    ", +" + stats4[2] + " " + stats4[1]}
                                  {stats5[0] &&
                                    stats5[0] === "inc" &&
                                    ", +" + stats5[2] + " " + stats5[1]}
                                </div>
                              </Media.Body>
                            </li>
                          </ul>
                        );
                      })}
                  </Col>
                  <Col style={{ borderLeft: "1px solid lightgray" }}>
                    {confirm !== undefined && (
                      <Alert variant={confirm === 1 ? "danger" : "success"}>
                        {confirm === 1
                          ? "Angebot ist nicht mehr gültig!"
                          : "Glückwunsch, Item gekauft!"}
                      </Alert>
                    )}
                    {item && itemType === "misc" ? (
                      <Card>
                        <Card.Img
                          variant="top"
                          style={{ width: "100px" }}
                          className="mx-auto d-block mt-2"
                          src={require(`../../images/items/misc/${item.img}.png`)}
                        />
                        <Card.Body className="text-center">
                          <Card.Title>{item.name}</Card.Title>

                          <Card.Text>
                            {item.mindmg !== 0 &&
                              "Damage: " + item.mindmg + " - " + item.maxdmg}

                            {item.stat1 !== "" ? (
                              <div>{item.stat1.split(",").join(" ")}</div>
                            ) : null}

                            {item.stat2 !== "" ? (
                              <div>{item.stat2.split(",").join(" ")}</div>
                            ) : null}

                            {item.stat3 !== "" ? (
                              <div>{item.stat3.split(",").join(" ")}</div>
                            ) : null}

                            {item.stat4 !== "" ? (
                              <div>{item.stat4.split(",").join(" ")}</div>
                            ) : null}

                            {item.stat5 !== "" ? (
                              <div>{item.stat5.split(",").join(" ")}</div>
                            ) : null}

                            {<div>Qualität: {item.qlvl}</div>}
                            {item.reql !== 0
                              ? `req. level: ${item.reql}`
                              : null}
                            {item.reql !== 0 ? <br /> : null}

                            {item.reqs !== 0
                              ? `req. skill: ${item.reqs}`
                              : null}

                            {
                              <div>
                                Preis: <u>{item.price} Cr.</u>
                              </div>
                            }
                          </Card.Text>
                        </Card.Body>
                        <Card.Footer className="text-right">
                          <small>
                            <Button
                              size="sm"
                              variant="link"
                              onClick={() =>
                                buyItem(
                                  item.mindmg !== 0
                                    ? "weapons"
                                    : item.droptime !== 0 || item.droptime === 0
                                    ? "misc"
                                    : "rings"
                                )
                              }
                            >
                              kaufen
                            </Button>
                          </small>
                        </Card.Footer>
                      </Card>
                    ) : items && items.misc.length === 0 ? (
                      "Kein Angebot vorhanden. Du solltest wann anders nochmal kommen."
                    ) : (
                      "Wähle ein Item"
                    )}
                  </Col>
                </Row>
              </Tab>
              <Tab
                onEntering={() => setItemType("bots")}
                onExit={() => setItem()}
                eventKey="drone"
                title="Drohnen"
              >
                <Row>
                  <Col md="4">
                    {loading === false &&
                      items.bots.map((ring, id) => {
                        let stats1 = ring.stat1.split(",");
                        let stats2 = ring.stat2.split(",");
                        let stats3 = ring.stat3.split(",");
                        let stats4 = ring.stat4.split(",");
                        let stats5 = ring.stat5.split(",");
                        return (
                          <ul
                            key={id}
                            className="list-unstyled mb-1"
                            style={{
                              borderBottom: "1px solid lightgray",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setItem(ring);
                              setConfirm();
                            }}
                          >
                            <li className="media">
                              <img
                                className="align-self-center mr-3"
                                src={require(`../../images/items/bots/${ring.img}.png`)}
                                alt=""
                                width={"50px"}
                              />
                              <Media.Body>
                                <div className="mt-0 mb-0 d-flex justify-content-between">
                                  <span>
                                    <span>{ring.name}</span>
                                  </span>
                                </div>
                                <div>
                                  {stats1[0] === "inc" && ", +"}
                                  {stats1[2]} {stats1[1]}
                                  {stats2[0] &&
                                    stats2[0] === "inc" &&
                                    ", +" + stats2[2] + " " + stats2[1]}
                                  {stats3[0] &&
                                    stats3[0] === "inc" &&
                                    ", +" + stats3[2] + " " + stats3[1]}
                                  {stats4[0] &&
                                    stats4[0] === "inc" &&
                                    ", +" + stats4[2] + " " + stats4[1]}
                                  {stats5[0] &&
                                    stats5[0] === "inc" &&
                                    ", +" + stats5[2] + " " + stats5[1]}
                                </div>
                              </Media.Body>
                            </li>
                          </ul>
                        );
                      })}
                  </Col>
                  <Col style={{ borderLeft: "1px solid lightgray" }}>
                    {confirm !== undefined && (
                      <Alert variant={confirm === 1 ? "danger" : "success"}>
                        {confirm === 1
                          ? "Angebot ist nicht mehr gültig!"
                          : "Glückwunsch, Item gekauft!"}
                      </Alert>
                    )}
                    {item && itemType === "bots" ? (
                      <Card>
                        <Card.Img
                          variant="top"
                          style={{ width: "100px" }}
                          className="mx-auto d-block mt-2"
                          src={require(`../../images/items/bots/${item.img}.png`)}
                        />
                        <Card.Body className="text-center">
                          <Card.Title>{item.name}</Card.Title>

                          <Card.Text>
                            {item.mindmg !== 0 &&
                              "Damage: " + item.mindmg + " - " + item.maxdmg}

                            {item.stat1 !== "" ? (
                              <div>{item.stat1.split(",").join(" ")}</div>
                            ) : null}

                            {item.stat2 !== "" ? (
                              <div>{item.stat2.split(",").join(" ")}</div>
                            ) : null}

                            {item.stat3 !== "" ? (
                              <div>{item.stat3.split(",").join(" ")}</div>
                            ) : null}

                            {item.stat4 !== "" ? (
                              <div>{item.stat4.split(",").join(" ")}</div>
                            ) : null}

                            {item.stat5 !== "" ? (
                              <div>{item.stat5.split(",").join(" ")}</div>
                            ) : null}

                            {<div>Qualität: {item.qlvl}</div>}
                            {item.reql !== 0
                              ? `req. level: ${item.reql}`
                              : null}
                            {item.reql !== 0 ? <br /> : null}

                            {item.reqs !== 0
                              ? `req. skill: ${item.reqs}`
                              : null}

                            {
                              <div>
                                Preis: <u>{item.price} Cr.</u>
                              </div>
                            }
                          </Card.Text>
                        </Card.Body>
                        <Card.Footer className="text-right">
                          <small>
                            <Button
                              size="sm"
                              variant="link"
                              onClick={() =>
                                buyItem(
                                  item.mindmg !== 0
                                    ? "weapons"
                                    : item.name === "Trainingsdrohne"
                                    ? "bots"
                                    : item.droptime !== 0 || item.droptime === 0
                                    ? "misc"
                                    : "rings"
                                )
                              }
                            >
                              kaufen
                            </Button>
                          </small>
                        </Card.Footer>
                      </Card>
                    ) : items && items.bots.length === 0 ? (
                      "Kein Angebot vorhanden. Du solltest wann anders nochmal kommen."
                    ) : (
                      "Wähle ein Item"
                    )}
                  </Col>
                </Row>
              </Tab>
            </Tabs>
          </div>
        </Container>
      ) : (
        <div>Du bist gerade beschäftigt</div>
      )}
    </div>
  );
};

export default Shop;
