import React, { useState, useEffect } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { GET } from "../../tools/fetch";

const AskedItems = () => {
  const [items, setItems] = useState();
  const [loading, setLoading] = useState();

  const loadItems = async () => {
    try {
      setLoading(true);
      const request = await GET("/auction/askeditems");
      setItems(request.items);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadItems();
  }, []);
  return (
    <Col>
      <Card style={{ opacity: "0.7" }}>
        <Card.Body>
          <Card.Title>gefragte Items</Card.Title>
          {loading === false &&
            items.map((item) => {
              return (
                <Row key={item.auctionid}>
                  <Col className="pr-0">{item.name}</Col>
                  <Col className="pl-0" xs="auto">
                    {item.item_count} x
                  </Col>
                </Row>
              );
            })}
        </Card.Body>
      </Card>
    </Col>
  );
};

export default AskedItems;
