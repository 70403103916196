import moment from "moment";
import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { GET } from "../../tools/fetch";
import "moment/locale/de";
const History = () => {
  const [loading, setLoading] = useState();
  const [history, setHistory] = useState();

  const loadHistory = async () => {
    try {
      setLoading(true);
      const request = await GET("/auction/history");
      setHistory(request);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadHistory();
  }, []);

  return (
    <Row className="mr-1 ml-1">
      <Col md="6">
        <Card style={{ backgroundColor: "rgba(255,255,255,0.7)" }}>
          <Card.Body>
            <div className="text-center font-weight-bold">Verkauft</div>
            <hr />
            {loading === false &&
              history.sold.map((element) => {
                let a = moment();
                let b = moment(element.time);
                let time = b.from(a);
                moment.locale("de");
                return (
                  <div className="mb-1">
                    <Row>
                      <Col>{element.item}</Col>
                    </Row>
                    <Row className="no-gutters justify-content-between">
                      <Col xs="auto">{element.buyer}</Col>
                      <Col xs="auto">{element.price} Cr.</Col>
                      <Col xs="auto">{time}</Col>
                    </Row>
                    <hr />
                  </div>
                );
              })}
          </Card.Body>
        </Card>
      </Col>
      <Col md="6">
        <Card style={{ backgroundColor: "rgba(255,255,255,0.7)" }}>
          <Card.Body>
            <div className="text-center font-weight-bold">Gekauft</div>
            <hr />
            {loading === false &&
              history.bought.map((element) => {
                let a = moment();
                let b = moment(element.time);
                let time = b.from(a);
                moment.locale("de");
                return (
                  <div className="mb-1">
                    <Row>
                      <Col>{element.item}</Col>
                    </Row>
                    <Row className="no-gutters justify-content-between">
                      <Col xs="auto">{element.seller}</Col>
                      <Col xs="auto">{element.price} Cr.</Col>
                      <Col xs="auto">{time}</Col>
                    </Row>
                    <hr />
                  </div>
                );
              })}
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default History;
