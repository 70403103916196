import { SET_OFFLINE } from "../constants/actionTypes";

const initialState = {
  offline: false,
};

const game = (state = initialState, action) => {
  switch (action.type) {
    case SET_OFFLINE:
      return { ...state, offline: action.payload };
    default:
      return { ...state };
  }
};

export default game;
