import React, { useState } from "react";
import { Alert, Button, Col, Row } from "react-bootstrap";
import { POST } from "../../tools/fetch";

const BugsReport = () => {
  const [titel, setTitel] = useState("");
  const [desc, setDesc] = useState("");
  const [success, setSuccess] = useState("");

  const handleChangeTitel = (e) => {
    setTitel(e.target.value);
  };

  const handleChangeDesc = (e) => {
    setDesc(e.target.value);
  };

  const handleSendBug = async () => {
    try {
      const request = await POST("/bugs/report", { titel: titel, desc: desc });
      if (request) {
        setSuccess(
          `Vielen Dank für deine Hilfe. Du kannst diesen Bug mit der ID ${request.id.id} weiter verfolgen.`
        );
      }
    } catch (e) {
      console.error(e);
    } finally {
      setTitel("");
      setDesc("");
    }
  };

  const handleReset = () => {
    setTitel("");
    setDesc("");
  };

  return (
    <div>
      <div className="text-center">
        Bitte beschreibe deine Beobachtung so genau wie möglich
      </div>
      <Row>
        <Col md={{ span: 6, offset: 3 }}>
          <div className="text-center">
            Titel
            <br />
            <input
              maxLength="100"
              value={titel}
              onChange={handleChangeTitel}
              className="w-100"
              type="text"
            />
          </div>
          <div className="mt-3 text-center">
            Beschreibung
            <br />
            <textarea
              style={{height:"30vh"}}
              maxLength="500"
              value={desc}
              onChange={handleChangeDesc}
              className="w-100"
            />
          </div>
          <div className="mt-2 d-flex justify-content-between text-center">
            <Button onClick={handleReset} variant="outline-danger" size="sm">
              reset
            </Button>{" "}
            <Button
              disabled={titel === "" || desc === ""}
              onClick={handleSendBug}
              variant="outline-success"
              size="sm"
            >
              absenden
            </Button>
          </div>
        </Col>
      </Row>
      {success !== "" && (
        <Alert className="text-center" variant="success">
          {success}
        </Alert>
      )}
    </div>
  );
};

export default BugsReport;
