import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  Col,
  ListGroup,
  Nav,
  Row,
  Spinner,
  Tab,
} from "react-bootstrap";
import { GET } from "../../tools/fetch";
import config from "../../config";

const DroneTraining = () => {
  const [status, setStatus] = useState("waiting");
  const [start, setStart] = useState(0);
  const [click, setClick] = useState(0);
  const [reaction, setReaction] = useState([]);
  const [box, setBox] = useState("");
  const [styles, setStyles] = useState({
    borderRadius: "0",
    top: "0px",
    left: "0px",
    backgroundColor: "",
    display: "none",
    position: "absolute",
    width: "25px",
    height: "25px",
  });
  const boxRef = useRef(null);
  const [boxCount, setBoxCount] = useState(0);
  const [durchR, setDurchR] = useState(0);

  const startGame = () => {
    setStatus("running");
    setBoxCount(0);
    setDurchR(0);
    setReaction([]);
    // Verzögerung für den zufälligen Timer nach dem Klick auf "Start"
    const randomDelay = Math.random() * 3000;
    setTimeout(() => {
      setBox(makeBox());
    }, randomDelay);
  };
  const makeBox = () => {
    let bR = "0";
    let backgroundColor = "";
    let display = "";
    let time = Math.random();
    time = time * 3000;

    const newDiv = document.createElement("div"); // Erstellen Sie ein neues DOM-Element
    newDiv.style.position = "absolute"; // Position auf absolut setzen
    newDiv.style.width = "25px"; // Breite und Höhe anpassen
    newDiv.style.height = "25px";

    if (Math.random() > 0.5) {
      bR = "25px";
    } else {
      bR = "0";
    }

    // Anpassen der Positionsberechnungen an die Größe der Card.Body
    let topS = Math.random() * (boxRef.current.clientHeight - 25);
    let leftS = Math.random() * (boxRef.current.clientWidth - 25);

    topS = topS + "px";
    leftS = leftS + "px";

    backgroundColor = getRandomColor();

    display = "block";

    setStart(Date.now());

    // Stil für das DOM-Element aktualisieren
    newDiv.style.borderRadius = bR;
    newDiv.style.top = topS;
    newDiv.style.left = leftS;
    newDiv.style.backgroundColor = backgroundColor;
    newDiv.style.display = display;

    setStyles({
      ...styles,
      borderRadius: bR,
      top: topS,
      left: leftS,
      backgroundColor: backgroundColor,
      display: display,
    });
    // Das aktualisierte DOM-Element zurückgeben
    return newDiv;
  };
  const handleBoxClick = () => {
    const end = Date.now();
    setClick(end);
    const reaction_temp = end - start;
    setReaction([...reaction, reaction_temp]);
    setBox(""); // Die geklickte Box ausblenden
    setBoxCount(boxCount + 1);
    if (boxCount < 9) {
      setTimeout(() => {
        setBox(makeBox()); // Nach einer zufälligen Verzögerung eine neue Box erstellen
      }, Math.random() * 3000);
    }
  };
  const getRandomColor = () => {
    var letters = "0123456789ABCDEF".split("");
    var color = "#";
    for (var i = 0; i < 6; i++) {
      color += letters[Math.round(Math.random() * 15)];
    } //ends for loop
    return color;
  };

  const sendTrain = async (input) => {
    const payload = config(input);
    try {
      const request = await GET(`/train/drone/${payload}`);
    } catch (error) {}
  };

  useEffect(() => {
    let reaction_sum = 0;
    let durchschnitt = 0;
    reaction.forEach((element) => {
      reaction_sum += element;
      durchschnitt = Math.round(reaction_sum / boxCount) / 1000;
    });
    setDurchR(durchschnitt);
    if (boxCount === 10) {
      setStatus("finish");
      sendTrain(durchschnitt);
    }
  }, [reaction]);

  return (
    <div>
      <div className="text-center mb-2">
        Du setzt deinen Helm auf und startest die Trainingsdrohne
        <div>
          Voll im Fokus kannst du die Geschosse wahrnehmen und verteidigen
        </div>
      </div>
      <Card style={{ width: "75%", minHeight: "400px", margin: "0 auto" }}>
        <Card.Body
          ref={boxRef}
          className="p-0"
          style={{ position: "relative" }}
        >
          {box !== "" && <div onClick={handleBoxClick} style={styles} />}
        </Card.Body>
        <Card.Footer className="d-flex justify-content-between">
          <div className="small align-self-center">
            {status === "running" && <Spinner animation="border" size="sm" />}{" "}
            {boxCount + "/10"}
          </div>
          <div className="small align-self-center">
            {durchR} Sek. &#216; Reaktion
          </div>
          <Button size="sm" variant="outline-success" onClick={startGame}>
            {status === "waiting" ? "start" : "re-start"}
          </Button>
        </Card.Footer>
      </Card>
      <Tab.Container id="list-group-tabs-example" defaultActiveKey="third">
        <Row className="mt-5 small">
          <Col sm={4}>
            <Nav className="flex-column">
              <Nav.Item className="border rounded">
                <Nav.Link
                  eventKey="first"
                  className="d-flex justify-content-between"
                >
                  <div>Gold</div>
                  <div>{"<= 0.975 Sek."}</div>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="border rounded">
                <Nav.Link
                  eventKey="second"
                  className="d-flex justify-content-between"
                >
                  <div>Silber</div>
                  <div>{"0.976 - 1.25 Sek."}</div>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="border rounded">
                <Nav.Link
                  eventKey="third"
                  className="d-flex justify-content-between"
                >
                  <div>Bronze</div>
                  <div>{"1.26 - 1.50 Sek."}</div>
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
          <Col sm={8}>
            <Tab.Content>
              <Tab.Pane eventKey="first">
                <div>
                  <u>GOLD</u>
                </div>
                Ein außergewöhnlicher Trainingserfolg.
                <br />
                Dieses Training ist keine Herausforderung mehr.
                <br />
                +1 DEX, +1 LSA, +1 LSD
              </Tab.Pane>
              <Tab.Pane eventKey="second">
                <div>
                  <u>SILBER</u>
                </div>
                Ein gutes Ergebnis. Es gibt aber Verbesserungspotential.
                <br />
                +1 DEX, +1 LSD
              </Tab.Pane>
              <Tab.Pane eventKey="third">
                <div>
                  <u>BRONZE</u>
                </div>
                Es geht deutlich besser, aber ein guter Ansatz.
                <br />
                +1 DEX
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </div>
  );
};

export default DroneTraining;
