import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Button,
  Card,
  CardDeck,
  Col,
  Form,
  Modal,
  ProgressBar,
  Row,
} from "react-bootstrap";
import { GET, POST } from "../../tools/fetch";
import Item from "../item";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";

const Craft = () => {
  const [Citem, setCItem] = useState();
  const [costs, setCosts] = useState(0);
  const [maxQLvl, setMaxQlvl] = useState(0);
  const [baseMaxQLvl, setBaseMaxQLvl] = useState(0);
  const [charMaxQLvl, setCharMaxQLvl] = useState(0);
  const [minQlvl, setMinQlvl] = useState(0);
  const [itemQlvl, setItemQlvl] = useState(0);
  const [itemName, setItemName] = useState("");
  const [itemImg, setItemImg] = useState("");
  const [itemEssence, setItemEssence] = useState("");
  const [chance2nd, setChance2nd] = useState(0);
  const [chance3rd, setChance3rd] = useState(0);

  const [essences, setEssences] = useState(0);

  const [craftedItem, setCraftedItem] = useState();

  const skills = useSelector((state) => state.skills.inv.char.skills);
  const char = useSelector((state) => state.skills.inv.char);

  const [abis, setCheckedAbis] = useState(false);
  const [maxAbi, setMaxAbi] = useState(0);
  const [minAbi, setMinAbi] = useState(0);
  const [mights, setCheckedMights] = useState(false);
  const [mightsN, setCheckedMightsN] = useState(false);
  const [mightsL, setCheckedMightsL] = useState(false);
  const [mightsD, setCheckedMightsD] = useState(false);
  const [checked2nd, setChecked2nd] = useState(false);
  const [checked3rd, setChecked3rd] = useState(false);
  const [maxMight, setMaxMight] = useState(0);
  const [minMight, setMinMight] = useState(0);
  const [alt, setAlt] = useState(false);
  const [altModal, setAltModal] = useState(false);

  const [crafting, setCrafting] = useState(false);
  const [timeNow, setTimeNow] = useState(0);
  const [timeMax, setTimeMax] = useState(11);

  const [back, setBack] = useState("");

  const tick = () => {
    if (timeNow < timeMax) {
      setTimeNow(timeNow + 1);
    }
  };
  useEffect(() => {
    if (crafting) {
      const timerId = setInterval(() => tick(), 1000);
      return () => clearInterval(timerId);
    }
  });

  //crafting fertig
  useEffect(() => {
    if (timeNow === 11) {
      setCrafting(false);
      setCheckedAbis(false);
      setCheckedMights(false);
      setCheckedMightsN(false);
      setCheckedMightsL(false);
      setCheckedMightsD(false);
      setChecked2nd(false);
      setChance2nd(0);
      setChecked3rd(false);
      setChance3rd(0);
      setEssences(0);
      setItemEssence("");
    }
  }, [timeNow]);

  const loadItems = async () => {
    try {
      const response = await GET(
        `/character/inventory?id=misc&page=1&sort=itemid&direction=desc&search=${itemEssence}`
      );
      if (response) {
        setEssences(response.items[0].weight);
      }
    } catch (e) {
      console.error(e);
    } finally {
    }
  };

  const handleChangeMights = () => {
    if (abis === false) {
      setCheckedMights(!mights);
    }
  };

  const handleChangeMightsN = () => {
    if (
      abis === false &&
      mights === false &&
      mightsL === false &&
      mightsD === false
    ) {
      setCheckedMightsN(!mightsN);
    }
  };

  const handleChangeMightsL = () => {
    if (
      abis === false &&
      mights === false &&
      mightsN === false &&
      mightsD === false
    ) {
      setCheckedMightsL(!mightsL);
    }
  };

  const handleChangeMightsD = () => {
    if (
      abis === false &&
      mights === false &&
      mightsN === false &&
      mightsL === false
    ) {
      setCheckedMightsD(!mightsD);
    }
  };

  const handleChangeAbis = () => {
    if (mights === false) {
      setCheckedAbis(!abis);
    }
  };

  const handle2ndCheck = () => {
    if (checked2nd) {
      setChecked3rd(false);
    }
    setChecked2nd(!checked2nd);
  };

  const handle3rdCheck = () => {
    if (checked2nd) {
      setChecked3rd(!checked3rd);
    }
  };

  const handleChangeAlt = () => {
    setAlt(!alt);
  };

  const craft = async () => {
    setCrafting(true);
    const item = await POST("/events/loot", {
      item: "craft",
      itemName: Citem,
      qlvl: itemQlvl,
      focus: abis
        ? "abis"
        : mights
        ? "mights"
        : mightsN
        ? "mightsN"
        : mightsL
        ? "mightsL"
        : mightsD
        ? "mightsD"
        : "",
      costs: costs,
      chance2nd: checked2nd,
      chance3rd: checked3rd,
      alt: alt,
    });
    if (item) {
      setCraftedItem(item.loot);
      setChance2nd(0);
      setChance3rd(0);
      setAlt(false);
    }
  };

  const [loading, setLoading] = useState();
  const [progress, setProgress] = useState({});

  const loadProgress = async () => {
    try {
      setLoading(true);
      const request = await GET("/character/craftprogress");
      if (request) {
        setProgress(request);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  //Item gewählt
  useEffect(() => {
    let max = 3 * +skills.level;
    let maxRed = 2.5 * +skills.level;
    let maxRedSecond = 2 * +skills.level;
    let check = skills.spi + skills.itl + skills.dex;
    let addQlvl = 0;
    let addQlvlProgress = 0;
    switch (Citem) {
      case "onionR":
        if (check >= max) {
          addQlvl = 17; // 30 % von 55 (maxLvl)
        } else if (check >= maxRed) {
          addQlvl = 11; // 20 % von 55
        } else if (check >= maxRedSecond) {
          addQlvl = 6; // 10 % von 55
        }
        //je nach Progress noch was dazu bei 100% 6 Pkt
        addQlvlProgress = Math.round((progress.onion / 10) * 0.55);
        setMaxQlvl(55);
        setBaseMaxQLvl(32);
        setCharMaxQLvl(32 + addQlvl + addQlvlProgress);
        setMinQlvl(2);
        setItemName("Onion Ring");
        setItemQlvl(2);
        setItemImg("essenceOnion");
        setItemEssence("onion");
        setBack(
          "linear-gradient(to right top, rgba(219,184,0,1) 0%, rgba(181,152,54,1) 35%, rgba(209,154,22,1) 100%)"
        );
        setChance2nd(8);
        setChance3rd(0);
        break;
      case "rustyR":
        if (check >= max) {
          addQlvl = 16; // 30 % von 53 (maxLvl)
        } else if (check >= maxRed) {
          addQlvl = 11; // 20 % von 53
        } else if (check >= maxRedSecond) {
          addQlvl = 5; // 10 % von 53
        }
        //je nach Progress noch was dazu bei 100% 6 Pkt
        addQlvlProgress = Math.round((progress.rusty / 10) * 0.53);
        setMaxQlvl(53);
        setBaseMaxQLvl(37);
        setCharMaxQLvl(37 + addQlvl + addQlvlProgress);
        setMinQlvl(2);
        setItemName("Rusty Ring");
        setItemQlvl(2);
        setItemImg("essenceRusty");
        setItemEssence("rusty");
        setBack(
          "linear-gradient(to right top, rgba(191,86,0,1) 0%, rgba(181,124,54,1) 35%, rgba(120,120,120,1) 100%)"
        );
        setChance2nd(15);
        setChance3rd(8);
        break;
      case "silverR":
        if (check >= max) {
          addQlvl = 21; // 30 % von 70 (maxLvl)
        } else if (check >= maxRed) {
          addQlvl = 14; // 20 % von 70
        } else if (check >= maxRedSecond) {
          addQlvl = 7; // 10 % von 70
        }
        //je nach Progress noch was dazu bei 100% 6 Pkt
        addQlvlProgress = Math.round((progress.silver / 10) * 0.7);
        setMaxQlvl(70);
        setBaseMaxQLvl(49);
        setCharMaxQLvl(49 + addQlvl + addQlvlProgress);
        setMinQlvl(4);
        setItemName("Silver Ring");
        setItemQlvl(4);
        setItemImg("essenceSilver");
        setItemEssence("silver");
        setBack("linear-gradient(to right top,  #d7e1ec 0%, #ffffff 74%)");
        setChance2nd(27);
        setChance3rd(19);
        break;
      case "goldR":
        if (check >= max) {
          addQlvl = 23; // 30 % von 77 (maxLvl)
        } else if (check >= maxRed) {
          addQlvl = 15; // 20 % von 77
        } else if (check >= maxRedSecond) {
          addQlvl = 8; // 10 % von 77
        }
        //je nach Progress noch was dazu bei 100% 6 Pkt
        addQlvlProgress = Math.round((progress.gold / 10) * 0.77);
        setMaxQlvl(77);
        setBaseMaxQLvl(54);
        setCharMaxQLvl(54 + addQlvl + addQlvlProgress);
        setMinQlvl(9);
        setItemName("Gold Ring");
        setItemQlvl(9);
        setItemImg("essenceGold");
        setItemEssence("gold");
        setBack("linear-gradient(to right top, #ffd700, #ffd9725d)");
        setChance2nd(40);
        setChance3rd(23);
        break;
      default:
        break;
    }
  }, [Citem, progress]);

  //Essences aus der DB holen
  useEffect(() => {
    loadItems();
    loadProgress();
  }, [itemEssence, Citem]);

  //Costs and range
  useEffect(() => {
    const normal = itemQlvl * 2;
    const addA = abis === true ? itemQlvl * 3 : 0;
    const addM = mights === true ? Math.round(itemQlvl * 2) : 0;
    const addMN = mightsN === true ? Math.round(itemQlvl * 2.5) : 0;
    const addML = mightsL === true ? Math.round(itemQlvl * 2.5) : 0;
    const addMD = mightsD === true ? Math.round(itemQlvl * 2.5) : 0;
    const add2nd = checked2nd === true ? Math.round(itemQlvl * 3) : 0;
    const add3rd = checked3rd === true ? Math.round(itemQlvl * 3) : 0;
    let discount = 0;
    switch (Citem) {
      case "goldR":
        discount = Math.round(
          (Math.round(progress.gold / 10) *
            (normal + addA + addM + addMN + addML + addMD + add2nd + add3rd)) /
            100
        );
        break;
      case "silverR":
        discount = Math.round(
          (Math.round(progress.silver / 10) *
            (normal + addA + addM + addMN + addML + addMD + add2nd + add3rd)) /
            100
        );
        break;
      case "rustyR":
        discount = Math.round(
          (Math.round(progress.rusty / 10) *
            (normal + addA + addM + addMN + addML + addMD + add2nd + add3rd)) /
            100
        );
        break;
      case "onionR":
        discount = Math.round(
          (Math.round(progress.onion / 10) *
            (normal + addA + addM + addMN + addML + addMD + add2nd + add3rd)) /
            100
        );
        break;
      default:
        break;
    }
    const allCosts =
      normal + addA + addM + addMN + addML + addMD + add2nd + add3rd - discount;
    setCosts(allCosts);

    //Abi-Range
    const Amin =
      Math.floor(0.023 * 2 * itemQlvl) >= 1
        ? Math.floor(0.023 * 2 * itemQlvl)
        : 1;
    const Amax =
      Math.floor(0.115 * 2 * itemQlvl) >= 1
        ? Math.floor(0.115 * 2 * itemQlvl)
        : 1;
    setMinAbi(Amin);
    setMaxAbi(Amax);

    //Might-Range
    const Mmin =
      Math.floor(0.027 * 2 * itemQlvl) >= 1
        ? Math.floor(0.027 * 2 * itemQlvl)
        : 1;
    const Mmax =
      Math.floor(0.129 * 2 * itemQlvl) >= 1
        ? Math.floor(0.129 * 2 * itemQlvl)
        : 1;
    setMinMight(Mmin);
    setMaxMight(Mmax);
  }, [
    itemQlvl,
    abis,
    mights,
    mightsN,
    mightsL,
    mightsD,
    checked2nd,
    checked3rd,
  ]);

  return (
    <div className="mt-2">
      {!Citem && (
        <CardDeck>
          <Card>
            <Card.Header className="text-center p-3 h3 bg-light">
              Waffe
            </Card.Header>
            <Card.Body className="text-center">
              <div style={{ display: "inline-grid" }}>
                <img src={require(`../../images/items/weapons/wood2.gif`)} />
                Mahogany
              </div>
              <div style={{ display: "inline-grid" }}>
                <img src={require(`../../images/items/weapons/wood1.gif`)} />
                Wood
              </div>
              <div style={{ display: "inline-grid" }}>
                <img src={require(`../../images/items/weapons/rusty1.gif`)} />
                Rusty
              </div>
              <div style={{ display: "inline-grid" }}>
                <img src={require(`../../images/items/weapons/fight1.gif`)} />
                Advanced Fight
              </div>
              <div style={{ display: "inline-grid" }}>
                <img
                  src={require(`../../images/items/weapons/stainless1.gif`)}
                />
                Stainless Steel
              </div>
            </Card.Body>
          </Card>
          <Card>
            <Card.Header className="p-3 h3 text-center bg-light">
              Ring
            </Card.Header>
            <Card.Body className="text-center">
              <div
                style={{ display: "inline-grid", cursor: "pointer" }}
                onClick={() => setCItem("goldR")}
              >
                <img src={require(`../../images/items/rings/gold1.jpg`)} />
                Gold
              </div>
              <div
                style={{ display: "inline-grid", cursor: "pointer" }}
                onClick={() => setCItem("silverR")}
              >
                <img src={require(`../../images/items/rings/silver1.jpg`)} />
                Silver
              </div>
              <div
                style={{ display: "inline-grid", cursor: "pointer" }}
                onClick={() => setCItem("rustyR")}
              >
                <img src={require(`../../images/items/rings/rusty1.jpg`)} />
                Rusty
              </div>
              <div
                style={{ display: "inline-grid", cursor: "pointer" }}
                onClick={() => setCItem("onionR")}
              >
                <img src={require(`../../images/items/rings/onion1.jpg`)} />
                Onion
              </div>
            </Card.Body>
          </Card>
        </CardDeck>
      )}
      {Citem && (
        <Row className="justify-content-md-center">
          <Col md="6">
            {timeNow < 11 && (
              <div>
                <Card style={{ background: `${back}` }}>
                  <Card.Header className="text-center p-3 h3">
                    {itemName}
                  </Card.Header>
                  <Card.Body>
                    <Form>
                      <Form.Group controlId="formBasicRange">
                        <Form.Label>Quality {itemQlvl}</Form.Label>
                        <Form.Control
                          min={minQlvl}
                          max={charMaxQLvl}
                          value={itemQlvl}
                          onChange={(e) => setItemQlvl(e.target.value)}
                          type="range"
                          disabled={crafting}
                        />
                      </Form.Group>
                      <Row>
                        <Col xs={9}>
                          <Form.Check
                            checked={abis}
                            onChange={handleChangeAbis}
                            custom
                            type="checkbox"
                            id={`custom`}
                            label={`Focus on Abilities`}
                            disabled={crafting}
                          />
                        </Col>
                        <Col className="text-right">
                          {minAbi + " - " + maxAbi}
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={9}>
                          <Form.Check
                            checked={mights}
                            onChange={handleChangeMights}
                            custom
                            type="checkbox"
                            id="m"
                            label={`Focus on Mights`}
                            disabled={crafting}
                          />
                        </Col>
                        <Col className="text-right">
                          {minMight + " - " + maxMight}
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={9}>
                          <Form.Check
                            checked={mightsN}
                            onChange={handleChangeMightsN}
                            custom
                            type="checkbox"
                            id="mn"
                            label={`Focus on neutral Mights`}
                            disabled={crafting}
                          />
                        </Col>
                        <Col className="text-right">
                          {minMight + " - " + maxMight}
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={9}>
                          <Form.Check
                            checked={mightsL}
                            onChange={handleChangeMightsL}
                            custom
                            type="checkbox"
                            id="ml"
                            label={`Focus on light Mights`}
                            disabled={crafting}
                          />
                        </Col>
                        <Col className="text-right">
                          {minMight + " - " + maxMight}
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={9}>
                          <Form.Check
                            checked={mightsD}
                            onChange={handleChangeMightsD}
                            custom
                            type="checkbox"
                            id="md"
                            label={`Focus on dark Mights`}
                            disabled={crafting}
                          />
                        </Col>
                        <Col className="text-right">
                          {minMight + " - " + maxMight}
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={9}>
                          <Form.Check
                            checked={checked2nd}
                            onChange={handle2ndCheck}
                            custom
                            type="checkbox"
                            id="2nd"
                            label={`Chance for a 2nd stat`}
                            disabled={crafting}
                          />
                        </Col>
                        <Col className="text-right">{chance2nd + " %"}</Col>
                      </Row>
                      {chance3rd > 0 && (
                        <Row>
                          <Col xs={9}>
                            <Form.Check
                              checked={checked3rd}
                              onChange={handle3rdCheck}
                              custom
                              type="checkbox"
                              id="3rd"
                              label={`Chance for a 3rd stat`}
                              disabled={crafting}
                            />
                          </Col>
                          <Col className="text-right">{chance3rd + " %"}</Col>
                        </Row>
                      )}
                      {Citem === "goldR" && (
                        <Row>
                          <Col xs="9" className="d-flex">
                            <Form.Check
                              onChange={handleChangeAlt}
                              checked={alt}
                              custom
                              type="checkbox"
                              id="alt"
                              label={"alternative Grafik"}
                              disabled={crafting}
                            />
                            <QuestionMarkIcon
                              onClick={() => setAltModal(true)}
                              style={{ cursor: "pointer" }}
                              fontSize="small"
                            />
                          </Col>
                        </Row>
                      )}
                    </Form>

                    {itemImg && (
                      <div className="text-center m-2">
                        Costs:
                        <img
                          style={{ height: "25px", width: "25px" }}
                          src={require(`../../images/items/misc/${itemImg}.png`)}
                          alt=""
                        />
                        <span
                          className={
                            costs > essences ? "text-danger" : "text-success"
                          }
                        >
                          {costs}
                        </span>{" "}
                        / {essences}
                      </div>
                    )}
                    <div className="text-center">
                      {crafting === false ? (
                        <Button
                          disabled={costs > essences}
                          variant="outline-secondary"
                          onClick={craft}
                        >
                          erstellen
                        </Button>
                      ) : (
                        <div>
                          <ProgressBar animated now={timeNow * 10} />
                          {timeNow * 10 + " %"}
                        </div>
                      )}
                    </div>
                  </Card.Body>
                </Card>
                <div className="text-center">
                  <Button
                    disabled={crafting}
                    onClick={() => (
                      setCItem(),
                      setCosts(0),
                      setEssences(0),
                      setCheckedAbis(false),
                      setCheckedMights(false),
                      setCheckedMightsN(false),
                      setCheckedMightsL(false),
                      setCheckedMightsD(false),
                      setChecked2nd(false),
                      setChecked3rd(false)
                    )}
                    variant="outline-secondary"
                  >
                    zurück
                  </Button>
                </div>
              </div>
            )}
            {timeNow === 11 && (
              <div>
                <Item type="box" imgFolder="rings" item={craftedItem} />
                <div className="text-center">
                  <Button
                    size="sm"
                    variant="outline-primary"
                    onClick={() => (
                      setCItem(), setCraftedItem(), setTimeNow(0)
                    )}
                  >
                    nochmal
                  </Button>
                </div>
              </div>
            )}
          </Col>
        </Row>
      )}
      <Modal
        show={altModal}
        onHide={() => setAltModal(false)}
        size="sm"
        centered
      >
        <Modal.Body className="text-center">
          <img
            src={require("../../images/items/rings/gold2.jpg")}
            width="50%"
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Craft;
