import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { POST, GET } from "../tools/fetch";
import { characterState__setOverviewData } from "../redux/actions/characterActions";

import Abi from "./ability";

import Row from "react-bootstrap/Row";
import CheckQuest from "./quest/checkQuest";
import { Col } from "react-bootstrap";

const Abis = () => {
  const data = useSelector((state) => state.skills);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState();
  const [loadTrainAbi, setLoadTrainAbi] = useState(false);
  const [skillable, setSkillable] = useState(false);
  const [points, setPoints] = useState(0);
  const [abilities, setAbilities] = useState();

  const quest = useSelector((state) => state.skills.skills.quest);

  const loadAbi = async () => {
    setLoading(true);
    try {
      const response = await GET("/character/abilities");
      if (response) {
        dispatch(characterState__setOverviewData(response));
        setAbilities(response);
        if (response.rsp > 0) {
          setSkillable(true);
          setPoints(+response.rsp);
        }
      }
    } finally {
      setLoading(false);
    }
  };

  const skillAbi = async (abi) => {
    setLoadTrainAbi(abi);
    try {
      const response = await POST("/character/abilities", { train: abi });
      if (response) {
        setAbilities(response);
        if (response.rsp <= 0) {
          setSkillable(false);
        }
        setPoints(+response.rsp);
      }
    } finally {
      setLoadTrainAbi(false);
    }
  };

  useEffect(() => {
    loadAbi();
  }, []);

  return (
    <>
      {loading === false && quest[0] === 1 && (
        <div>
          <CheckQuest details={quest[1]} refresh={loadAbi} />
        </div>
      )}
      {loading === false &&
        (quest === 0 ||
          (quest[1].quest_id === "1" &&
            (quest[1].step_id === "6" || quest[1].step_id === "7"))) &&
        points > 0 && (
          <div className="container text-center">
            <h3>
              Du hast noch {points} {points > 1 ? "Punkte" : "Punkt"}
            </h3>
          </div>
        )}
      {loading === false &&
      (quest === 0 ||
        (quest[1].quest_id === "1" &&
          (quest[1].step_id === "6" || quest[1].step_id === "7"))) ? (
        <Row>
          <Abi
            key="cns"
            abi="cns"
            basePoints={abilities.skills[0].cns}
            tempBonus={abilities.tempBonus["tmpcns"]}
            onClick={skillAbi}
            skillable={skillable}
            loadTrainAbi={loadTrainAbi}
            train={abilities.train}
          />
          <Abi
            key="agi"
            abi="agi"
            basePoints={abilities.skills[0].agi}
            tempBonus={abilities.tempBonus["tmpagi"]}
            onClick={skillAbi}
            skillable={skillable}
            loadTrainAbi={loadTrainAbi}
            train={abilities.train}
          />
          <Abi
            key="tac"
            abi="tac"
            basePoints={abilities.skills[0].tac}
            tempBonus={abilities.tempBonus["tmptac"]}
            onClick={skillAbi}
            skillable={skillable}
            loadTrainAbi={loadTrainAbi}
            train={abilities.train}
          />
          <Abi
            key="dex"
            abi="dex"
            basePoints={abilities.skills[0].dex}
            tempBonus={abilities.tempBonus["tmpdex"]}
            onClick={skillAbi}
            skillable={skillable}
            loadTrainAbi={loadTrainAbi}
            train={abilities.train}
          />
          <Abi
            key="spi"
            abi="spi"
            basePoints={abilities.skills[0].spi}
            tempBonus={abilities.tempBonus["tmpspi"]}
            onClick={skillAbi}
            skillable={skillable}
            loadTrainAbi={loadTrainAbi}
            train={abilities.train}
          />
          <Abi
            key="itl"
            abi="itl"
            basePoints={abilities.skills[0].itl}
            tempBonus={abilities.tempBonus["tmpitl"]}
            onClick={skillAbi}
            skillable={skillable}
            loadTrainAbi={loadTrainAbi}
            train={abilities.train}
          />
          <Abi
            key="lsa"
            abi="lsa"
            basePoints={abilities.skills[0].lsa}
            tempBonus={abilities.tempBonus["tmplsa"]}
            onClick={skillAbi}
            skillable={skillable}
            loadTrainAbi={loadTrainAbi}
            train={abilities.train}
          />
          <Abi
            key="lsd"
            abi="lsd"
            basePoints={abilities.skills[0].lsd}
            tempBonus={abilities.tempBonus["tmplsd"]}
            onClick={skillAbi}
            skillable={skillable}
            loadTrainAbi={loadTrainAbi}
            train={abilities.train}
          />
        </Row>
      ) : null}
      {loading === false &&
        (quest === 0 ||
          (quest[1].quest_id === "1" &&
            (quest[1].step_id === "6" || quest[1].step_id === "7"))) && (
          <div>
            Im Kampf erreichst du folgende Werte:
            <Row>
              <Col>Fight-Chance</Col>
              <Col>{abilities.fc} %</Col>
            </Row>
            <Row>
              <Col>Cast-Chance</Col>
              <Col>{abilities.cc} %</Col>
            </Row>
            <Row>
              <Col>Angriffswert bei Cast-Angriff</Col>
              <Col>{abilities.attM}</Col>
            </Row>
            <Row>
              <Col>Angriffswert bei Nahkampf-Angriff</Col>
              <Col>{abilities.attF}</Col>
            </Row>
            <Row>
              <Col>
                <small>
                  Diesen Werten, werden jeweils die Werte des Gegners gegenüber
                  gesetzt.
                  <br />
                  Daraus ergibt sich der Erfolg deiner Aktionen.
                </small>
              </Col>
            </Row>
          </div>
        )}
    </>
  );
};

export default Abis;
