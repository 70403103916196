import React, { useEffect, useState } from "react";
import { GET, POST } from "../../tools/fetch";
import Pagination from "react-js-pagination";
import {
  Accordion,
  Alert,
  Button,
  Card,
  Col,
  Form,
  Row,
} from "react-bootstrap";
import moment from "moment";

const AdminIngameNews = () => {
  const [loading, setLoading] = useState();
  const [news, setNews] = useState();
  const [activePage, setActivePage] = useState(1);
  const [text, setText] = useState("");

  const loadNews = async () => {
    setLoading(true);
    try {
      const request = await GET(`/admin/ingamenews?page=${activePage}`);
      if (request) {
        setNews(request);
      }
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };
  const updateNews = async (id, value, state) => {
    setLoading(true);
    try {
      const request = await POST("/admin/ingamenews", {});
      if (request) {
        setNews(request);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };
  const sendNews = async (e) => {
    e.preventDefault();
    try {
      e.preventDefault();
      await POST("/admin/ingamenews", {
        text: text,
      });
    } catch (error) {
    } finally {
      setText("");
      loadNews();
    }
  };
  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };
  useEffect(() => {
    loadNews();
  }, [activePage]);
  return (
    <div>
      {loading === false && (
        <Accordion className="mt-2">
          {Object.values(news.news).map((news, id) => {
            return (
              <Card key={news.id} className="shadow">
                <Accordion.Toggle
                  style={{ cursor: "pointer" }}
                  as={Card.Header}
                  eventKey={news.id}
                >
                  <div className="d-flex justify-content-between">
                    <div>{news.id}</div>
                  </div>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey={news.id}>
                  <Card.Body>
                    <div>
                      <Row>
                        <Col>ID: {news.id}</Col>
                        <Col>von: {news.author}</Col>
                        <Col>
                          @{" "}
                          {moment(news.date)
                            .local()
                            .format("DD.MM.yyyy H:mm:ss")}
                        </Col>
                      </Row>
                      <Alert
                        style={{ whiteSpace: "pre-line" }}
                        variant="secondary"
                      >
                        <span
                          dangerouslySetInnerHTML={{ __html: news.text }}
                        ></span>
                      </Alert>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            );
          })}
        </Accordion>
      )}
      {loading === false && news.number >= 1 && (
        <Pagination
          totalItemsCount={news.number}
          activePage={activePage}
          hideDisabled
          itemsCountPerPage={5}
          onChange={handlePageChange}
          itemClass="page-item"
          linkClass="page-link"
        />
      )}
      <Form
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <Form.Label>News Text</Form.Label>
        <Form.Control
          as="textarea"
          value={text}
          onChange={(e) => setText(e.target.value)}
          placeholder="news..."
        />
        <Button onClick={sendNews} className="mt-2" size="small">
          send
        </Button>
      </Form>
      {loading === true && "loading..."}
    </div>
  );
};

export default AdminIngameNews;
