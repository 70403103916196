// config.js
const config = (input) => {
  const rand = [1, 2, 3, 4, 5];
  const rand_shuffle = shuffle(rand)[0];
  const num =
    rand_shuffle + "" + ((input * 1000 * 44 * 19) / 135) * rand_shuffle;
  return num;
};
const shuffle = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    let j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};
export default config;
