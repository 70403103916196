import React from 'react';

const Search = (props) => {
    let style = "";
		if(props.inline === 1) {
            style = "container d-inline"
        } else {
            style = "container"
        }
        return (
            <div className={style}>			
                
                {/*Search Input*/}
                <label className="search-label" htmlFor="search-input">
                    <input
                        type="text"
                        value={props.value}
                        id="search-input"
                        placeholder="searching name, dmg or stat"
                        onChange={props.onSearch}
                    />
                    <i className="fa fa-search search-icon"/>
                </label>
                
            </div>
            )	
    }

export default Search;