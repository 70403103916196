import React from "react";

const MightBar = (props) => {
  return (
    <div>
      <div className="container-fluid d-flex justify-content-center p-0">
        <img
          style={{cursor: props.doing === "no" ? "pointer" : "auto"}}
          className="d-block m-1"
          src={require(`../../images/fseei.gif`)}
          onClick={() => {
            props.handleCast("fseei");
          }}
          alt=""
        />
        <img
          style={{cursor: props.doing === "no" ? "pointer" : "auto"}}
          className="d-block m-1"
          src={require(`../../images/fpush_g.gif`)}
          alt=""
        />
        <img
          style={{cursor: props.doing === "no" ? "pointer" : "auto"}}
          className="d-block m-1"
          src={require(`../../images/fpull_g.gif`)}
          alt=""
        />
        <img
          style={{cursor: props.doing === "no" ? "pointer" : "auto"}}
          className="d-block m-1"
          src={require(`../../images/fspee_g.gif`)}
          alt=""
        />
      </div>
    </div>
  );
};

export default MightBar;
