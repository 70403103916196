import React from "react";

import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";

const Item = (props) => {
  const postDataHandler = () => {
    props.dropItem({ itemid: props.item.itemid, type: props.type });
  };

  let dmg = "";
  if (props.item.mindmg !== 0 && props.item.mindmg !== undefined) {
    dmg = "Damage: " + props.item.mindmg + " - " + props.item.maxdmg;
  }

  if (props.type === "cash") {
    dmg = props.item.cash + " Credits";
    props.item.img = "cash";
    props.item.stat1 = "";
    props.item.stat2 = "";
    props.item.stat3 = "";
    props.item.stat4 = "";
    props.item.stat5 = "";
    props.item.reql = 0;
    props.item.reqs = 0;
  }

  const styleCard = { border: "1px solid rgba(0,0,0,0.125)" };
  return (
    <div>
      <Card style={styleCard} className="shadow mr-1">
        <Card.Img
          variant="top"
          style={{ width: "100px" }}
          className="mx-auto d-block mt-2"
          src={require(`../images/items/${props.imgFolder}/${props.item.img}.jpg`)}
        />
        <Card.Body className="text-center">
          {!props.type || props.type === "box" || props.type === "inventory" ? (
            <Card.Title>{props.item.name}</Card.Title>
          ) : null}

          <Card.Text>
            {props.item.uni === "y" ? (
              <span className="text-warning">unique item</span>
            ) : null}
            {props.item.uni === "y" ? <br /> : null}
            {props.item.nodrop === "y" ? "no drop" : null}
            {props.item.nodrop === "y" ? <br /> : null}
            {props.item.crafted === "y" ? "crafted" : null}
            {props.item.crafted === "y" ? <br /> : null}
            {dmg}
            {dmg ? <br /> : null}
            {props.item.stat1 !== "" && <div>{props.item.stat1}</div>}
            {props.item.stat2 !== "" && <div>{props.item.stat2}</div>}
            {props.item.stat3 !== "" && <div>{props.item.stat3}</div>}
            {props.item.stat4 !== "" && <div>{props.item.stat4}</div>}
            {props.item.stat5 !== "" && <div>{props.item.stat5}</div>}
            {props.item.stat1_mod}{" "}
            {props.item.stat1_stat != props.item.stat1_mod &&
              props.item.stat1_stat}{" "}
            {props.item.stat1_value !== "0" ? props.item.stat1_value : null}
            {props.item.stat1_mod !== undefined && <br />}
            {props.item.stat2_mod}{" "}
            {props.item.stat2_stat != props.item.stat2_mod &&
              props.item.stat2_stat}{" "}
            {props.item.stat2_value !== "0" ? props.item.stat2_value : null}
            {props.item.stat2_mod !== undefined && <br />}
            {props.item.stat3_mod}{" "}
            {props.item.stat3_stat != props.item.stat3_mod &&
              props.item.stat3_stat}{" "}
            {props.item.stat3_value !== "0" ? props.item.stat3_value : null}
            {props.item.stat3_mod !== undefined && <br />}
            {props.item.stat4_mod}{" "}
            {props.item.stat4_stat != props.item.stat4_mod &&
              props.item.stat4_stat}{" "}
            {props.item.stat4_value !== "0" ? props.item.stat4_value : null}
            {props.item.stat4_mod !== undefined && <br />}
            {props.item.stat5_mod}{" "}
            {props.item.stat5_stat != props.item.stat5_mod &&
              props.item.stat5_stat}{" "}
            {props.item.stat5_value !== "0" ? props.item.stat5_value : null}
            {props.item.stat5_mod !== undefined && <br />}
            {props.item.reql !== 0 ? `req. level: ${props.item.reql}` : null}
            {props.item.reql !== 0 ? <br /> : null}
            {props.item.reqs !== 0 ? `req. skill: ${props.item.reqs}` : null}
          </Card.Text>
        </Card.Body>

        {props.type !== "cash" &&
        props.type !== "box" &&
        props.type !== "inventory" ? (
          <Card.Footer>
            <small>
              <Button
                className="text-muted"
                size="sm"
                variant="link"
                onClick={postDataHandler}
              >
                ablegen
              </Button>
            </small>
          </Card.Footer>
        ) : null}
      </Card>
    </div>
  );
};

export default Item;
