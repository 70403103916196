import React, { useEffect, useState } from "react";
import {
  Button,
  ButtonGroup,
  CardDeck,
  Col,
  Modal,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { GET, POST } from "../../tools/fetch";
import Search from "../search";
import { inventoryState__setItems } from "../../redux/actions/inventoryActions";
import Checkbox from "../../tools/checkbox";
import Pagination from "react-js-pagination";
import TradeView from "./tradeView";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faTimesCircle } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

library.add(faTimesCircle);

const Trade = () => {
  const [searching, setSearching] = useState();
  const [search, setSearch] = useState("");
  const [result, setResult] = useState();
  const [showInv, setShowInv] = useState(false);
  const [type, setType] = useState("");
  const [cash, setCash] = useState(0);

  const [loading, setLoading] = useState();

  const [loadingItems, setLoadingItems] = useState();
  const [activePage, setActivePage] = useState(1);
  const [itemType, setItemType] = useState("weapons");
  const [sortDir, setSortDir] = useState("desc");
  const [sortType, setSortType] = useState("itemid");
  const [searchVal, setSearchVal] = useState("");

  const dispatch = useDispatch();
  const inv = useSelector((state) => state.skills.inv);
  const user = useSelector((state) => state.user.userId);
  const data = useSelector((state) => state.skills.skills);

  const [checkedItems, setCheckedItems] = useState([]);

  const [confirmModal, setConfirmModal] = useState(false);

  const [requests, setRequests] = useState([]);
  const [historie, setHistorie] = useState([]);

  const [error, setError] = useState(0);

  //Für das Gegenangebot (status = 2)
  const [tradeID, setTradeID] = useState(0);

  const [text, setText] = useState("");
  const [hist, setHist] = useState(false);

  const handleChangeModal = () => {
    setConfirmModal(!confirmModal);
  };

  const handleChange = (item) => {
    const itemID = item.itemid;
    //item entfernen
    if (checkedItems.some((checkedItem) => checkedItem.id === +itemID)) {
      const newItems = checkedItems.filter((newItem) => newItem.id !== +itemID);
      setCheckedItems(newItems);
    }
    //item hinzufügen
    else {
      setCheckedItems([
        ...checkedItems,
        { id: +itemID, type: type, item: item, checked: true },
      ]);
    }
  };

  const searchingDatabase = async () => {
    if (search.length >= 3) {
      try {
        setSearching(true);
        const response = await POST("/messages/search", {
          suchbegriff: search,
        });
        if (response.userid) {
          setResult(response.userid);
        } else {
        }
      } catch (e) {
        console.error(e);
      } finally {
        setSearching(false);
      }
    }
  };

  const handleStartOffer = () => {
    setShowInv(!showInv);
    loadItems(activePage, itemType, sortDir, sortType, searchVal);
  };

  const loadRequests = async () => {
    try {
      setLoading(true);
      const requestsDB = await GET("/trade/requests");
      if (requestsDB) {
        setRequests(requestsDB.requests);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const loadHistorie = async () => {
    try {
      setLoading(true);
      const requestsDB = await GET("/trade/historie");
      if (requestsDB) {
        setHistorie(requestsDB.requests);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const loadItems = async (
    activePage,
    itemType,
    sortDir,
    sortType,
    searchVal
  ) => {
    try {
      setLoadingItems(true);
      const response = await GET(
        `/character/inventory?id=${itemType}&page=${activePage}&sort=${sortType}&direction=${sortDir}&search=${searchVal}`
      );
      if (response) {
        dispatch(inventoryState__setItems(response));
        setType(response.img);
      }
    } catch (e) {
      console.error(e);
    } finally {
      // finally wird immer ausgefuehrt.
      // dadurch wird der state auch immer danach false gesetzt.
      setLoadingItems(false);
    }
  };

  const onSearch = (event) => {
    setSearchVal(event.target.value);
  };

  const handleSearchInput = (e) => {
    setSearch(e.target.value);
  };

  const handleOnSortDir = (SortType) => {
    if (sortDir === "desc") {
      setSortDir("asc");
    } else {
      setSortDir("desc");
    }
    setSortType(SortType);
  };

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const handleSendOffer = async () => {
    try {
      setLoading(true);
      let request = null;

      if (tradeID === 0) {
        request = await POST("/trade/offer", {
          items: checkedItems,
          cash: cash,
          receiver: +result,
          text: text,
        });
      } else {
        request = await POST("/trade/bid", {
          items: checkedItems,
          cash: cash,
          id: tradeID,
        });
      }
      if (request) {
        await loadItems(activePage, itemType, sortDir, sortType, searchVal);
      }
    } catch (e) {
      console.error(e);
      setError(e.code);
    } finally {
      setLoading(false);
      setCash(0);
      setConfirmModal(false);
      setCheckedItems([]);
      setShowInv(false);
      setResult();
      setSearch("");
      loadRequests();
      setTradeID(0);
    }
  };

  const handleResetOffer = () => {
    setCheckedItems([]);
    setConfirmModal(false);
    setCash(0);
  };

  const handleTradeOffer = async (id) => {
    try {
      setLoading(true);
      const request = await GET(`/trade/finish/${id}`);
    } catch (e) {
      console.error(e);
      setError(e.code);
    } finally {
      setLoading(false);
      //trade erfoglreich
      loadRequests();
    }
  };

  const handleCashChange = (e) => {
    if (e.target.value < 0) {
      setCash(0);
    } else if (e.target.value <= inv.char.cash) {
      setCash(+e.target.value);
    } else {
      setCash(inv.char.cash);
    }
  };

  const handleChangeText = (e) => {
    setText(e.target.value);
  };

  const handleChangeHistorie = () => {
    setHist(!hist);
  };

  useEffect(() => {
    loadRequests();
    loadHistorie();
  }, []);

  useEffect(() => {
    loadItems(activePage, itemType, sortDir, sortType, searchVal);
  }, [activePage, sortDir, searchVal]);

  useEffect(() => {
    searchingDatabase();
  }, [search]);

  return (
    <div>
      {data.char.actionid === 0 &&
      data.char.targetid === 0 &&
      data.char.targettime === 0 ? (
        <div>
          <Row className="justify-content-between">
            <Col md="auto">
              {loading === true && <Spinner animation="border" size="sm" />}
            </Col>
            <Col md="auto">
              <Button
                onClick={handleChangeHistorie}
                size="sm"
                variant="outline-secondary"
                disabled={loading}
              >
                {hist === false ? "Historie" : "zurück"}
              </Button>{" "}
              <Button
                disabled={loading}
                onClick={() => (loadRequests(), loadHistorie())}
                size="sm"
                variant="outline-primary"
              >
                refresh
              </Button>
            </Col>
          </Row>
          {hist === false && (
            <>
              {requests &&
                requests.length !== 0 &&
                requests.map((request) => {
                  return (
                    <div key={request.id}>
                      <div className="h3 text-center">
                        Handel mit{" "}
                        {request.offerer === user
                          ? request.bidderUser
                          : request.offererUser}
                      </div>
                      <div className="text-center">
                        {request.status === 1
                          ? "Antwort steht aus"
                          : request.status === 2 && request.offerer === user
                          ? "akzeptiere um den Trade abzuschließen"
                          : request.status === 2 && request.bidder === user
                          ? "du hast dein Angebot abgegeben, nun musst du warten ob es akzeptiert wird"
                          : "status"}
                      </div>
                      <CardDeck>
                        <TradeView
                          tradeInfo={request}
                          tradeItems={request.tradeItems}
                          tradeDirection={request.offerer}
                          loadFunc={loadRequests}
                          offerFunc={handleStartOffer}
                          idFunc={setTradeID}
                          tradeFunc={handleTradeOffer}
                          errorFunc={setError}
                        />

                        <TradeView
                          tradeInfo={request}
                          tradeItems={request.tradeItems}
                          tradeDirection={request.bidder}
                          loadFunc={loadRequests}
                          offerFunc={handleStartOffer}
                          idFunc={setTradeID}
                          tradeFunc={handleTradeOffer}
                          errorFunc={setError}
                        />
                      </CardDeck>
                      <hr />
                    </div>
                  );
                })}
              {hist === false && (
                <>
                  <div className="h5">neue Anfrage</div>
                  Username:{" "}
                  <input
                    type="text"
                    value={search}
                    onChange={handleSearchInput}
                  />{" "}
                  {searching === true && (
                    <Spinner animation="border" size="sm" />
                  )}
                  {result && (
                    <div className="ml-3 mt-3">
                      <Button
                        disabled={showInv}
                        onClick={handleStartOffer}
                        variant="outline-success"
                      >
                        Anfrage
                      </Button>
                    </div>
                  )}
                </>
              )}
              {showInv && loadingItems === false && (
                <div>
                  <Row className="p-1 justify-content-center">
                    <ButtonGroup>
                      <Button
                        disabled={loading}
                        variant="outline-dark"
                        onClick={() => {
                          loadItems(1, "weapons", "desc", "itemid", "");
                          setItemType("weapons");
                          setActivePage(1);
                          setSortType("itemid");
                          setSortDir("desc");
                          setSearchVal("");
                        }}
                      >
                        Waffen
                      </Button>
                      <Button
                        disabled={loading}
                        variant="outline-dark"
                        onClick={() => {
                          loadItems(1, "rings", "desc", "itemid", "");
                          setItemType("rings");
                          setActivePage(1);
                          setSortType("itemid");
                          setSortDir("desc");
                          setSearchVal("");
                        }}
                      >
                        Ringe
                      </Button>
                      <Button
                        disabled={loading}
                        variant="outline-dark"
                        onClick={() => {
                          loadItems(1, "misc", "desc", "itemid", "");
                          setItemType("misc");
                          setActivePage(1);
                          setSortType("itemid");
                          setSortDir("desc");
                          setSearchVal("");
                        }}
                      >
                        Verschiedenes
                      </Button>
                    </ButtonGroup>
                  </Row>
                  <div className="row justify-content-md-between">
                    <Col md="auto">
                      <Search onSearch={onSearch} value={searchVal} />
                    </Col>
                    <Col md="auto">
                      add Cash:{" "}
                      <input
                        min={0}
                        max={inv.char.cash}
                        value={cash}
                        onChange={handleCashChange}
                        style={{ height: "30px" }}
                        type="number"
                      />
                    </Col>
                  </div>
                  <Row>
                    <Col>
                      <label htmlFor="message-text" className="col-form-label">
                        Forderung formulieren?
                      </label>
                      <textarea
                        value={text}
                        onChange={handleChangeText}
                        maxLength={200}
                        className="form-control"
                        id="message-text"
                      />
                    </Col>
                  </Row>
                  <Table responsive="md" size="sm">
                    <thead>
                      <tr>
                        <th>
                          <Button
                            disabled={loadingItems}
                            onClick={() => handleOnSortDir("name")}
                            className="pl-2 pb-0 pt-0 pr-0 font-weight-bold text-secondary"
                            variant="link"
                          >
                            name
                          </Button>
                        </th>

                        {itemType === "weapons" ? (
                          <>
                            <th>
                              <Button
                                disabled={loadingItems}
                                onClick={() => handleOnSortDir("mindmg")}
                                className="p-0 font-weight-bold text-secondary"
                                variant="link"
                              >
                                mindmg
                              </Button>
                            </th>
                            <th>
                              <Button
                                disabled={loadingItems}
                                onClick={() => handleOnSortDir("maxdmg")}
                                className="p-0 font-weight-bold text-secondary"
                                variant="link"
                              >
                                maxdmg
                              </Button>
                            </th>
                          </>
                        ) : null}
                        <th>
                          <Button
                            disabled={loadingItems}
                            onClick={() => handleOnSortDir("qlvl")}
                            className="p-0 font-weight-bold text-secondary"
                            variant="link"
                          >
                            qlvl
                          </Button>
                        </th>
                        {inv.img !== "misc" && (
                          <>
                            <th>
                              <Button
                                disabled={loadingItems}
                                onClick={() => handleOnSortDir("reql")}
                                className="p-0 font-weight-bold text-secondary"
                                variant="link"
                              >
                                reql
                              </Button>
                            </th>
                            <th>
                              <Button
                                disabled={loadingItems}
                                onClick={() => handleOnSortDir("reqs")}
                                className="p-0 font-weight-bold text-secondary"
                                variant="link"
                              >
                                reqs
                              </Button>
                            </th>
                          </>
                        )}
                        <th>
                          <Button
                            disabled={loadingItems}
                            onClick={() => handleOnSortDir("stat1_value")}
                            className="p-0 font-weight-bold text-secondary"
                            variant="link"
                          >
                            stat1
                          </Button>
                        </th>
                        {inv.img !== "misc" && (
                          <>
                            <th>
                              <Button
                                disabled={loadingItems}
                                onClick={() => handleOnSortDir("stat2_value")}
                                className="p-0 font-weight-bold text-secondary"
                                variant="link"
                              >
                                stat2
                              </Button>
                            </th>
                            <th>
                              <Button
                                disabled={loadingItems}
                                onClick={() => handleOnSortDir("stat3_value")}
                                className="p-0 font-weight-bold text-secondary"
                                variant="link"
                              >
                                stat3
                              </Button>
                            </th>
                            <th>
                              <Button
                                disabled={loadingItems}
                                onClick={() => handleOnSortDir("stat4_value")}
                                className="p-0 font-weight-bold text-secondary"
                                variant="link"
                              >
                                stat4
                              </Button>
                            </th>
                            <th>
                              <Button
                                disabled={loadingItems}
                                onClick={() => handleOnSortDir("stat5_value")}
                                className="p-0 font-weight-bold text-secondary"
                                variant="link"
                              >
                                stat5
                              </Button>
                            </th>
                          </>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {loadingItems === false &&
                        inv.items.map((item) => {
                          //definieren ob Ring oder Waffe
                          item.type = inv.img;
                          if (item.nodrop !== "1") {
                            return (
                              <tr className="small" key={item.itemid}>
                                <td>
                                  <Checkbox
                                    checked={checkedItems.some(
                                      (e) => e.id === item.itemid
                                    )}
                                    name={item.itemid}
                                    onChange={() => handleChange(item)}
                                    itemName={item.name}
                                  />
                                </td>
                                {itemType === "weapons" ? (
                                  <>
                                    <td>{item.mindmg}</td>
                                    <td>{item.maxdmg}</td>
                                  </>
                                ) : null}
                                <td>{item.qlvl}</td>
                                {inv.img !== "misc" && (
                                  <>
                                    <td>
                                      {inv.char.skills.level < item.reql ? (
                                        <span className="text-danger">
                                          {item.reql}
                                        </span>
                                      ) : (
                                        item.reql
                                      )}
                                    </td>

                                    <td>
                                      {inv.char.skills.dex < item.reqs ? (
                                        <span className="text-danger">
                                          {item.reqs}
                                        </span>
                                      ) : (
                                        item.reqs
                                      )}
                                    </td>
                                  </>
                                )}
                                <td>
                                  {item.stat1_mod} {item.stat1_stat}{" "}
                                  {item.stat1_value !== "0"
                                    ? item.stat1_value
                                    : null}
                                </td>
                                {inv.img !== "misc" && (
                                  <>
                                    <td>
                                      {item.stat2_mod} {item.stat2_stat}{" "}
                                      {item.stat2_value !== "0"
                                        ? item.stat2_value
                                        : null}
                                    </td>
                                    <td>
                                      {item.stat3_mod} {item.stat3_stat}{" "}
                                      {item.stat3_value !== "0"
                                        ? item.stat3_value
                                        : null}
                                    </td>
                                    <td>
                                      {item.stat4_mod} {item.stat4_stat}{" "}
                                      {item.stat4_value !== "0"
                                        ? item.stat4_value
                                        : null}
                                    </td>
                                    <td>
                                      {item.stat5_mod} {item.stat5_stat}{" "}
                                      {item.stat5_value !== "0"
                                        ? item.stat5_value
                                        : null}
                                    </td>
                                  </>
                                )}
                              </tr>
                            );
                          }
                        })}
                    </tbody>
                  </Table>

                  <Pagination
                    hideDisabled
                    activePage={activePage}
                    itemsCountPerPage={10}
                    totalItemsCount={inv.totalItems ? inv.totalItems : 0}
                    pageRangeDisplayed={5}
                    onChange={handlePageChange}
                    itemClass="page-item"
                    linkClass="page-link"
                  />
                  {(checkedItems.length !== 0 || cash > 0) && (
                    <div>
                      <Button
                        disabled={loading}
                        size="sm"
                        variant="outline-success"
                        onClick={handleChangeModal}
                      >
                        anbieten
                      </Button>
                      <Button
                        disabled={loading}
                        size="sm"
                        variant="outline-danger"
                        onClick={handleResetOffer}
                      >
                        zurücksetzen
                      </Button>
                    </div>
                  )}
                </div>
              )}
            </>
          )}
          {hist === true && (
            <div>
              {historie &&
                historie.length !== 0 &&
                historie.map((request) => {
                  return (
                    <div key={request.id}>
                      <div className="h3 text-center">
                        Handel mit{" "}
                        {request.offerer === user
                          ? request.bidderUser
                          : request.offererUser}
                      </div>
                      <div className="text-center">
                        {request.status === 3 && "abgeschlossen"}
                      </div>
                      <CardDeck>
                        <TradeView
                          tradeInfo={request}
                          tradeItems={request.tradeItems}
                          tradeDirection={request.offerer}
                          loadFunc={loadRequests}
                          offerFunc={handleStartOffer}
                          idFunc={setTradeID}
                          tradeFunc={handleTradeOffer}
                          errorFunc={setError}
                        />

                        <TradeView
                          tradeInfo={request}
                          tradeItems={request.tradeItems}
                          tradeDirection={request.bidder}
                          loadFunc={loadRequests}
                          offerFunc={handleStartOffer}
                          idFunc={setTradeID}
                          tradeFunc={handleTradeOffer}
                          errorFunc={setError}
                        />
                      </CardDeck>
                      <hr />
                    </div>
                  );
                })}
            </div>
          )}
          {/*confirm Modal*/}
          <Modal show={confirmModal} centered onHide={handleChangeModal}>
            <Modal.Header>Angebot überprüfen</Modal.Header>
            <Modal.Body>
              {checkedItems.map((item) => {
                return (
                  <div className="border-bottom pb-1" key={item.item.itemid}>
                    {item.item.name}
                    <div className="ml-2">
                      Quality: {item.item.qlvl}
                      {item.type === "weapons" && (
                        <>
                          {" / "}
                          Damage: {item.item.mindmg} - {item.item.maxdmg}
                        </>
                      )}
                      {item.item.stat1_mod !== "" && (
                        <>
                          {" / "}
                          {item.item.stat1_mod +
                            " " +
                            item.item.stat1_stat +
                            " " +
                            item.item.stat1_value}
                        </>
                      )}
                      {item.item.stat2_mod !== "" && (
                        <>
                          {" / "}
                          {item.item.stat2_mod +
                            " " +
                            item.item.stat2_stat +
                            " " +
                            item.item.stat2_value}
                        </>
                      )}
                      {item.item.stat3_mod !== "" && (
                        <>
                          {" / "}
                          {item.item.stat3_mod +
                            " " +
                            item.item.stat3_stat +
                            " " +
                            item.item.stat3_value}
                        </>
                      )}
                      {item.item.stat4_mod !== "" && (
                        <>
                          {" / "}
                          {item.item.stat4_mod +
                            " " +
                            item.item.stat4_stat +
                            " " +
                            item.item.stat4_value}
                        </>
                      )}
                      {item.item.stat5_mod !== "" && (
                        <>
                          {" / "}
                          {item.item.stat5_mod +
                            " " +
                            item.item.stat5_stat +
                            " " +
                            item.item.stat5_value}
                        </>
                      )}
                    </div>
                  </div>
                );
              })}
              {cash > 0 && <div>Cash: {cash} Cr.</div>}
              <br />
              möchtest du diese Items wirklich anbieten?
            </Modal.Body>
            <Modal.Footer>
              <Button
                disabled={loading}
                onClick={handleChangeModal}
                size="sm"
                variant="outline-danger"
              >
                zurück
              </Button>
              <Button
                disabled={loading}
                onClick={handleSendOffer}
                size="sm"
                variant="outline-success"
              >
                anbieten
              </Button>
            </Modal.Footer>
          </Modal>
          {/*error Modal*/}
          <Modal show={error === 404} centered onHide={() => setError(0)}>
            <Modal.Header className="d-block text-center border-0">
              <div className="text-center">
                <FontAwesomeIcon size="3x" icon={["far", "times-circle"]} />
              </div>
              <div className="h4 text-center w-100">Sorry!</div>
            </Modal.Header>
            <Modal.Body>
              <p class="text-center">
                Der Handel wurde bereits abgeschlossen oder gelöscht
              </p>
            </Modal.Body>
            <Modal.Footer className="border-0">
              <Button block variant="danger" onClick={() => setError(0)}>
                OK
              </Button>
            </Modal.Footer>
          </Modal>
          {/*Modal wenn kein Gegenangebot*/}
          <Modal
            show={
              showInv === false && checkedItems.length === 0 && tradeID !== 0
            }
            centered
            onHide={() => setTradeID(0)}
          >
            <Modal.Body>du möchtest es also geschenkt?</Modal.Body>
            <Modal.Footer>
              <Button onClick={handleSendOffer}>ja</Button>
            </Modal.Footer>
          </Modal>
        </div>
      ) : (
        <div>Du bist beschäftigt</div>
      )}
    </div>
  );
};

export default Trade;
