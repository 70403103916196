import React from "react";

const Essence = (props) => {
  return (
    <div style={{ display: "inline" }}>
      <img
        style={{ height: "5%", width: "5%" }}
        src={require(`../images/items/misc/${props.img}.png`)}
        alt=""
      />{" "}
      {props.count}
    </div>
  );
};

export default Essence;
