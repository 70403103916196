import React from "react";

const Craftbars = (props) => {
  return (
    <div>
      <div className="container mt-3 col p-0 progress-group">
        <div className="progress-group-header align-items-end">
          <i className="cil-globe far progress-group-icon">{props.img}</i>
          <div>{props.type}</div>
          <div className="ml-auto font-weight-bold mr-2">{props.data}</div>
        </div>
        <div className="progress-group-bars">
          <div style={{ height: "4px" }} className="progress">
            <div
              className={`progress-bar ${props.bg}`}
              role="progressbar"
              style={{ width: props.width, background: props.side }}
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Craftbars;
