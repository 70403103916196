import React from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";

const Casino = () => {
  const userid = useSelector((state) => state.user.userId);
  const data = useSelector((state) => state.skills.skills);

  return (
    <div>
      {data.char.actionid === 0 &&
      data.char.targetid === 0 &&
      data.char.targettime === 0 ? (
        <Row>
          <Col md="2" xs="4">
            <Link to="/bj">
              <img
                className="w-100 h-100"
                src={require(`../../images/bj.jpg`)}
                alt=""
              />
            </Link>
          </Col>
          {userid === 20 && (
            <Col md="2" xs="4">
              <Link to="/roulette">
                <img
                  className="w-100 h-100"
                  src={require(`../../images/roulette.jpg`)}
                  alt=""
                />
              </Link>
            </Col>
          )}
          <Col md="2" xs="4">
            <Link to="/bingo">
              <img
                className="w-100 h-100"
                src={require(`../../images/bingo.jpg`)}
                alt=""
              />
            </Link>
          </Col>
        </Row>
      ) : (
        <div>Du bist gerade beschäftigt</div>
      )}
    </div>
  );
};

export default Casino;
