import QuestionMark from "@mui/icons-material/QuestionMark";
import React, { useEffect, useState } from "react";
import { Button, Col, Modal, Row, Table } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { POST } from "../../../tools/fetch";
import "./bingo.css";

const Bingo = () => {
  const user = useSelector((state) => state.skills.skills.skills);
  const [card, setCard] = useState([]);
  const [rows2, setRows2] = useState(0);
  const [drawedNumbers, setDrawedNumbers] = useState([]);
  const [markedNumbers, setMarkedNumbers] = useState([]);
  const [markedRows, setMarkedRows] = useState([]);
  const [markedColmn, setMarkedColmn] = useState([]);
  const [winningKeys, setWinningKeys] = useState([]);
  const [drawing, setDrawing] = useState(false);
  const [gameStatus, setGameStatus] = useState("active");
  const [newGame, setNewGame] = useState("");
  const [message, setMessage] = useState("");
  const [modal, setModal] = useState(false);
  const [winningStreak, setWinningStreak] = useState(0);
  const numbers = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40,
    //41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59,
    //60,
  ];
  const createCard = () => {
    setGameStatus("active");
    const selectedValues = [];
    while (selectedValues.length < 25) {
      const index = Math.floor(Math.random() * numbers.length);
      const value = numbers[index];
      if (!selectedValues.includes(value)) {
        selectedValues.push(value);
      }
    }
    setCard(selectedValues);
    const rows = [];
    for (let i = 0; i < selectedValues.length; i += 5) {
      const row = selectedValues.slice(i, i + 5);
      rows.push(row);
    }
    rows[0][5] = (
      <img
        key={1}
        style={{ height: "30px", width: "30px" }}
        src={require(`../../../images/items/misc/health.png`)}
        alt=""
      />
    );
    rows[1][5] = <div key={2}>{user.level * 50} Cr.</div>;
    rows[2][5] = (
      <img
        key={3}
        style={{ height: "30px", width: "30px" }}
        src={require(`../../../images/items/misc/essenceGold.png`)}
        alt=""
      />
    );
    rows[3][5] = (
      <img
        key={4}
        style={{ height: "30px", width: "30px" }}
        src={require(`../../../images/items/misc/essenceSilver.png`)}
        alt=""
      />
    );
    rows[4][5] = (
      <img
        key={5}
        style={{ height: "30px", width: "30px" }}
        src={require(`../../../images/items/misc/mega.png`)}
        alt=""
      />
    );
    rows[5] = [];
    rows[5][0] = (
      <img
        key={6}
        style={{ height: "30px", width: "30px" }}
        src={require(`../../../images/items/misc/mana.png`)}
        alt=""
      />
    );
    rows[5][1] = (
      <img
        key={7}
        style={{ height: "30px", width: "30px" }}
        src={require(`../../../images/items/misc/essenceRusty.png`)}
        alt=""
      />
    );
    rows[5][2] = (
      <div key={8}>
        {user.level * 50} <br /> XP
      </div>
    );
    rows[5][3] = (
      <img
        key={9}
        style={{ height: "30px", width: "30px" }}
        src={require(`../../../images/items/misc/energie.png`)}
        alt=""
      />
    );
    rows[5][4] = (
      <img
        key={10}
        style={{ height: "30px", width: "30px" }}
        src={require(`../../../images/items/misc/essenceOnion.png`)}
        alt=""
      />
    );
    rows[5][5] = "";
    setRows2(rows);
    const index = Math.floor(Math.random() * numbers.length);
    const value = numbers[index];
    if (!drawedNumbers.includes(value)) {
      setDrawedNumbers([...drawedNumbers, value]);
    }
    POST("/character/saveuser", {
      where: "char",
      what: "cash",
      amount: -500,
    });
  };
  const marked = (e, rowIndex, itemIndex) => {
    if (
      drawedNumbers.length !== 0 &&
      drawedNumbers[drawedNumbers.length - 1] === +e.target.textContent &&
      !markedNumbers.includes(+e.target.textContent)
    ) {
      setMarkedNumbers([...markedNumbers, +e.target.textContent]);
      setMarkedColmn([...markedColmn, itemIndex]);
      setMarkedRows([...markedRows, rowIndex]);
      //setMarkedIndexes(...markedIndexes, [(rowIndex = { row: itemIndex })]);
      checkWinning();
    }
  };
  const checkWinning = () => {
    let winningStreak_temp = 0;
    let winningKeys_temp = winningKeys;
    let einsR = 0;
    let zweiR = 0;
    let dreiR = 0;
    let vierR = 0;
    let fuenfR = 0;
    markedRows.forEach((element) => {
      if (element === 0) {
        einsR += 1;
        if (einsR === 5 && !winningKeys.includes(1)) {
          winningKeys_temp.push(1);
          winningStreak_temp += 1;
        }
      } else if (element === 1) {
        zweiR += 1;
        if (zweiR === 5 && !winningKeys.includes(2)) {
          winningKeys_temp.push(2);
          winningStreak_temp += 1;
        }
      } else if (element === 2) {
        dreiR += 1;
        if (dreiR === 5 && !winningKeys.includes(3)) {
          winningKeys_temp.push(3);
          winningStreak_temp += 1;
        }
      } else if (element === 3) {
        vierR += 1;
        if (vierR === 5 && !winningKeys.includes(4)) {
          winningKeys_temp.push(4);
          winningStreak_temp += 1;
        }
      } else if (element === 4) {
        fuenfR += 1;
        if (fuenfR === 5 && !winningKeys.includes(5)) {
          winningKeys_temp.push(5);
          winningStreak_temp += 1;
        }
      }
    });

    let einsC = 0;
    let zweiC = 0;
    let dreiC = 0;
    let vierC = 0;
    let fuenfC = 0;
    markedColmn.forEach((element) => {
      if (element === 0) {
        einsC += 1;
        if (einsC === 5 && !winningKeys.includes(6)) {
          winningKeys_temp.push(6);
          winningStreak_temp += 1;
        }
      } else if (element === 1) {
        zweiC += 1;
        if (zweiC === 5 && !winningKeys.includes(7)) {
          winningKeys_temp.push(7);
          winningStreak_temp += 1;
        }
      } else if (element === 2) {
        dreiC += 1;
        if (dreiC === 5 && !winningKeys.includes(8)) {
          winningKeys_temp.push(8);
          winningStreak_temp += 1;
        }
      } else if (element === 3) {
        vierC += 1;
        if (vierC === 5 && !winningKeys.includes(9)) {
          winningKeys_temp.push(9);
          winningStreak_temp += 1;
        }
      } else if (element === 4) {
        fuenfC += 1;
        if (fuenfC === 5 && !winningKeys.includes(10)) {
          winningKeys_temp.push(10);
          winningStreak_temp += 1;
        }
      }
    });
    if (winningStreak_temp > 0) {
      setWinningKeys(winningKeys_temp);
    }
    setWinningStreak(winningStreak_temp);
  };
  const sendWinning = async () => {
    if (winningKeys.length !== 0) {
      for (let index = 1; index <= winningStreak; index++) {
        console.log(index);
        post(winningKeys.length - index);
        setMessage("Glückwunsch!");
      }
    }
  };
  const post = (item) => {
    POST("/cron/bingo", {
      item: winningKeys[item],
    });
  };
  useEffect(() => {
    if (
      drawedNumbers.length < 25 &&
      card.length !== 0 &&
      gameStatus === "active"
    ) {
      const interval = setInterval(() => {
        // Hier kannst du den Code schreiben, der alle 20 Sekunden ausgeführt werden soll.
        setDrawing(true);
        const index = Math.floor(Math.random() * numbers.length);
        const value = numbers[index];
        if (!drawedNumbers.includes(value)) {
          setDrawedNumbers([...drawedNumbers, value]);
        }
      }, 7000);
      setDrawing(false);
      return () => clearInterval(interval);
    }
    if (drawedNumbers.length === 25) {
      setGameStatus("finish");
    }
  }, [drawedNumbers, card, gameStatus]);

  useEffect(() => {
    checkWinning();
  }, [markedNumbers]);

  useEffect(() => {
    if (gameStatus === "finish" && newGame === "new") {
      setCard([]);
      setDrawedNumbers([]);
      setMarkedNumbers([]);
      setMarkedColmn([]);
      setMarkedRows([]);
      setWinningKeys([]);
      setDrawing(false);
      setNewGame("");
      setGameStatus("pre");
    }
  }, [gameStatus, newGame]);

  useEffect(() => {
    if (gameStatus === "pre") {
      createCard();
      setGameStatus("active");
    }
  }, [gameStatus]);

  useEffect(() => {
    sendWinning();
  }, [winningKeys, winningStreak]);
  return (
    <div>
      <div className="d-flex justify-content-between align-items-center">
        <div className="h5 text-center flex-grow-1">B I N G O</div>
        <div className="ml-auto align-self-baseline">
          <QuestionMark
            fontSize="sm"
            onClick={() => setModal(true)}
            style={{ cursor: "pointer" }}
          />
        </div>
      </div>

      {card.length !== 0 && (
        <>
          {drawedNumbers.length !== 0 && (
            <div className="circle-wrap">
              <div className="circle">
                <div className="mask full">
                  {gameStatus === "active" && <div className="fill"></div>}
                </div>

                <div className="mask half">
                  {gameStatus === "active" && <div className="fill"></div>}
                </div>

                <div className="inside-circle">
                  {drawedNumbers[drawedNumbers.length - 1]}
                </div>
              </div>
            </div>
          )}
          <div className="d-block text-center mb-2">
            restliche Ziehungen: {25 - drawedNumbers.length}
          </div>
        </>
      )}
      {rows2 !== 0 && (
        <Table responsive size="sm">
          <tbody>
            {rows2.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {row.map((item, itemIndex) => (
                  <td
                    className="text-center"
                    key={itemIndex}
                    style={{
                      cursor:
                        drawedNumbers[drawedNumbers.length - 1] === item &&
                        "pointer",
                    }}
                    onClick={(item) => marked(item, rowIndex, itemIndex)}
                  >
                    <div
                      className={
                        markedNumbers.includes(item)
                          ? "border rounded border-danger"
                          : winningKeys.includes(+item.key)
                          ? "border rounded border-success"
                          : ""
                      }
                    >
                      {item}
                    </div>
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      )}
      {message != "" && <div className="d-block text-center">{message}</div>}
      {card.length === 0 && gameStatus === "active" && (
        <div>
          <Button size="sm" onClick={() => createCard()}>
            start
          </Button>{" "}
          <div>500 Credits</div>
        </div>
      )}
      {drawedNumbers.length === 25 && (
        <Button onClick={() => setNewGame("new")}>new Game</Button>
      )}
      <Modal show={modal} onHide={() => setModal(false)}>
        <Modal.Header>BINGO - Regeln</Modal.Header>
        <Modal.Body>
          <Row>
            <Col xs="2">1.</Col>
            <Col>Einsatz beträgt 500 Credits.</Col>
          </Row>
          <Row>
            <Col xs="2">2.</Col>
            <Col>Gespielt wird mit den Zahlen 1 - 60.</Col>
          </Row>
          <Row>
            <Col xs="2">3.</Col>
            <Col>Es werden 25 Zahlen gezogen.</Col>
          </Row>
          <Row>
            <Col xs="2">4.</Col>
            <Col>Alle 15 Sekunden wird eine neue Zahl gezogen.</Col>
          </Row>
          <Row>
            <Col xs="2">5.</Col>
            <Col>Gewinne werden automatisch gutgeschrieben.</Col>
          </Row>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Bingo;
