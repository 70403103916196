import React, { useEffect, useState } from "react";
import { GET } from "../../tools/fetch";
import Bars from "../bars";
import Craftbars from "./craftbars";
import "./crafting.css";

const Crafting = () => {
  const [loading, setLoading] = useState();
  const [progress, setProgress] = useState({});

  const loadProgress = async () => {
    try {
      setLoading(true);
      const request = await GET("/character/craftprogress");
      if (request) {
        setProgress(request);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadProgress();
  }, []);

  return (
    <div>
      <div className="row">
        <div className="col-md-6">
          <h2 className="text-center">Waffen</h2>
          {loading === false && (
            <div>
              <Craftbars
                type="Mahogany"
                side="#ffd700"
                width={progress.maho + "%"}
                data={progress.maho + " %"}
                img={
                  <img
                    style={{ width: "16px", height: "16px" }}
                    src={require(`../../images/items/weapons/wood2.gif`)}
                    alt=""
                  />
                }
              />
              <Craftbars
                type="Wood"
                side="#c0c0c0"
                width={progress.wood + "%"}
                data={progress.wood + " %"}
                img={
                  <img
                    style={{ width: "16px", height: "16px" }}
                    src={require(`../../images/items/weapons/wood1.gif`)}
                    alt=""
                  />
                }
              />
              <Craftbars
                type="Rusty"
                side="#b7410e"
                width={progress.rustyS + "%"}
                data={progress.rustyS + " %"}
                img={
                  <img
                    style={{ width: "16px", height: "16px" }}
                    src={require(`../../images/items/weapons/rusty1.gif`)}
                    alt=""
                  />
                }
              />
              <Craftbars
                type="Adv. Fighting"
                side="#ccac00"
                width={progress.advF + "%"}
                data={progress.advF + " %"}
                img={
                  <img
                    style={{ width: "16px", height: "16px" }}
                    src={require(`../../images/items/weapons/fight1.gif`)}
                    alt=""
                  />
                }
              />
              <Craftbars
                type="Stainless Steel"
                side="#ccac00"
                width={progress.stainless + "%"}
                data={progress.stainless + " %"}
                img={
                  <img
                    style={{ width: "16px", height: "16px" }}
                    src={require(`../../images/items/weapons/stainless1.gif`)}
                    alt=""
                  />
                }
              />
            </div>
          )}
        </div>
        <div className="col-md-6">
          <h2 className="text-center">Ringe</h2>
          {loading === false && (
            <div>
              <Craftbars
                type="Gold Ring"
                side="#ffd700"
                width={progress.gold + "%"}
                data={progress.gold + " %"}
                img={
                  <img
                    src={require(`../../images/items/rings/gold1.gif`)}
                    alt=""
                  />
                }
              />
              <Craftbars
                type="Silver Ring"
                side="#c0c0c0"
                width={progress.silver + "%"}
                data={progress.silver + " %"}
                img={
                  <img
                    src={require(`../../images/items/rings/silver1.gif`)}
                    alt=""
                  />
                }
              />
              <Craftbars
                type="Rusty Ring"
                side="#b7410e"
                width={progress.rusty + "%"}
                data={progress.rusty + " %"}
                img={
                  <img
                    src={require(`../../images/items/rings/rusty1.gif`)}
                    alt=""
                  />
                }
              />
              <Craftbars
                type="Onion Ring"
                side="#ccac00"
                width={progress.onion + "%"}
                data={progress.onion + " %"}
                img={
                  <img
                    src={require(`../../images/items/rings/onion1.gif`)}
                    alt=""
                  />
                }
              />
            </div>
          )}
        </div>
      </div>
      <small>
        Bei 100% kannst du 10% bessere Items erstellen, sowie 10% der Kosten
        sparen.
        <br />
        Bei weniger, entsprechend dem Verhältnis.
      </small>
    </div>
  );
};

export default Crafting;
