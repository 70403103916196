import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import { POST } from "../../tools/fetch";
import { useHistory } from "react-router-dom";
import "./create.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";

library.add(faQuestionCircle);

const Create = () => {
  const history = useHistory();

  const [name, setName] = useState("");
  const [geschl, setGeschl] = useState(true);
  const [gesin, setGesin] = useState(true);
  const [spec, setSpec] = useState("");
  const [age, setAge] = useState();
  const [height, setHeight] = useState();
  const [home, setHome] = useState();
  const homeWorlds = [
    "unknown",
    "Alderaan",
    "Arbra",
    "Bespin",
    "Corellia",
    "Coruscant",
    "Dagobah",
    "Endor",
    "Geonosis",
    "Hoth",
    "Kamino",
    "Kashyyyk",
    "Myrkr",
    "Naboo",
    "Nar Shaddaa",
    "Ord Mantell",
    "Raxus Prime",
    "Tatooine",
    "Yavin IV",
  ];
  const specRace = [
    "Besalisk",
    "Ewok",
    "Gungan",
    "Gran",
    "Human",
    "Klatooinian",
    "Rodian",
    "Trandoshan",
    "Twilek",
    "Wookiee",
    "Zabrak",
    "Yuuzhan Vong",
  ];
  const bools = [true, false];

  const vokal = [
    "a",
    "b",
    "c",
    "d",
    "f",
    "g",
    "h",
    "j",
    "k",
    "l",
    "m",
    "n",
    "p",
    "q",
    "r",
    "s",
    "t",
    "v",
    "w",
    "x",
    "z",
  ];

  const konso = ["e", "i", "o", "u", "y"];

  const [modalRule, setModalRule] = useState(false);

  const handleRules = () => {
    setModalRule(!modalRule);
  };

  const [changedGesin, setChangedGesin] = useState(false);
  const [changedGeschl, setChangedGeschl] = useState(false);
  const [changed, setChanged] = useState(false);

  const [searching, setSearching] = useState();
  const [result, setResult] = useState();

  const searchingDatabase = async (name) => {
    if (name.length >= 3) {
      try {
        setSearching(true);
        const response = await POST("/messages/search", {
          suchbegriff: name,
        });
        if (response.userid) {
          setResult(response.userid);
        } else {
        }
      } catch (e) {
        console.error(e);
      } finally {
        setSearching(false);
      }
    }
  };

  const setRandom = () => {
    const randIndex = Math.floor(Math.random() * specRace.length);
    const randIndexHome = Math.floor(Math.random() * homeWorlds.length);
    const randIndexSeite = Math.floor(Math.random() * bools.length);
    const randIndexSex = Math.floor(Math.random() * bools.length);
    const seite = bools[randIndexSeite];
    const sex = bools[randIndexSex];
    const home = homeWorlds[randIndexHome];
    const race = specRace[randIndex];

    const nameLength = randomNumber(2, 4);
    let sugName = "";
    for (let index = 0; index < nameLength; index++) {
      const randIndexVokal = Math.floor(Math.random() * vokal.length);
      const randIndexKonso = Math.floor(Math.random() * konso.length);

      sugName += vokal[randIndexVokal];
      sugName += konso[randIndexKonso];
    }
    const nameCapitalized = sugName.charAt(0).toUpperCase() + sugName.slice(1);

    if (changed === false || name === "") {
      setName(nameCapitalized);
    }
    if (changedGeschl === false) {
      setGeschl(sex);
    }
    if (changedGesin === false) {
      setGesin(seite);
    }
    setHome(home);
    setSpec(race);
    let newHeight = 0;
    switch (race) {
      case "Besalisk":
        setAge(randomNumber(21, 37));
        newHeight = setHeight(randomNumber(174, 203));
        break;
      case "Ewok":
        setHome("Endor");
        setAge(randomNumber(3, 20));
        newHeight = setHeight(randomNumber(65, 125));
        break;
      case "Gungan":
        setHome("Naboo");
        setAge(randomNumber(15, 26));
        newHeight = setHeight(randomNumber(170, 205));
        break;
      case "Gran":
        setAge(randomNumber(19, 39));
        newHeight = setHeight(randomNumber(173, 199));
        break;
      case "Human":
        setAge(randomNumber(16, 45));
        newHeight = setHeight(randomNumber(165, 200));
        break;
      case "Klatooinian":
        setAge(randomNumber(17, 33));
        newHeight = setHeight(randomNumber(150, 170));
        break;
      case "Rodian":
        setAge(randomNumber(17, 33));
        newHeight = setHeight(randomNumber(150, 170));
        break;
      case "Tradoshan":
        setAge(randomNumber(13, 35));
        newHeight = setHeight(randomNumber(187, 211));
        break;
      case "Twilek":
        setAge(randomNumber(23, 41));
        newHeight = setHeight(randomNumber(189, 212));
        break;
      case "Wookiee":
        setHome("Kashyyyk");
        setAge(randomNumber(18, 34));
        newHeight = setHeight(randomNumber(185, 216));
        break;
      case "Yuuzhan Vong":
        setAge(randomNumber(22, 32));
        newHeight = setHeight(randomNumber(171, 192));
        break;
      case "Zabrak":
        setAge(randomNumber(15, 36));
        newHeight = setHeight(randomNumber(159, 209));
        break;
      default:
        break;
    }
  };

  const [image, setImage] = useState("");

  useEffect(() => {
    if (spec !== "") {
      let species = spec.toLowerCase();
      if (spec === "Yuuzhan Vong") {
        species = "yuuzhan_vong";
      } else {
        species = spec.toLowerCase();
      }
      setImage(species);
    }
  }, [spec, geschl]);

  const create = async () => {
    try {
      const request = POST("/accounts/create", {
        s_sex1: geschl === false ? "m" : "f",
        s_height1: height,
        s_home1: home,
        s_race1: spec,
        s_age1: age,
        s_side1: gesin === false ? "h" : "d",
        s_charname1: name,
      });
    } catch (e) {
      console.error(e);
    } finally {
      history.push("/overview");
    }
  };

  // Function to generate random number
  function randomNumber(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  useEffect(() => {
    setRandom();
  }, []);

  return (
    <div>
      <div className="h3">
        Willkommen, hier kannst du deinen eigenen Charakter erstellen
      </div>

      {result && (
        <div className="text-danger">Dieser Name wird bereits verwendet</div>
      )}
      <div>
        Name:{" "}
        <input
          style={{ border: "none" }}
          className="mb-1 border-bottom border-dark"
          required
          type="text"
          value={name}
          onChange={(e) => (
            setName(e.target.value),
            setChanged(true),
            searchingDatabase(e.target.value)
          )}
        />
        <FontAwesomeIcon
          onClick={handleRules}
          style={{ verticalAlign: "middle" }}
          icon={["far", "question-circle"]}
        />
        {searching === true && <Spinner animation="border" size="sm" />}
      </div>
      <Row>
        <Col xs="6">
          <div>
            <u>Ausrichtung</u>
          </div>
          <Form className="gesin d-flex">
            <div className="pr-2">Hell</div>
            <Form.Check
              checked={gesin}
              onChange={() => (setGesin(!gesin), setChangedGesin(true))}
              type="switch"
              id="gesin"
              label=""
            />
            <div>Dunkel</div>
          </Form>
          <div>
            <u>Geschlecht</u>
          </div>
          <Form className="d-flex">
            <div className="pr-2">Männlich</div>
            <Form.Check
              checked={geschl}
              onChange={() => (setGeschl(!geschl), setChangedGeschl(true))}
              type="switch"
              id="geschl"
              label=""
            />
            <div>Weiblich</div>
          </Form>
          <div>Spezies: {spec}</div>
          <div>Alter: {age} Jahre</div>
          <div>Größe: {height} cm</div>
          <div>Heimatwelt: {home}</div>
          <Button size="sm" onClick={create} disabled={result}>
            ok
          </Button>{" "}
          <Button size="sm" onClick={() => setRandom()}>
            mischen
          </Button>
        </Col>
        <Col xs="6">
          {spec !== "" && image !== "" && (
            <img
              style={{ width: "160px" }}
              src={require(`../../images/npc/spec/${image}_${
                geschl ? "f" : "m"
              }.jpg`)}
              alt="Species Image"
            />
          )}
        </Col>
      </Row>
      <Modal centered show={modalRule} onHide={() => setModalRule(false)}>
        <Modal.Header>Namesregeln</Modal.Header>
        <Modal.Body>
          <ul>
            Select your character name. Rules for selecting:
            <br />{" "}
            <li>
              Only the letters a-Z are allowed, while <b>only</b> the first
              letters can be capitals, additionally there can be one space
              between two names
            </li>
            <li>
              No Existing Character names like: Luke Skywalker, God, ...
            </li>
            <li>No titles or ranks in name like: Mr., Darth ..., ...</li>
            <li>
              No names consisting only of items, subjects or similar like:
              Mouseman, Lasersword, Eleminator, Light, ...
            </li>
            <li>
              No senseless or nameless names like: Hmpf, Damn, Pwnd,
              Stormtrooper, ...
            </li>
            Any ignored rule will result in a <b>temporary deactivation</b> as long as the Name is not changed.
          </ul>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Create;
