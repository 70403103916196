import React from "react";
import { useSelector } from "react-redux";
import "./temple.css";

const TempleSkill = (props) => {
  const data = useSelector((state) => state.char.char);
  const img = (skill, choose) => {
    if (choose === true) {
      return (
        <img
          className="align-self-center picColor"
          src={require(`../../images/tempel/${skill}.png`)}
          alt=""
        />
      );
    } else {
      return (
        <img
          className="align-self-center picGrey"
          src={require(`../../images/tempel/${skill}_g.png`)}
          alt=""
        />
      );
    }
  };
  return (
    <div
      style={{ cursor: "pointer" }}
      onClick={() => props.onClick(props.skill)}
    >
      {img(
        `${props.skill}`,
        props.from === `${props.skill}` || props.to === `${props.skill}`
      )}
      {data.skills[`${props.skill}`]}
    </div>
  );
};

export default TempleSkill;
