import React from "react";
import PropTypes from "prop-types";

const Checkbox = ({ type = "checkbox", itemName, name, checked = false, onChange }) => (
  <label htmlFor={name}>
    <input type={type} id={name} name={name} checked={checked} onChange={onChange} />
    {itemName}
  </label>
);

Checkbox.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  itemName: PropTypes.string,
  name: PropTypes.number.isRequired,
  checked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

export default Checkbox;