import React from "react";
import { useHistory } from "react-router-dom";

const Shop2 = () => {
  const history = useHistory();

  return (
    <div>
      <div id="pic" className="container-fluid p-0 m-0 position-relative">
        <img
          className="w-100"
          style={{ verticalAlign: "sub" }}
          src={require(`../../images/city/händler.jpg`)}
          alt=""
          onClick={() => history.push("/shop")}
        />
      </div>
    </div>
  );
};

export default Shop2;
