import React, { useEffect, useState } from "react";
import { GET, POST } from "../../tools/fetch";
import { Button, Form } from "react-bootstrap";

const AdminRaids = () => {
  const [loading, setLoading] = useState();
  const [bosses, setBosses] = useState();

  const loadBosses = async () => {
    setLoading(true);
    try {
      const request = await GET(`/admin/bosses`);
      if (request) {
        setBosses(request);
      }
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  const handlePoolChange = (e) => {
    if (e.target.id === "") {
      e.target.id = 0;
    }
    //bosses.bosses[e.target.id].checked = !bosses.bosses[e.target.id].checked;
    const bosses_temp = (bosses.bosses[e.target.id].checked =
      !bosses.bosses[e.target.id].checked);

    setBosses({ ...bosses, bosses: [...bosses.bosses] });
  };

  const handleSave = async () => {
    try {
      const request = await POST("/admin/bosses/save", { bosses: bosses });
    } catch (error) {}
  };

  useEffect(() => {
    loadBosses();
  }, []);

  return (
    <div>
      Auswahl der Raid-Bosse für alle Allianzen
      {loading === false && (
        <div>
          <div>
            <div>NPC-Pool</div>
            {bosses.bosses_av.map((boss, id) => {
              return (
                <Form.Check
                  key={+boss}
                  id={+id}
                  type="checkbox"
                  label={+boss}
                  checked={bosses.bosses[id] && bosses.bosses[id].checked}
                  onChange={(e) => handlePoolChange(e)}
                />
              );
            })}
          </div>
          <Button onClick={handleSave}>speichern</Button>
        </div>
      )}
    </div>
  );
};

export default AdminRaids;
