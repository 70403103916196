import React, { useEffect, useState } from "react";
import { Col, ProgressBar, Row } from "react-bootstrap";
import { GET } from "../../tools/fetch";
import DoneAllIcon from "@mui/icons-material/DoneAll";

const Dquest = () => {
  const [quests, setQuests] = useState([]);
  const [Uquests, setUQuests] = useState([]);
  const [loading, setLoading] = useState();

  const loadQuests = async () => {
    try {
      setLoading(true);
      const quests = await GET("/quest/dquest");
      setQuests(quests.dquests);
      setUQuests(quests.dquestsU);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadQuests();
  }, []);

  return (
    <div>
      <Row
        style={{ minHeight: "30rem" }}
        className="mt-3 mb-3 justify-content-center"
      >
        <Col className="shadow" md="8">
          <div className="text-center mb-2 h4">tägliche Aufgaben</div>
          <hr />
          {loading === false &&
            quests.map((quest, id) => {
              return (
                <div>
                  <Row>
                    <Col xs="1">{id + 1}.</Col>
                    <Col>{quest.description}</Col>
                  </Row>
                  <Row className="align-items-center">
                    <Col xs="1"></Col>
                    <Col>
                      <ProgressBar
                        style={{ height: "4px" }}
                        striped
                        variant="success"
                        now={(100 / quest.amount) * Uquests[id].amount}
                      />
                    </Col>
                    <Col className="text-center" xs="2">
                      {Uquests[id].amount}/{quest.amount}
                    </Col>
                    {Uquests[id].amount === quest.amount ? (
                      <Col xs="2" className="text-center">
                        <DoneAllIcon color="primary" />
                      </Col>
                    ) : (
                      <Col xs="2"></Col>
                    )}
                  </Row>
                  <Row className="mb-3" style={{ marginTop: "-0.75rem" }}>
                    <Col xs="1"></Col>
                    <Col>
                      <small>Belohnung: {quest.belohnung}</small>
                    </Col>
                  </Row>
                  <hr />
                </div>
              );
            })}
        </Col>
      </Row>
    </div>
  );
};

export default Dquest;
