import moment from "moment";
import React, { useState, useEffect } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { POST } from "../../tools/fetch";

const SingleAucHis = (props) => {
  const [auction, setAuction] = useState();
  const [loading, setLoading] = useState();

  const loadAuction = async () => {
    try {
      const response = await POST("/auction/singleauctionhistory", {
        id: props.auction,
      });
      setAuction(response.auction);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadAuction();
  }, [props.auction]);

  return (
    <Row>
      <Col md="3">
        {loading === false && (
          <Card
            className="ml-2 mr-2"
            style={{ backgroundColor: "rgba(245, 245, 245, 0.4)" }}
          >
            <Card.Img
              className="card-img-top mx-auto d-block mt-2"
              style={{ width: "100px" }}
              src={`../../images/items/${auction.itemtype}/${auction.item.img}.jpg`}
            />
            <Card.Body className="text-center">
              <Card.Title className="h5">{auction.item.name}</Card.Title>
              <Card.Text>
                <p>
                  Quality: {auction.item.qlvl}
                  <br />
                  {auction.item.reql > 0 && (
                    <>
                      required level: {auction.item.reql} <br />
                    </>
                  )}
                  {auction.item.reqs > 0 && (
                    <>
                      required skill: {auction.item.reqs} <br />
                    </>
                  )}
                  {auction.item.mindmg > 0 && (
                    <>
                      Damage: {auction.item.mindmg} - {auction.item.maxdmg}
                      <br />
                    </>
                  )}
                  {auction.item.stat1 && (
                    <>
                      {auction.item.stat1.split(",").join(" ")}
                      <br />
                    </>
                  )}
                  {auction.item.stat2 && (
                    <>
                      {auction.item.stat2.split(",").join(" ")}
                      <br />
                    </>
                  )}
                  {auction.item.stat3 && (
                    <>
                      {auction.item.stat3.split(",").join(" ")}
                      <br />
                    </>
                  )}
                  {auction.item.stat4 && (
                    <>
                      {auction.item.stat4.split(",").join(" ")}
                      <br />
                    </>
                  )}
                  {auction.item.stat5 && (
                    <>
                      {auction.item.stat5.split(",").join(" ")}
                      <br />
                    </>
                  )}
                </p>
              </Card.Text>
            </Card.Body>
          </Card>
        )}
      </Col>
      <Col className="mr-2 ml-2 text-white">
        {loading === false && (
          <>
            <Row>
              <Col>Verkäufer: {auction.seller.username}</Col>
              <Col>
                Ablauf: {moment(auction.time, "X").format("DD.MM. HH:mm")} Uhr
              </Col>
            </Row>
            <Row>
              <Col>Käufer: {auction.buyer.username}</Col>
            </Row>
            <div>Verkaufspreis: {auction.price} Cr.</div>
          </>
        )}
      </Col>
    </Row>
  );
};

export default SingleAucHis;
